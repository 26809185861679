import { Fragment, useState, useEffect, useCallback } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function TunnelConfigDropdown(props: any) {
  const [selected, setSelected] = useState(props.devices[3]);

  const handleSelect = useCallback(() => {props.selectHandler(selected) }, [selected]);

  useEffect(() => { handleSelect() }, [handleSelect]);

  return (
    <Listbox value={selected} onChange={ setSelected }>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className=" bg-white align-middle relative w-full border border-cyan-400 rounded pl-1 pr-2 py-1 text-left 
            cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500 text-xs">
              <span className="block align-middle text-xs">{String(selected.Vendor + ' ' + selected.Platform + ': ' + selected.Software)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="align-middle w-full bg-white shadow-sm max-h-60 rounded-md py-1 text-xs ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                {props.devices.map((device: any) => (
                  <Listbox.Option
                    key={device.VpnConnectionDeviceTypeId}
                    onClick={() => {props.selectHandler(selected)} }
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-cyan-400' : 'text-gray-600',
                        'cursor-default select-none relative py-1 pl-3 pr-9'
                      )
                    }
                    value={device}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {device.Vendor.slice(0, 12) + ' ' + device.Platform + ' ' + device.Software}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-cyan-400',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
