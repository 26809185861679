import {
  flattenSampleDataNoAmpersand,
  flattenSampleData,
  isMatch,
  transformPayload,
  removeEmptyEntries,
  createFHIRBundle,
  buildPayload
} from './parsing/index.mjs';

const dsn = 'https://39f6b6957e434773b1c5b5f99cc78bc6@o1350334.ingest.sentry.io/6630106';
const tracesSampleRate = 0.1;

export { 
  flattenSampleDataNoAmpersand,
  flattenSampleData,
  isMatch,
  transformPayload,
  removeEmptyEntries,
  createFHIRBundle,
  buildPayload, 
  tracesSampleRate, 
  dsn 
};
