
export default {
  url: process.env.API_URL,
  client_url: process.env.CLIENT_URL,
  stripe_api_key: process.env.STRIPE_API_KEY,
  ENV: process.env.ENV,
  nav: [
    { name: 'Dashboard', href: '/', current: false },
    { name: 'Workflows', href: '/workflows', current: false },
    { name: 'Tunnels', href: '/tunnels', current: false },
  ],
};
