import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ProvideAuth } from './app/hooks/useAuth';
import { ThemeProvider } from './app/context/ThemeContext';
import App from './App';
import config from './config';

Sentry.init({
  dsn: 'https://fdcf8918ff754c49abaea4ce8fb0a3db@o1350334.ingest.sentry.io/6630105',
  integrations: [new BrowserTracing()],
  environment: config.ENV,
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <BrowserRouter>
    <ProvideAuth>
      <ThemeProvider>
        <App />
        <Route path="/" />
      </ThemeProvider>
    </ProvideAuth>
  </BrowserRouter>,
  document.getElementById('root'),
);
