/* eslint-disable no-shadow */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';

const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

export default function WorkflowDropdown(props: any) {
  const sel = !props.selected || Object.keys(props.selected).length === 0 ? props.Types[1] : props.selected;
  const [selected, setSelected] = useState(sel);
  const [types, setTypes] = useState(props.Types);

  useEffect(() => {
    setSelected(sel);
    setTypes(props.Types);
  }, [props.Types, props.selected]);

  return (
    <Listbox
      value={selected}
      onChange={(newValue) => {
        setSelected(newValue);
        props.selectHandler(newValue);
      }}
    >
      {({ open }) => (
        <div className="mr-3 whitespace-nowrap relative z-10">
          <Listbox.Button className="bg-transparent dark:bg-gray-800 text-gray-600 dark:text-gray-100 align-middle relative w-full border border-gray-600 rounded shadow-sm pl-3 pr-12 py-2 text-left text-sm cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300">
            <span className="text-sm truncate">{selected.label ? selected.label : selected.type}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="align-middle w-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-100 absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {types.length &&
                types.map((event: any) => (
                  <Listbox.Option
                    className={({ active }) =>
                      classNames(active ? 'text-white bg-cyan-400' : 'text-gray-600 dark:text-gray-200', 'cursor-default select-none relative py-2 pl-3 pr-9')
                    }
                    key={event.id}
                    onClick={() => props.selectHandler(event)}
                    value={event}
                    defaultValue={event.label}
                    disabled={event.disabled}
                  >
                    {({ selected, active }) => (
                      <div>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', '')}>{event.label}</span>
                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-cyan-400', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
