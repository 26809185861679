/* eslint-disable react/function-component-definition */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { UserGroupIcon, ExclamationCircleIcon, AdjustmentsVerticalIcon, CalendarIcon, ShieldCheckIcon } from '@heroicons/react/24/outline';
import InputBox from '../components/Base/Elements/Input/InputBox';
import InnerNavButton from '../components/Base/Elements/Nav/InnerNavButton';
import InnerNavButtonSelected from '../components/Base/Elements/Nav/InnerNavButtonSelected';
import DuplicateUserAlert from '../components/Base/Elements/Alerts/DuplicateUserAlert';
import AccountUserLimitAlert from '../components/Base/Elements/Alerts/AccountUserLimitAlert';
import PasswordNotComplexAlert from '../components/Base/Elements/Alerts/PasswordNotComplexAlert';
import EmailFormatAlert from '../components/Base/Elements/Alerts/EmailFormatAlert';
import UserPermissionsModal from '../components/Accounts/Elements/UserPermissionsModal';
import PricingBox from '../components/Accounts/Elements/PricingBox';
import ScreenShot from '../../images/screenshot.png';
import Environments from '../components/Environments/Environments';
import logo from '../../images/retrohook.png';
import useWorkspace from '../hooks/useWorkspace';

interface Props {
  user: any;
  selectedWorkspace: any;
  notify: () => void;
  account: any;
  setSelectedWorkspace: () => void;
  setupEnvironmentsHandler: () => void;
  getAccount: () => void;
  setAccount: () => void;
  environmentChanged: boolean;
  setEnvironmentChanged: () => void;
}

const Workspace = (props: Props) => {
  const {
    subUx,
    setSubUx,
    error,
    setError,
    products,
    selectedProduct,
    togglePermissions,
    selectedUser,
    invite,
    billingUX,
    loadingType,
    setLoadingType,
    quantityPurchased,
    setQuantityPurchased,
    workspace,
    selectProduct,
    getCustomerPortal,
    handleNewUserFirstNameChange,
    handleNewUserLastNameChange,
    handleNewUserEmailChange,
    handleMessageIDChange,
    handleOrganizationValueChange,
    openPermissions,
    addUserToWorkspace,
    removeUserFromWorkspace,
    updatePermissions,
    savePermissions,
    saveWorkspace,
    loading,
    awsProductURL,
  } = useWorkspace(props);

  if (!loading) {
    return (
      <div>
          <div className="flex h-full">
            <div className="dark:bg-gray-800 bg-cyan-400 flex-1 min-w-0 flex items-center justify-between px-4">
              <div className="w-full mx-auto py-4 px-6 flex grow">
                <h2 className="text-lg leading-6 font-semibold text-gray-100 pr-2">
                      Workspace {props.selectedWorkspace ? `${subUx}` : subUx}
                  </h2>
            </div>
              {subUx == 'Settings' ? (
                <InnerNavButtonSelected icon={AdjustmentsVerticalIcon} title="General" clickHandler={() => setSubUx('Settings')} />
            ) : (
                <InnerNavButton icon={AdjustmentsVerticalIcon} title="General" clickHandler={() => setSubUx('Settings')} />
            )}
            {subUx == 'Team Settings' ? (
                    <InnerNavButtonSelected icon={UserGroupIcon} title="Team" clickHandler={() => setSubUx('Team Settings')} />
            ) : (
                <InnerNavButton icon={UserGroupIcon} title="Team" clickHandler={() => setSubUx('Team Settings')} />
            )}
              {subUx == 'Environments' ? (
                <InnerNavButtonSelected icon={CalendarIcon} title="Environments" clickHandler={() => setSubUx('Environments')} />
            ) : (
                <InnerNavButton icon={CalendarIcon} title="Environments" clickHandler={() => setSubUx('Environments')} />
            )}
              {subUx == 'Subscription' ? (
              <InnerNavButtonSelected icon={CalendarIcon} title="Subscription" clickHandler={() => setSubUx('Subscription')} />
            ) : (
                <InnerNavButton icon={CalendarIcon} title="Subscription" clickHandler={() => setSubUx('Subscription')} />
            )}
          </div>
        </div>
        {error == 'UsernameExistsException' && <DuplicateUserAlert closeAlert={() => setError(null)} />}
        {error == 'PasswordNotComplexException' && <PasswordNotComplexAlert closeAlert={() => setError(null)} />}
          {error == 'AccountUserLimitAlert' && <AccountUserLimitAlert closeAlert={() => setError(null)} />}
          {error == 'EmailFormatException' && <EmailFormatAlert closeAlert={() => setError(null)} />}
          <dl>
          {props.selectedWorkspace && (
            <div className="dark:bg-gray-800 border-2 min-h-full mt-4 rounded border-cyan-300 mx-4 overflow-hidden">
                <div>
                  {subUx == 'Settings' && (
                <div className="mb-6 sm:flex sm:flex-col sm:align-center">
                    <div className="flex">
                      <h2 className="text-xl w-full p-5 text-gray-600 dark:text-gray-200">General Settings</h2>
                      <div className="p-4">
                        <button type="button" onClick={() => saveWorkspace(workspace)} 
                          className="inline text-gray-600 whitespace-nowrap w-full items-center px-2 py-1 text-xs font-medium hover:text-white hover:bg-cyan-300 border-cyan-400 border-2 rounded focus:outline-none">
                          Save
                          </button>
                          </div>
                    </div>
                    <div className="mx-6 border border-top border-cyan-300 mb-8" />
                      <div className="gap-2 px-4">
                      <div className="text-gray-200">
                        <InputBox
                          name="Team Name"
                          onChange={(event: any) => {
                            event.preventDefault();
                            handleOrganizationValueChange(event);
                          }}
                          placeholder={workspace || ''}
                          defaultValue={workspace ? workspace.name : ''}
                        />
                          </div>
                    </div>
                      <div className="mx-6" />
                      <p className="mx-auto my-8 w-fit text-center text-sm text-gray-600 border-4 px-2 py-2 border-red-300 rounded">
                        <ExclamationCircleIcon className="h-6 w-6 inline text-pink-500 rounded" />
                        <span className="pl-3 text-pink-500">
                          You must include the Workflow Message ID Value below in the stated Workflow Message ID Location of your HL7 Messages for security.
                      </span>
                    </p>
                      <div className="grid grid-cols-12 gap-2 px-4 pb-6">
                      <div className="col-span-4">
                            <InputBox
                          name="Security Segment"
                          onChange={handleMessageIDChange}
                          placeholder={workspace ? workspace.hash.location : ''}
                          defaultValue={workspace ? workspace.hash.location : ''}
                        />
                        </div>
                            <div className="col-span-8">
                            <InputBox
                          name="Security Token"
                          disabled
                          placeholder={workspace ? workspace.hash.value : ''}
                          defaultValue={workspace ? workspace.hash.value : ''}
                        />
                        </div>
                        </div>
                  </div>
                )}
                {subUx == 'Team Settings' && (
                    <div className="sm:flex sm:flex-col sm:align-center">
                      <h2 className="w-full text-xl pt-4 px-4 text-gray-600 dark:text-gray-200 dark:bg-gray-800">Owner | {props.account?.email}</h2>
                    <h2 className="w-full text-xl p-2 px-4 text-cyan-300 dark:bg-gray-800">Members</h2>
                      <UserPermissionsModal open={togglePermissions} close={savePermissions} updatePermissions={updatePermissions} selectedUser={selectedUser} />
                      <table className="mb-6 mx-2">
                      <tbody>
                        {workspace?.accounts
                          ?.filter((account: any) => account.email != workspace?.owner?.email)
                          .map((user: any, index: number) => (
                              <tr
                              key={user.email + index}
                              onClick={() => {
                                openPermissions(user);
                              }}
                              className={
                                index % 2 === 0
                                  ? 'border-gray-400 border-2 rounded-sm text-gray-600 dark:text-gray-200 hover:border-cyan-400 cursor-pointer'
                                  : 'border-gray-400 border-2 rounded-sm text-gray-600 dark:text-gray-200 hover:border-cyan-400 cursor-pointer'
                              }
                            >
                              <td className="px-4 py-4 whitespace-wrap text-md font-light text-gray-600 dark:text-gray-200">{user.email ? user.email : ''}</td>
                              <td className="relative whitespace-wrap px-2 text-sm text-gray-500 dark:text-gray-800">
                                <button
                                  className=" px-3 absolute top-3 right-2 bg-pink-400 dark:bg-gray-500 py-1 hover:bg-pink-500 text-white rounded focus:outline-none focus:shadow-outline"
                                  type="button"
                                  onClick={() => {
                                    removeUserFromWorkspace(user);
                                    setLoadingType('remove');
                                  }}
                                >
                                    X
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                      <div className="mx-6 border border-top border-gray-300" />
                      <h2 className="w-full text-xl p-4 text-pink-500 dark:bg-gray-800">Invite</h2>
                      <table className="min-w-full">
                        <tbody>
                          <tr>
                            <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                              <InputBox name="First Name" onChange={handleNewUserFirstNameChange} defaultValue={invite.firstName} />
                            </td>
                            <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                              <InputBox name="Last Name" onChange={handleNewUserLastNameChange} defaultValue={invite.lastName} />
                                </td>
                              <td className="px-2 py-4 whitespace-nowrap text-md text-gray-200">
                              <InputBox name="Email" onChange={handleNewUserEmailChange} defaultValue={invite.email} />
                            </td>
                            {!loading && (
                              <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                                <button
                                  className="w-full bg-cyan-500 hover:bg-cyan-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline"
                                  type="button"
                                  onClick={() => {
                                    addUserToWorkspace(props.user.attributes.id);
                                    setLoadingType('invite');
                                  }}
                                >
                                  Send Invite
                                </button>
                            </td>
                            )}
                              {loading && loadingType == 'invite' && (
                                <td className="px-2 py-3 whitespace-nowrap text-md text-gray-200">
                                  <button
                                  className="w-full bg-gray-500 hover:bg-gray-400 py-2 px-1 text-white rounded focus:outline-none focus:shadow-outline animate-bounce"
                                  type="button"
                                >
                                    Sending...
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                {subUx == 'Environments' && (
                  <Environments
                    user={props.user}
                    account={props.account}
                    setAccount={props.setAccount}
                    selectedWorkspace={props.selectedWorkspace}
                    setSelectedWorkspace={props.setSelectedWorkspace}
                    setupEnvironmentsHandler={props.setupEnvironmentsHandler}
                    notify={props.notify}
                    environmentChanged={props.environmentChanged}
                    setEnvironmentChanged={props.setEnvironmentChanged}
                  />
                )}
                {subUx == 'Subscription' && (
                    <div>
                      <PricingBox
                      account={props.account}
                      products={products}
                      selectProduct={selectProduct}
                      getAccount={props.getAccount}
                      awsProductURL={awsProductURL}
                      quantityPurchased={quantityPurchased}
                      setQuantityPurchased={setQuantityPurchased}
                      selectedProduct={selectedProduct}
                      getCustomerPortal={getCustomerPortal}
                      selectedWorkspace={props.selectedWorkspace}
                      ux={billingUX}
                    />
                  </div>
                )}
                {subUx == 'Release Notes' && (
                    <div className="relative mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="px-4 sm:px-6 lg:px-8">
                            <div className="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-20">
                          <h1 className="text-4xl font-extrabold tracking-tight text-gray-300 sm:text-5xl">Changelog</h1>
                        <p className="mt-4 text-base leading-7 text-cyan-400">
                                Stay up to date with all of the latest additions and improvements we've made to Retrohook. We'll be updating this page regularly with all of the latest changes and will provide a way to subscribe soon.
                        </p>
                      </div>
                        </div>
                    <section id="2022-12-15" aria-labelledby="2022-12-15-heading" className="md:flex">
                            <h2 id="2022-12-15-heading" className="pl-7 text-md leading-6 text-slate-500 md:w-1/4 md:pl-0 md:pr-6 md:text-right">
                        <a href="#2023-06-01" className="text-cyan-400">
                                    June 1st, 2023
                        </a>
                      </h2>
                      <div className="relative py-4 mx-4">
                        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
                            <div className="relative flex space-x-2">
                          <div className="min-w-0 flex-1 flex justify-between space-x-1" />
                            <div className="max-w-none prose-h3:mb-4 pl-12 prose-h3:text-base prose-h3:leading-6 prose-sm prose prose-slate prose-a:font-semibold prose-a:text-pink-500 hover:prose-a:text-purple-600">
                              <h2 className="text-red-400 text-2xl">Beta Release 🎉</h2>
                              <p className="py-4 text-gray-200">
                                      Today we're happy to announce the launch of our baseline HL7v2 tool. We've been working hard to build a platform that will help you work with HL7 messages quickly and easily. We're excited to share this with you and we hope you enjoy it!
                                      Check out our documentation or just start building with our workflow builder.
                            </p>
                              <a href="/workflows">
                                <img className="rounded-xl ring-1 ring-offset-[-1px] ring-slate-700/10" src={ScreenShot} alt="Workflow Builder" />
                            </a>
                          </div>
                        </div>
                        </div>
                        </section>
                  </div>
                )}
              </div>
            </div>
          )}
            </dl>
      </div>
    );
  }
  return (
    <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48">
          <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
          <h2 className="mt-0 text-center text-2xl font-bold text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>
            Retrohook
          <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
        </h2>
      </div>
  );
};

export default Workspace;
