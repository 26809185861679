/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/react-in-jsx-scope */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { IconPickerItem } from 'react-heroicons-picker';

interface Props {
  isOpen: boolean;
  templates: any;
  saveButton: boolean;
  closeModal: () => void;
  applyTemplate: (event: any) => void;
  saveTemplate: (event: any) => void;
}

export default function TemplatesModal({ isOpen, templates, closeModal, applyTemplate, saveTemplate, saveButton }: Props) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-3/4 h-3/4 align-bottom animate-color-change-2x dark:animate-color-change-2x-dark border-cyan-400 border-2 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
              <div className="animate-color-change-2x px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start border-b-cyan-400 border-b-2 pb-1">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full animate-color-change-2x sm:mx-0 sm:h-10 sm:w-10">
                    <ClipboardDocumentListIcon className="h-9 w-9 text-gray-600 dark:text-gray-100" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-2 sm:text-left">
                    <Dialog.Title as="h3" className="mt-2 text-2xl leading-6 font-medium text-gray-600 dark:text-gray-100">
                      Templates
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 grid grid-cols-2 gap-4 dark:bg-gray-800 rounded-sm overflow-y-auto">
                  {templates.map((template: any) => (
                    <div className="border-2 px-2 rounded-md text-cyan-500 border-gray-500 hover:border-cyan-400 cursor-pointer hover:text-cyan-300 space-x-2 inline dark:bg-gray-700 w-full">
                      <div className="pt-2 px-2">
                        <div key={template.id} className="flex dark:bg-gray-800  border-gray-600 border-2 p-2 rounded-md">
                          <div className="border-2 border-cyan-400 p-1 rounded-full">
                            <IconPickerItem icon={template?.icon ? template?.icon : 'BoltIcon'} library="outline" />
                          </div>
                          <p className="text-gray-600 dark:text-gray-100 text-lg inline mt-1 ml-2">{template.flowName}</p>
                        </div>
                      </div>

                      <p className="text-gray-400 text-xs mt-2 items-center w-full grid grid-cols-3">
                        <div className="col-span-2">
                          <span className="px-2 border-2 my-2 text-cyan-400 border-gray-500 py-1 rounded-l dark:bg-gray-800">{template.trigger.type}</span>
                          <ArrowRightIcon className="w-8 h-6 bg-cyan-400 inline-flex border-y-2 border-y-gray-600 mt-2 mb-3 mx-0 text-gray-100" />
                          <span className="px-2 border-2 my-2 text-cyan-400 border-gray-500 py-1 rounded-r dark:bg-gray-800">{template.next.type}</span>
                        </div>
                        <button
                          className="relative inline right-2 rounded mb-2 border-2 border-cyan-400 shadow-sm py-1 bg-cyan-400 text-white hover:bg-cyan-300 text-xs"
                          onClick={() => {
                            applyTemplate(template);
                            closeModal();
                          }}
                        >
                          Apply
                        </button>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="animate-color-change-2x px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded border border-gray-300 shadow-sm px-4 py-2 bg-transparent text-base font-medium text-gray-600 hover:text-gray-100 dark:text-gray-100 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                {saveButton && (
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={saveTemplate}
                  >
                    Generate Template from Current Workflow
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
