export default function InputBox(props: any) {
  return (
    <div className="relative border border-gray-600 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-300 focus-within:border-cyan-300">
      <label htmlFor={props.name} className="z-10 absolute -top-4 left-0 -mt-px inline-block px-1 text-gray-600 bg-gray-100 border border-gray-300 dark:bg-gray-700 rounded-md text-xs font-medium dark:text-gray-100">{props.name}</label>
      <input
        type="text"
        name={props.name}
        id={props.name}
        onChange={props.onChange}
        value={props.defaultValue}
        placeholder={props.placeholder ? props.placeholder : ''}
        className="block bg-transparent w-full border-0 p-0 text-gray-600 placeholder-gray-700 dark:text-gray-100 focus:ring-0 sm:text-sm" 
      />
    </div>
  );
}
