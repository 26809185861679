import { Switch } from '@headlessui/react'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function GridToggle(props: any) {
  return (
    <Switch checked={props.enabled} onChange={() => { props.setEnabled() }}
      className={classNames( props.enabled ? 'bg-cyan-400' : 'bg-gray-700',
        'relative inline-flex flex-shrink-0 h-3 w-7 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400'
      )}
    >
      <span className="sr-only">Toggle Empty Values</span>
      <span aria-hidden="true" className={classNames(props.enabled ? 'translate-x-4' : 'translate-x-0', 'pointer-events-none inline-block h-2 w-2 rounded-full bg-gray-500 shadow transform ring-0 transition ease-in-out duration-200')}/>
    </Switch>
  )
}
