import { useEffect, useState } from "react";
import useFetch from 'use-http';
import { ServerIcon } from '@heroicons/react/24/outline';
import InputBox from "../Base/Elements/Input/InputBox";
import config from "../../../config";
import logo from "../../../images/retrohook.png"

const defaultNewEnvironment = {
  name: "",
  awsAccountID: "",
  awsRegion: "",
  awsUserClientId: "",
  awsUserSecret: "",
  storage: { version: 'latest' },
  network: { version: 'latest' },
  compute: { version: 'latest' },
  stackery: { version: 'latest' },
};
const placeholders = {
  awsRegion: "Enter AWS Region",
  awsUserClientId: "Enter AWS User Client ID",
  awsAccountID: "Enter AWS Account ID",
  awsUserSecret: "Enter AWS User Client Secret",
  name: "Enter Environment Name",
};

export default function EnvironmentForm(props: any) {
  const { post, get, response, loading } = useFetch(config.url);
  const [environment, setEnvironment] = useState(defaultNewEnvironment);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [userCF, setUserCF] = useState("");
  const [loadingType, setLoadingType] = useState('');

  const handleInputChange = (event: any, input: string) => {
    setEnvironment({ ...environment, [input]: event.target.value});
  }
  const saveEnvironment = async () => {
    setLoadingType('save');
    if (environment.name === "") return props.notify({ message: "Please enter a name for your environment." });
    if (environment.awsAccountID === "") return props.notify({ message: "Please enter your AWS account ID." });
    if (environment.awsRegion === "") return props.notify({ message: "Please select an AWS region." });
    if (environment.awsUserClientId === "") return props.notify({ message: "Please enter your AWS client ID." });
    if (environment.awsUserSecret === "") return props.notify({ message: "Please enter your AWS client secret." });
    if (!props.account.quantityPurchased || props.account.quantityPurchased <= (props.selectedWorkspace.environments.length + 1)) return props.notify({ message: "You have reached your environment limit." });
    const params = { environment: environment, workspace: props.selectedWorkspace, account: props.account };
    const request = await post(`environment/create/${props.user.attributes.id}`, params); 
    if (request && response.ok) {
      props.setAccount(request.account);
      props.setSelectedWorkspace(request.workspace);
      setEnvironment(defaultNewEnvironment);
      props.notify({ message: "🎉 Environment Created" });
    }
    setLoadingType('');
  }
  const getUserCF = async () => {
    const userCFRequest = await get(`environment/setup-user/`); 
    if (response.ok) setUserCF(userCFRequest.json);
  } 
  const getRegions = async () => {
    const regions = await get(`environment/get-regions/`); 
    if (response.ok) setAvailableRegions(regions.regions.Regions); 
  } 
  const downloadUserCF = async () => {
    const element = document.createElement("a");
    console.dir(JSON.stringify(userCF));
    const file = new Blob([JSON.stringify(userCF)], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "retrohook-user.json";
    document.body.appendChild(element);
    element.click();
  }

  useEffect(() => { getUserCF(); getRegions(); }, []);

  return (
    <div className="col-span-2">
      <div className="min-h-full bg-transparent dark:bg-gray-800 p-4 shadow rounded-lg overflow-hidden border-gray-400 hover:border-cyan-300 border-2">
        { (loadingType === 'save' && loading) ? 
           <div className="flex flex-col justify-center items-center min-h-full">
           <div className="items-center animate-pulse">
             <img className="h-24 mx-auto w-auto" src={logo} alt="Logo"/>
             <h2 className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook
             </h2>
           </div>
         </div>
        : <>
            <div >
              <p className="text-xs text-gray-400 mt-2 mb-2"> 
              1. <a onClick={() => { downloadUserCF()}} className="text-gray-600 py-0.5 border-2 border-pink-400 hover:bg-pink-400 rounded-sm p-1 mx-1 my-1 cursor-pointer">Download</a> 
                  the role template.
              </p>
              <p className="text-xs text-gray-400">
                  2. Create a client id and secret.
              </p>
              
              <div className="space-y-2">
                <div className="mt-4">
                <InputBox 
                  name="Name" 
                  onChange={ (event: any) => { handleInputChange(event, 'name') }} 
                  defaultValue={environment.name} 
                  placeholder={placeholders.name} 
                />
              </div>
                <div className="pb-2 pt-4">
                  <InputBox 
                    name="AWS Account ID"
                    onChange={ (event: any) => { handleInputChange(event, 'awsAccountID') }} defaultValue={environment.awsAccountID} 
                    placeholder={placeholders.awsAccountID} 
                  />
                </div>
                <div className="pb-2 pt-2">
                  <InputBox name="AWS Client ID" 
                    onChange={ (event: any) => { handleInputChange(event, 'awsUserClientId') }} 
                    defaultValue={environment.awsUserClientId} 
                    placeholder={placeholders.awsUserClientId} 
                  />
                </div>
                <div className="pb-2 pt-2">
                  <InputBox name="AWS Client Secret" 
                    onChange={ (event: any) => { handleInputChange(event, 'awsUserSecret') }} 
                    defaultValue={environment.awsUserSecret} 
                    placeholder={placeholders.awsUserSecret} 
                  />
                </div>
                <div className="pb-2">
                  <select className="border-2 appearance-none border rounded w-full py-2 px-3 bg-transparent border-cyan-300 text-cyan-300 leading-tight focus:outline-none"
                    value={environment.awsRegion}
                    onChange={(event) => { setEnvironment({ ...environment, awsRegion: event.target.value }); }}
                  >
                    <option value="" disabled>Select AWS Region</option>
                    { availableRegions && availableRegions.map((region : any, index : number) => (
                      <option key={index} value={region?.RegionName}>{region?.RegionName}</option>
                    )) }
                  </select>
                </div>
                <button type="button" className="w-full bg-cyan-300 hover:bg-cyan-400 text-white font-bold py-2 px-4 rounded  focus:outline-none focus:shadow-outline" onClick={saveEnvironment}>
                  Create Environment
                </button>
              </div>
            </div>
          </> 
        }
      </div>
    </div>
  );
}
