/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useState, useEffect, useCallback, useMemo, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { Provider } from 'use-http';
import toast, { Toaster } from 'react-hot-toast';
import ReconnectingWebSocket from 'reconnecting-websocket';
import qs from 'qs';
import { ThemeProvider } from './app/context/ThemeContext';
import Navigation from './app/components/Base/Layout/Navigation';
import WorkspaceSelectModal from './app/components/Base/Elements/Nav/WorkspaceSelectModal';
import { useAuth } from './app/hooks/useAuth';
import Content from './app/components/Base/Layout/Content';
import Product from './app/pages/Product';
import ChatwootWidget from './app/components/Base/Layout/ChatWootWidget';
import config from './config';

type OptionsResponse = { response: any };
let options: any;

const WebSocketContext = createContext(null);
export const useWebSocketContext = () => useContext(WebSocketContext);

export default function App() {
  const history = useHistory();
  const auth = useAuth();
  const { user } = auth;
  const [wsClient, setWsClient] = useState(null);
  const [nav, setNav] = useState<any>(config.nav);
  const [account, setAccount] = useState<any>(user?.account);
  const [environments, setEnvironments] = useState<any>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [selectedWorkspaceModal, setSelectedWorkspaceModal] = useState<any>(false);
  const [updateBillingCustomer, setUpdateBillingCustomer] = useState<any>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>(user?.account?.workspaces[0].environments[0]);
  const [selectedWorkspace, setSelectedWorkspace] = useLocalStorage<any>('selectedWorkspace', [user?.account?.workspaces[0]]);
  const [mktplaceToken, setMktplaceToken] = useLocalStorage<any>('mktplaceToken', '');
  const [isAmazonAccount, setIsAmazonAccount] = useLocalStorage<any>('isAmazonAccount', false);
  const [environmentChanged, setEnvironmentChanged] = useState<any>(false);
  const [version, setVersion] = useState('loading...');


  useEffect(() => {
    const fetchVersion = async () => {
      const version = await getRetrohookVersion();
      setVersion(version);
      console.info('✨ Retrohook version:', version);
    };

    fetchVersion();
  }, []);

  const setUpdateBilling = useCallback(
    async (selected: any) => {
      setUpdateBillingCustomer(selected);
    },
    [setSelectedAccount]
  );

  const setAccountCallback = useCallback(
    async (selected: any) => {
      setAccount(selected);
    },
    [setAccount]
  );

  const setSelectedEnvironmentCallback = useCallback(
    async (selected: any) => {
      setSelectedEnvironment(selected);
    },
    [setSelectedEnvironment]
  );

  const notify = (data: { message: string }) => {
    toast(data.message);
  };

  const updateNav = (href: string) => {
    const newNav = nav.map((item: any) => {
      if (item.href === href) {
        return { ...item, current: true };
      }
      return { ...item, current: false };
    });
    setNav(newNav);
  };

  const redirectToURL = (page: string) => {
    history.push(`/${page}`);
    updateNav(page);
  };

  const handleLogout = () => {
    if (wsClient) {
      wsClient.send(JSON.stringify({ event: 'brb' }));
    }
    auth.logout();
    history.push('/');
  };

  const getRetrohookVersion = async () => {
    try {
      const packageJson = await import('@1putthealth/retrohook-utilities/package.json');
      return packageJson.version;
    } catch (error) {
      console.error('Error loading retrohook utilities version:', error);
      return 'unknown';
    }
  };

  const setNavHighlight = (pageName: string) => {
    const newNav = nav.map((item: any) => {
      if (item.name === pageName) return { ...item, current: true };
      return { ...item, current: false };
    });
    setNav(newNav);
  };

  const getUrlParameter = (nameParam: any, hst: any) => {
    const name = nameParam.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(hst.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const getAccount = async () => {
    const response = await fetch(`${config.url}/account/get-account/${user.attributes.id}`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${user.tokens.AccessToken}` },
    });
    if (response.ok) {
      const data = await response.json();
      user.account = data.account;
      setAccount(data.account);
    }
  };

  const initialAccountFetch = async () => {
    if (auth.user && auth.user.tokens) {
      let location = {
        ip: '',
        city: '',
        state: '',
        country: '',
      };
      const locationResponse = await fetch('https://api.geoapify.com/v1/ipinfo?apiKey=43fce59071bf4235804363c5f18e5f32', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (locationResponse) {
        const locationData = await locationResponse.json();
        location = {
          ip: locationData.ip,
          city: locationData.city.name,
          state: locationData.state.name,
          country: locationData.country.name,
        };
      }

      const url = `${config.url}/account/get-account/${auth.user.attributes.id}?ip=${location.ip}&city=${location.city}&state=${location.state}&country=${location.country}`;
      const response = await fetch(`${url}`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${auth.user.tokens.AccessToken}`, 'Content-Type': 'application/json' },
      });
      if (response && response.ok) {
        const data = await response.json();
        setAccount(data.account);
        setSelectedAccount(data.account);
        setSelectedWorkspace(selectedWorkspace.SK ? selectedWorkspace : data.account.workspaces[0]);
        setEnvironments(selectedWorkspace.environments ? selectedWorkspace.environments : data.account.workspaces[0].environments.sort().reverse());
        setSelectedEnvironmentCallback(selectedEnvironment || data?.account?.workspaces[0]?.environments[0]);
      }
    }
  };

  const createClient = (userId: string) => {
    const newClient = new ReconnectingWebSocket(process.env.WSS_URL ? process.env.WSS_URL : 'wss://yjogxztgs1.execute-api.us-east-1.amazonaws.com/default/');
    newClient.addEventListener('open', () => {
      newClient.send(JSON.stringify({ event: 'hai', userId }));
    });
    newClient.addEventListener('message', (messageEvent: any) => {
      const messageData = JSON.parse(messageEvent.data);
      if (messageData.message) notify({ message: messageData.message });
      if (messageData?.environmentChanged) setEnvironmentChanged(true);
    });
    setWsClient(newClient);
    return newClient;
  };

  useEffect(() => {
    if (user?.account && user.account.PK && user?.tokens?.AccessToken && !wsClient) {
      const newClient = createClient(user.account.PK);
      setWsClient(newClient);
    }
  }, [user?.account, user?.tokens?.AccessToken]);

  useEffect(() => {
    const marketPlaceToken = getUrlParameter('x-amzn-marketplace-token', history);
    const canceled = !!qs.parse(history.location.search, { ignoreQueryPrefix: true }).canceled;
    if (canceled) {
      setUpdateBilling(true);
      history.replace({ search: '' });
    }
    if (marketPlaceToken.length > 0) {
      setMktplaceToken(marketPlaceToken);
      setIsAmazonAccount(true);
    }
  }, [auth.user?.account, history.location.search, mktplaceToken]);

  useEffect(() => {
    const currentPath = history.location.pathname.substring(1);
    if (currentPath) redirectToURL(currentPath);
    setNav(
      nav.map((item: any) => {
        if (item.href === `/${currentPath}`) return { ...item, current: true };
        return { ...item, current: false };
      }),
    );
  }, []);

  useEffect(() => {
    if (auth.user && auth.user.account) {
      setAccountCallback(auth.user.account);
      setSelectedAccount(auth.user.account);
      setSelectedWorkspace(selectedWorkspace?.length > 1 ? selectedWorkspace : auth.user.account.workspaces[0]);
      setEnvironments(selectedWorkspace?.environments ? selectedWorkspace?.environments : auth.user.account.workspaces[0].environments.sort().reverse());
      setSelectedEnvironmentCallback(selectedEnvironment || auth.user.account.workspaces[0].environments[0]);
    }
    if (auth.user && auth.user.tokens) initialAccountFetch();
  }, [auth.user]);

  if (user && user.tokens) {
    options = {
      headers: { Authorization: `Bearer ${user.tokens.AccessToken}` },
      cacheLife: 100,
      interceptors: {
        response: ({ response }: OptionsResponse) => {
          if (response.status === 401) {
            handleLogout();
          }
          return response;
        },
      },
    };
  }

  const contextValue = useMemo(() => ({ wsClient, notify, createClient }), [wsClient]);

  if (user && user.account) {
    return (
      <ThemeProvider>
        <WebSocketContext.Provider value={contextValue}>
          <div className="flex flex-col h-screen">
            <ChatwootWidget />
            <Toaster />
            <Provider url={config.url} options={options}>
              <Navigation
                user={user}
                account={account}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspaceModal={setSelectedWorkspaceModal}
                logout={() => handleLogout()}
                nav={nav}
                history={history}
                updateNav={updateNav}
                redirectToURL={(url: string) => redirectToURL(url)}
                version={version}
              />
              <WorkspaceSelectModal
                user={user}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspaceHandler={setSelectedWorkspace}
                closeSelectModal={() => setSelectedWorkspaceModal(false)}
                open={selectedWorkspaceModal}
                getAccount={getAccount}
              />
              <Content
                account={account}
                setAccount={setAccountCallback}
                getAccount={getAccount}
                environments={environments}
                history={history}
                updateBillingCustomer={updateBillingCustomer}
                selectedEnvironment={selectedEnvironment}
                setupEnvironmentsHandler={setEnvironments}
                setSelectedEnvironment={setSelectedEnvironmentCallback}
                selectedAccount={selectedAccount}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspace={setSelectedWorkspace}
                setSelectedAccount={setSelectedAccount}
                redirectToURL={(url: string) => redirectToURL(url)}
                notify={notify}
                environmentChanged={environmentChanged}
                setEnvironmentChanged={setEnvironmentChanged}
              />
            </Provider>
          </div>
        </WebSocketContext.Provider>
      </ThemeProvider>
    );
  }
  if (!user || !user.account) {
    return <Product user={user} isAmazonAccount={isAmazonAccount} mktplaceToken={mktplaceToken} setNavHighlight={setNavHighlight} />;
  }
}
