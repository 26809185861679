import uuid from 'react-uuid';
import Hl7Standard from 'hl7-standard';

const match = (s, p, sIndex, pIndex, dp) => {
  if (dp[pIndex][sIndex] !== undefined) return dp[pIndex][sIndex];
  const sNow = s[sIndex];
  const pNow = p[pIndex];
  let res = false;
  if (pNow === undefined) return sNow === undefined;
  if (sNow === undefined) {
    for (let i = pIndex; i < p.length; i++) {
      if (p[i] !== '*') return false;
    }
    return true;
  }
  if (sNow === pNow || pNow === '?') {
    res = match(s, p, sIndex + 1, pIndex + 1, dp);
  } else if (pNow === '*') {
    res = match(s, p, sIndex, pIndex + 1, dp) || match(s, p, sIndex + 1, pIndex + 1, dp) || match(s, p, sIndex + 1, pIndex, dp);
  }
  dp[pIndex][sIndex] = res;
  return res;
};
const swapWithUserKeys = (obj, oldKey, newKey) => {
  const updatedObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      updatedObj[key] = swapWithUserKeys(value, oldKey, newKey);
    } else {
      updatedObj[key === oldKey ? newKey : key] = value;
    }
  }
  return updatedObj;
};
const detectSegmentShape = (segmentData, segmentField, field, segmentCount) => {
  if (segmentCount < 2) {
    if (typeof segmentData.data[field] === 'string') return 'field';
    if (typeof segmentData.data[segmentField] === 'string') return 'subfield';
    if (Array.isArray(segmentData.data[segmentField])) return 'complex';
  }
  if (segmentCount > 1) {
    if (Array.isArray(segmentData.data[segmentField])) return 'complex-repeat';
    if (typeof segmentData.data[field] === 'string') return 'repeat-simp';
  }
  if (typeof segmentData.data == 'object') {
    if (segmentData?.data[segmentField] && Object.entries(segmentData.data[segmentField]).length < 2) return 'repeat';
    if (segmentData?.data[segmentField] && Object.entries(segmentData.data[segmentField]).length > 1) return 'no-repeat';
  }
  return 'unknown';
};
const flattenShortKeys = (payload) => {
  const updatedPayload = {};

  for (const [key, value] of Object.entries(payload)) {
    if (typeof key === 'string' && key.length < 3 && typeof value === 'object' && !Array.isArray(value)) {
      Object.assign(updatedPayload, flattenShortKeys(value)); // Flatten the object into the parent
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      updatedPayload[key] = flattenShortKeys(value); // Recursive call for nested objects
    } else {
      updatedPayload[key] = value; // Simply assign the value if none of the above conditions are met
    }
  }

  return updatedPayload;
};
const expandSegment = (segmentData, segment, segmentField, field, innerReplacements) => {
  const repeatingPayloads = [];
  segmentData.data[segmentField].forEach((repeatingData) => {
    const repeatingPayload = {};
    if (Object.keys(repeatingData).length > 1) {
      Object.keys(repeatingData).forEach((repeatingField) => {
        const replacementKey = innerReplacements[repeatingField] || repeatingField;
        repeatingPayload[replacementKey] = repeatingData[repeatingField];
      });
    } else {
      Object.assign(repeatingPayload, repeatingData);
    }
    if (Object.keys(repeatingPayload).length > 0) {
      repeatingPayloads.push(repeatingPayload);
    }
  });
  return repeatingPayloads;
};
const orderSegment = (segmentData, fieldMapping) => {
  const newSegmentData = {};

  for (let [oldKey, newKey] of Object.entries(fieldMapping)) {
    if (segmentData[oldKey]) {
      newSegmentData[newKey] = segmentData[oldKey];
    }
  }

  return newSegmentData;
};
const createFullUrl = (resourceType) => {
  return `urn:uuid:${resourceType}-${uuid()}`;
};
const addDataToResource = (resource, dots, sampleValue) => {
  const pathParts = dots.split('.');
  let currentPart = resource;

  pathParts.forEach((part, index) => {
    if (index === pathParts.length - 1) {
      if (part.includes('[')) {
        const match = part.match(/(\w+)\[(\d+)\]/);
        const arrName = match[1];
        const arrIndex = parseInt(match[2], 10);
        currentPart[arrName] = currentPart[arrName] || [];
        currentPart[arrName][arrIndex] = currentPart[arrName][arrIndex] || {};
        currentPart[arrName][arrIndex] = sampleValue;
      } else {
        currentPart[part] = sampleValue;
      }
    } else {
      if (!currentPart[part]) currentPart[part] = {};
      currentPart = currentPart[part];
    }
  });
  return resource;
};
const createFHIRResource = (fields, sampleMessage) => {
  const resources = {};
  const sampleHL7 = new Hl7Standard(sampleMessage);
  sampleHL7.transform(async (err) => {
    if (err) console.log(err);
  });
  for (const [key, dotNotationPath] of Object.entries(fields)) {
    const resourceType = dotNotationPath.split('.')[0].charAt(0).toUpperCase() + dotNotationPath.split('.')[0].slice(1);
    const sampleValue = key ? sampleHL7.get(key) : '';
    if (!resources[resourceType]) resources[resourceType] = { resourceType };
    addDataToResource(resources[resourceType], dotNotationPath.substring(resourceType.length + 1), sampleValue);
  }
  return Object.values(resources).map((resource) => ({
    fullUrl: createFullUrl(resource.resourceType),
    resource,
  }));
};
export const flattenSampleDataNoAmpersand = (data, prefix) => {
  const flattenedData = [];
  const flattenObject = (obj, parentKey) => {
    for (const [key, value] of Object.entries(obj)) {
      let newKey = key;
      if (key.length < 5) {
        newKey = `${parentKey}`;
      }

      if (typeof value === 'object' && !Array.isArray(value) && !key.includes('^')) {
        flattenObject(value, newKey);
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const repeatedKey = `${newKey}.${i + 1}`;
          if (typeof value[i] === 'object') {
            flattenObject(value[i], repeatedKey);
          } else if (typeof value[i] === 'string' && (value[i].includes('^') || value[i].includes('~'))) {
            splitValue(value[i], repeatedKey);
          } else {
            flattenedData.push([repeatedKey, value[i]]);
          }
        }
      } else if (typeof value === 'string') {
        splitValue(value, newKey);
      } else {
        flattenedData.push([newKey, value]);
      }
    }
  };

  const splitValue = (value, key) => {
    const splitSymbols = ['^', '~'];
    let subValues = [value];

    splitSymbols.forEach((symbol) => {
      let tempSubValues = [];
      subValues.forEach((subValue) => {
        tempSubValues.push(...subValue.split(symbol));
      });
      subValues = tempSubValues;
    });

    subValues.forEach((subValue) => {
      const subValueKey = `${key}`;
      flattenedData.push([subValueKey, subValue]);
    });
  };

  flattenObject(data, prefix);
  return flattenedData;
};
export const flattenSampleData = (data, prefix) => {
  const flattenedData = [];
  const flattenObject = (obj, parentKey) => {
    for (const [key, value] of Object.entries(obj)) {
      let newKey = key;
      if (key.length < 5) {
        newKey = `${parentKey}`;
      }

      if (typeof value === 'object' && !Array.isArray(value) && !key.includes('^')) {
        flattenObject(value, newKey);
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const repeatedKey = `${newKey}`;
          if (typeof value[i] === 'object') {
            flattenObject(value[i], repeatedKey);
          } else if (typeof value[i] === 'string' && (value[i].includes('^') || value[i].includes('~'))) {
            splitValue(value[i], repeatedKey);
          } else {
            flattenedData.push([repeatedKey, value[i]]);
          }
        }
      } else if (typeof value === 'string') {
        splitValue(value, newKey);
      } else {
        flattenedData.push([newKey, value]);
      }
    }
  };

  const splitValue = (value, key) => {
    const splitSymbols = ['^', '~'];
    let subValues = [value];

    splitSymbols.forEach((symbol) => {
      let tempSubValues = [];
      subValues.forEach((subValue) => {
        tempSubValues.push(...subValue.split(symbol));
      });
      subValues = tempSubValues;
    });

    subValues.forEach((subValue) => {
      const subValueKey = `${key}`;
      flattenedData.push([subValueKey, subValue]);
    });
  };

  flattenObject(data, prefix);
  return flattenedData;
};
export const isMatch = (s, p) => {
  const dp = Array(p.length + 1)
    .fill(0)
    .map(() => ({}));
  return match(s, p, 0, 0, dp);
};
export const transformPayload = async (hl7, lookups) => {
  for (let i = 0; i < Object.entries(lookups).length; i++) {
    const lookup = Object.entries(lookups)[i][1];
    if (lookup.field) {
      const messageValue = hl7.get(lookup.field);
      if (!messageValue && lookup?.default) {
        hl7.set(lookup.field, lookup.default);
      } else if (lookup.map) {
        for (let j = 0; j < Object.entries(lookup.map).length; j++) {
          const { value, key } = lookup.map[j];
          if (key && value) {
            if (isMatch(messageValue, key)) {
              hl7.set(lookup.field, value);
            }
          }
        }
      }
    }
  }
  return hl7.build();
};
export const removeEmptyEntries = (payload) => {
  const updatedPayload = {};
  for (const [key, value] of Object.entries(payload)) {
    if (Array.isArray(value) && value.length === 0) {
      continue;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      updatedPayload[key] = removeEmptyEntries(value);
      if (Object.keys(updatedPayload[key]).length === 0) {
        delete updatedPayload[key];
      }
    } else {
      updatedPayload[key] = value;
    }
  }
  return updatedPayload;
};
export const createFHIRBundle = (fields, sampleMessage) => {
  const entries = createFHIRResource(fields, sampleMessage);
  return {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: entries.map((entry) => ({ Resource: entry })),
  };
};
export const buildPayload = (workflowFields, sampleMessage) => {
  let payload = {};
  const fieldMap = Object.entries(workflowFields);
  fieldMap.forEach(([key, value]) => {
    const segment = key.split('.')[0];
    const field = `${segment}.${key.split('.')[1]}`;
    const seg = key.substring(0, key.length - 2);
    sampleMessage.getSegments(segment).forEach((segmentObject, idx) => {
      let segmentPayload;
      const segmentCount = sampleMessage.getSegments(segment).length;
      const dataType = detectSegmentShape(segmentObject, field, key, segmentCount);
      if (!payload[segment] && segmentCount < 2) payload[segment] = {};
      if (!payload[segment] && segmentCount > 1) payload[segment] = [];
      const segments = sampleMessage.getSegments(segment);
      switch (dataType) {
        case 'field':
          payload[segment][value] = sampleMessage.get(key);
          break;
        case 'subfield':
          payload[segment][value] = segmentObject.data[field][key];
          break;
        case 'complex':
          const pay = expandSegment(segmentObject, segment, field, key, value);
          segmentPayload = pay.map((subPayload) => orderSegment(subPayload, workflowFields));
          if (!payload[segment][seg]) payload[segment][seg] = {};
          payload[segment][seg] = segmentPayload;
          break;
        case 'repeat':
          payload[segment][value] = segmentObject.data[field][key];
          break;
        case 'no-repeat':
          if (!payload[segment][idx]) payload[segment][idx] = {};
          payload[segment][idx][value] = segmentObject.data[field][key];
          break;
        case 'complex-repeat':
          const array = segmentObject.data[field];
          array.forEach((subArray) => {
            if (!payload[segment][idx]) payload[segment][idx] = {};
            if (!payload[segment][idx][value]) payload[segment][idx][value] = [];
            payload[segment][idx][value].push(subArray[key]);
          });
          break;
        case 'repeat-simp':
          Object.entries(segments).forEach((Segment, i = 0) => {
            if (i == idx) {
              if (!payload[segment][idx]) payload[segment][idx] = {};
              payload[segment][idx][value] = Segment[1].data[field];
            }
            i++;
          });
          break;
        default:
          break;
      }
      idx++;
    });
  });
  return removeEmptyEntries(flattenShortKeys(payload));
};
