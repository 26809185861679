import { createContext, useContext, useEffect, useState } from 'react';

interface ThemeContextProps {
  theme: string;
  effectiveTheme: string;
  toggleTheme: (newTheme: string) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState<string>(() => localStorage.getItem('theme') || 'system');
  const [effectiveTheme, setEffectiveTheme] = useState<string>('light');

  useEffect(() => {
    const root = window.document.documentElement;

    const applyTheme = (theme: string) => {
      root.classList.remove('light', 'dark');
      if (theme === 'system') {
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        root.classList.add(prefersDarkMode ? 'dark' : 'light');
        setEffectiveTheme(prefersDarkMode ? 'dark' : 'light');
      } else {
        root.classList.add(theme);
        setEffectiveTheme(theme);
      }
    };

    applyTheme(theme);

    const systemThemeListener = (e: MediaQueryListEvent) => {
      if (theme === 'system') {
        applyTheme(e.matches ? 'dark' : 'light');
      }
    };

    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    darkModeMediaQuery.addEventListener('change', systemThemeListener);

    return () => {
      darkModeMediaQuery.removeEventListener('change', systemThemeListener);
    };
  }, [theme]);

  const toggleTheme = (newTheme: string) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return <ThemeContext.Provider value={{ theme, effectiveTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
}
