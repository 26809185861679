import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CogIcon } from '@heroicons/react/24/outline'
import Toggle from '../../Base/Elements/Input/Toggle'

export default function UserPermissionsModal(props: any) {
  if (props.selectedUser) {
    return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"  onClose={props.close}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <Transition.Child as={Fragment} 
              enter="ease-out duration-300" 
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
              enterTo="opacity-100 translate-y-0 sm:scale-100" 
              leave="ease-in duration-200" 
              leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-2 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="px-1 pb-6 inline-flex">
                  <div className="absolute top-4 left-4 px-1 inline">
                    <CogIcon className="h-10 w-10 text-gray-500 bg-white rounded-md inline" />
                    <Dialog.Title as="h3" className="text-xl font-medium text-gray-500 inline"> &nbsp; {props.selectedUser ? props.selectedUser.email : ''} </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2 sm:mt-6 border border-gray-300 bg-white p-1 rounded-md shadow-sm">
                  <div className="sm:grid sm:grid-cols-12 sm:gap-1 bg-white p-1">
                  <div className='col-span-3 text-md'>Workspace</div>
                    <div className='col-span-9 text-xs'><Toggle name={'admin-set'} enabled={JSON.parse(props.selectedUser.permissions.workspace.admin)} onChange={() => props.updatePermissions('workspace', 'admin', props.selectedUser)} />&nbsp;&nbsp;Admin </div>
                    <div className='col-span-3 text-md'>Dashboard</div>
                    <div className='col-span-9 text-xs'><Toggle name={'dashboard-view'} enabled={JSON.parse(props.selectedUser.permissions.dashboard.read)} onChange={() => props.updatePermissions('dashboard', 'read', props.selectedUser)} />&nbsp;View </div>
                    <div className='col-span-3 text-md'>Workflows</div>
                    <div className='col-span-2 text-xs'><Toggle name={'workflows-view'} enabled={JSON.parse(props.selectedUser.permissions.workflows.read)} onChange={() => props.updatePermissions('workflows', 'read', props.selectedUser)} />&nbsp;View </div>
                    <div className='col-span-2 text-xs'><Toggle name={'workflows-create'} enabled={JSON.parse(props.selectedUser.permissions.workflows.create)} onChange={() => props.updatePermissions('workflows', 'create', props.selectedUser.id)} />&nbsp;Create </div>
                    <div className='col-span-2 text-xs'><Toggle name={'workflows-update'} enabled={JSON.parse(props.selectedUser.permissions.workflows.update)} onChange={() => props.updatePermissions('workflows', 'update', props.selectedUser.id)} />&nbsp;Modify </div>
                    <div className='col-span-2 text-xs'><Toggle name={'workflows-delete'} enabled={JSON.parse(props.selectedUser.permissions.workflows.delete)} onChange={() => props.updatePermissions('workflows', 'delete', props.selectedUser.id)} />&nbsp;Delete </div>
                    <div className='col-span-3 text-md'>Tunnels</div>
                    <div className='col-span-2 text-xs'><Toggle name={'tunnels-view'} enabled={JSON.parse(props.selectedUser.permissions.tunnels.read)} onChange={() => props.updatePermissions('tunnels', 'read', props.selectedUser.id)} /> </div>
                    <div className='col-span-2 text-xs'><Toggle name={'tunnels-create'} enabled={JSON.parse(props.selectedUser.permissions.tunnels.create)} onChange={() => props.updatePermissions('tunnels', 'create', props.selectedUser.id)} />&nbsp;Create </div>
                    <div className='col-span-2 text-xs'><Toggle name={'tunnels-delete'} enabled={JSON.parse(props.selectedUser.permissions.tunnels.delete)} onChange={() => props.updatePermissions('tunnels', 'delete', props.selectedUser.id)} />&nbsp;Delete </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button 
                    type="button" 
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-cyan-300 shadow-sm px-4 py-2 bg-cyan-400 text-base font-medium text-white 
                    hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => props.close()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    )
  } else {
    return (<div></div>)
  }
}
