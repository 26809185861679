import {XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, makeVisFlexible } from 'react-vis';
const FlexibleXYPlot = makeVisFlexible(XYPlot);

const style = { strokeWidth: 2, strokeRadius: '40px'};
const opacity = 0.8;

export default function StackedBarChart(props: any) {
  return (
    <FlexibleXYPlot xType="ordinal" stackBy="y">
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis style={{
          text: { fill: '#d3d3d3', fontSize: '10px' }
        }} />
      <YAxis
        tickFormat={(tickValue) => {
          const roundedTickValue = tickValue == Math.ceil(tickValue) ? tickValue : '';
          return roundedTickValue.toLocaleString();
        }}
        style={{
          text: { fill: '#d3d3d3', fontSize: '10px'}
        }}
      />
      { props.workflows.map((workflow: any) => (
      <VerticalBarSeries key={`${workflow.SK}-successes`} 
        stroke="#22d3ee" fill="#06b6d4"  
        opacity={opacity} style={style}
        barWidth={workflow.gridSuccesses.length > 10 ? 0.98 : 0.85} 
        data={workflow.gridSuccesses ? workflow.gridSuccesses.data : [{}]}
      />
      ))}
      { props.workflows.map((workflow: any) => (
      <VerticalBarSeries key={`${workflow.SK}-warnings`} 
        stroke='#fdba74' fill="#fb923c" opacity={opacity} style={style}
        barWidth={workflow.gridErrors.length > 10 ? 0.98 : 0.85} 
        data={workflow.gridErrors ? workflow.gridErrors.data : [{}]} />
      ))}
    </FlexibleXYPlot>
  );
}
