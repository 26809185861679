import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import logo from '../../../images/retrohook.png';
import {useAuth} from "../../hooks/useAuth";
import {Redirect} from "react-router-dom";
import useFetch from 'use-http';

const RegisterButton = (props: any) => {
  if (props.loading) {
    return (
      <button type="button" onClick={props.handleRegister} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-cyan-300" />
        </div>
      </button>
    )
  }
   else {
    return (
      <button type="button" onClick={props.handleRegister} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"/>
          </svg>
        </span>
        Finalize AWS Registration
      </button>
    );
  }
}

export default function AWSRegisterModal(props: any) {
  const auth = useAuth();
  const { post, response, loading } = useFetch('https://subscriptions.api.retrohook.com');
  const [organization, setOrganization] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [awsTokenData, setAwsTokenData] = useState<any>({});
  const [quantityPurchased, setQuantityPurchased] = useState<number>(0);
  const [productCode, setProductCode] = useState<string>("");
  const [customerIdentifier, setCustomerIdentifier] = useState<string>("");
  const [ productExpiration, setProductExpiration ] = useState<string>("");
  const [rawToken, setRawToken] = useState<string>(props.mktplaceToken);

  const getTokenData = async (mktplaceToken: any) => {
    const tokenRequest: any = await post(`/marketplace/aws-token`, { token: mktplaceToken });
    if (tokenRequest) { 
      setAwsTokenData(tokenRequest); 
      setCustomerIdentifier(tokenRequest.customerEntitlements.Entitlements[0].CustomerIdentifier);
      setProductExpiration(tokenRequest.customerEntitlements.Entitlements[0].ExpirationDate);
      setProductCode(tokenRequest.customerEntitlements.Entitlements[0].ProductCode);
      setQuantityPurchased(tokenRequest.customerEntitlements.Entitlements[0].Value.IntegerValue);
      setRawToken(props.regToken);
    }
  };
  const renderFormFields = () => { 
    return (
      <div className="animate-in fade-in">
        <input type="hidden" name="remember" value="true"/>
        <div className="rounded-md shadow-sm -space-y-px">
        { errorMessage.length > 0 && <div className="text-red-500 text-sm pb-2">{errorMessage}</div>}
          <p className="text-gray-600 dark: text-gray-200 text-xs mb-0">
            Thanks for purchasing Retrohook with <span className='text-cyan-400'>{ quantityPurchased }</span> production environment(s).
          </p>
          <div className="px-0 mx-0 pt-2">
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autoComplete="email" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-t-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Email Address" value={email} onChange={(e) => { setEmail(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="username" className="sr-only">Organization</label>
            <input id="username" name="username" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Organization (Optional)" value={organization} onChange={(e) => { setOrganization(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="first-name" className="sr-only">First Name</label>
            <input id="first-name" name="firstName" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="last-name" className="sr-only">Last Name</label>
            <input id="last-name" name="lastName" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-b-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }}/>
          </div>
          <p className="text-gray-600 dark: text-gray-200 text-xs pt-4 mb-0">Passwords must be at least 8 characters,</p>
          <p className="text-gray-600 dark: text-gray-200 text-xs mb-0">contain an Uppercase, lowercase, number,</p>
          <p className="text-gray-600 dark: text-gray-200 text-xs pb-4">and special character.</p>
          <div className="px-0 mx-0">
            <label htmlFor="password" className="sr-only">Set Password</label>
            <input id="password" name="password" type="password" autoComplete="current-password" required onKeyDown={(e) => { if (e.key === "Enter") { handleRegister(); } } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-t-md
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="confirm" className="sr-only">Confirm Password</label>
            <input id="confirm" name="confirm" type="password" autoComplete="current-password" required onKeyDown={(e) => { if (e.key === "Enter") { handleRegister(); } } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-b-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Confirm Password" value={confirm} onChange={(e) => { setConfirm(e.target.value)}}/>
          </div>
          <input hidden disabled id="awsCustomerId" name="awsCustomerId" type="text" required value={customerIdentifier} />
          <input hidden disabled id="productCode" name="productCode" type="text" required value={productCode} />
          <input hidden disabled id="productExpiration" name="productExpiration" type="text" required value={productExpiration} />
          <input hidden disabled id="rawToken" name="rawToken" type="text" required value={props.regToken} />
        </div>
      </div>
    );
  }
  const handleRegister = async () => {
    setErrorMessage('');
    try {
      if (password !== confirm) { setErrorMessage('Passwords do not match.'); return; }
      const token: string = props.mktplaceToken as string;
      const registerResult = await auth.registerAWS(email, organization, firstName, lastName, password, confirm, customerIdentifier, productCode, quantityPurchased, productExpiration, token);
      if (registerResult) {
        props.setEmail(email);
        props.setVerificationCodeModel();
        props.closeModal();
      }
    } catch (er: any) { setErrorMessage(er.message); }
  };
  
  if (auth.user) { return <Redirect to={"/"}/> }
  useEffect(() => { getTokenData(props.mktplaceToken); setRawToken(JSON.stringify(props.mktplaceToken)) }, [JSON.stringify(props.mktplaceToken)]);
 
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"  onClose={ () => { props.closeModal}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child as={Fragment} 
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enterTo="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom items-center w-auto animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-2">
              <button type="button" className="absolute right-2 top-2 border-2 border-gray-500 rounded shadow px-2 py-1 text-base text-pink-400 bg-gray-600 outline-none hover:ring-1 hover:ring-pink-500" onClick={ () => { props.closeModal() }} >X</button>
              <div className="px-1 inline-flex items-center">
                <div className="animate-color-change-2x dark:animate-color-change-2x-dark px-6 pb-2">
                  <div className="items-center">
                    <img className="h-24 mx-auto w-auto" src={logo} alt="Logo"/>
                    <h2  className="mt-0 text-center text-2xl font-semibold text-gray-600 dark: text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook
                      <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1"></span>
                    </h2>
                  </div>
                  <form className="mt-2" action="#" method="POST">{renderFormFields()}</form>
                </div>
              </div>
            <div className="py-2 px-4">
            <RegisterButton handleRegister={handleRegister} loading={loading} />
            </div>
          </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
