import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import logo from '../../../images/retrohook.png';

const LoginButton = (props: any) => {
  if (props.loading) {
    return (
      <button type="button" onClick={props.handleLogin} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-cyan-300" />
        </div>
      </button>
    )
  }
  if (props.forgotPassword) {
    return (
      <button type="button" onClick={props.handleForgotPassword} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
          </svg>
        </span>
        Send Reset Code
      </button>
    );
  } else if (props.verifyCodeModal) {
    return (
      <button type="button" onClick={props.handleVerifyCode} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
          </svg>
        </span>
        Verify Code
      </button>
    );
  } else if (props.shouldSetNewPassword) {
    return (<button type="button" onClick={() => { props.handleSetNewPassword(props.newPassword) }} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
        <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
        </svg>
      </span>
      Set New Password
    </button>
    );
  }
  else {
    return (
      <>
        <button type="button" onClick={props.handleLogin} className="group relative w-full flex justify-center px-4 py-2 border border-transparent text-xl font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
            </svg>
          </span>
          Sign In
        </button>
      </>
    );
  }
};

export default function LoginModal(props: any) {
  const auth = useAuth();
  let history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [shouldSetNewPassword, setShouldSetNewPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [verifyCodeModal, setVerifyCodeModal] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<string>();

  const handleLogin = async () => {
    clearErrorMessage();
    setLoading(true);
    try {
      const loginResult = await auth.login(email, password);
      if (loginResult && loginResult.FORCE_PASSWORD_CHANGE) {
        setShouldSetNewPassword(true);
        setLoading(false);
      } else {
        history.push('/');
        props.setNavHighlight('Dashboard');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };
  const handleSetNewPassword = async (newPassword: string) => {
    try {
      await auth.newPasswordChange({
        username: email,
        password: password,
        newPassword: newPassword
      }).then(async () => {
        setShouldSetNewPassword(false);
        await handleLogin();
      });
    } catch (e: any) {
      console.error(e);
      setErrorMessage(e.message);
      setLoading(false);
    }
  };
  const handleForgotPassword = async () => {
    clearErrorMessage();
    try {
      setLoading(true);
      await auth.forgotPassword(email);
      setLoading(false);
      setForgotPassword(false);
      setVerifyCodeModal(true);
    } catch (e: any) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };
  const handleVerifyCode = async () => {
    clearErrorMessage();
    try {
      setLoading(true);
      const verifyCodeResult = await auth.confirmPasswordReset(email, verifyCode, newPassword);
      console.dir(verifyCodeResult);
      setLoading(false);
      setVerifyCodeModal(false);
    } catch (e: any) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };
  const clearErrorMessage = () => {
    setErrorMessage('');
  };
  const renderFormFields = () => {
    if (shouldSetNewPassword) {
      return (
        <div className="rounded -space-y-px">
          <p className="text-gray-600 text-md">Since this is your first time logging in</p>
          <p className="text-gray-600 text-md pb-2">please set a new password.</p>
          <p className="text-orange-700 text-xs mb-0">Your new password must be at least 8 characters,</p>
          <p className="text-orange-700 text-xs mb-0">contain an Uppercase, lowercase, number,</p>
          <p className="text-orange-700 text-xs mb-4">and special character.</p>
          <div>
            { errorMessage.length > 0 && <div className="text-pink-500 text-sm py-2">{errorMessage}</div>}
            <label htmlFor="new-password" className="sr-only">New password</label>
            <input id="new-password" name="new-password" type="password" required onKeyDown={(e: any) => { if (e.key === "Enter") { handleSetNewPassword(e.target.value)} } }
              className="appearance-none mt-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 
              placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="New password" value={newPassword}
              onChange={(e) => { setNewPassword(e.target.value) }}
            />
          </div>
        </div>
      );
    } else {
      if (forgotPassword) {
        return (
          <div>
            <input type="hidden" name="remember" value="true"/>
            <div className="rounded -space-y-px">
            { errorMessage.length > 0 && <div className="text-pink-500 text-sm pb-2">{errorMessage}</div>}
              <div className="px-0 mx-0">
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input id="email-address" name="email" type="email" autoComplete="email" required onKeyDown={(e) => { if (e.key === "Enter") {handleLogin()} } }
                  className="appearance-none rounded-md relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
                  placeholder="Email address" value={email} onChange={(e) => { setEmail(e.target.value) }}
                />
              </div>
            </div>
          </div>
        );
      } else if (verifyCodeModal) {
        return (
          <div>
            <input type="hidden" name="remember" value="true"/>
            <div className="rounded -space-y-px">
            { errorMessage.length > 0 && <div className="text-pink-500 text-sm pb-2">{errorMessage}</div>}
            <p className="text-cyan-400 text-md">Since this is your first time logging in</p>
            <p className="text-cyan-400 text-md pb-2">please set a new password.</p>
            <p className="text-orange-700 text-xs mb-0">Your new password must be at least 8 characters,</p>
            <p className="text-orange-700 text-xs mb-0">contain an Uppercase, lowercase, number,</p>
            <p className="text-orange-700 text-xs mb-4">and special character.</p>
              <div className="px-0 mx-0">
                <label htmlFor="new-password" className="sr-only">New Password</label>
                <input className="appearance-none mt-4 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-200 focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
                  id="new-password" 
                  name="new-password" 
                  type="password" 
                  required
                  placeholder="New password" 
                  value={newPassword} 
                  onKeyDown={(e: any) => { if (e.key === "Enter") { setNewPassword(e.target.value); }}}
                  onChange={(e) => { setNewPassword(e.target.value); setLoading(false); }}/>
              </div>
              <div className="px-0 mx-0">
                <label htmlFor="verify-code" className="sr-only">Verification Code</label>
                <input className="appearance-none rounded-md relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
                  placeholder="Verification Code" 
                  id="verify-code" 
                  name="verify-code" 
                  type="text" 
                  required 
                  value={verifyCode} 
                  onKeyDown={(e) => { if (e.key === "Enter") { setVerifyCode(e.target.value) }}}
                  onChange={(e) => { setVerifyCode(e.target.value) }}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <input type="hidden" name="remember" value="true"/>
            <div className="rounded -space-y-px">
            { errorMessage.length > 0 && <div className="text-pink-500 text-sm pb-2">{errorMessage}</div>}
              <div className="px-0 mx-0 w-full">
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input id="email-address" name="email" type="email" autoComplete="email" required onKeyDown={(e) => { if (e.key === "Enter") {handleLogin()} } }
                      className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-t-md focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
                      placeholder="Email address" value={email} onChange={(e) => {
                  setEmail(e.target.value)
                }}/>
              </div>
              <div className="px-0 mx-0">
                <label htmlFor="password" className="sr-only">Password</label>
                <input id="password" name="password" type="password" autoComplete="current-password" required onKeyDown={(e) => { if (e.key === "Enter") { handleLogin(); } } }
                      className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-b-md focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
                      placeholder="Password" value={password} onChange={(e) => {
                  setPassword(e.target.value)
                }}/>
              </div>
            </div>
        
            <div className="flex mt-1 mx-auto items-center justify-between w-full">
              <div className="text-xs items-center mx-auto">
                <a onClick={() => { setForgotPassword(true); }} className="font-medium text-cyan-400 hover:text-cyan-300">Forgot your password?</a>
              </div>
            </div>
          </div>
        );
      }
    }
  }
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed animate-in fade-in z-10 inset-0 overflow-y-auto"  onClose={ () => { props.closeModal}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child as={Fragment} 
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enterTo="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
          <div className="relative inline-block align-bottom  items-center w-auto animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left overflow-hidden transform transition-all sm:align-middle sm:p-2">
            <button type="button" className="absolute right-2 top-2 border-2 border-gray-200 dark:border-gray-500 rounded px-2 text-base text-pink-400 bg-transparent dark:animate-color-change-2x-dark outline-none hover:ring-1 hover:ring-pink-500" onClick={ () => { props.closeModal() }} >X</button>
              <div className="px-1 items-center">
                <div className="animate-color-change-2x dark:animate-color-change-2x-dark px-6 pb-2">
                  <div className="items-center">
                    <img className="h-24 mx-auto w-auto" src={logo} alt="Logo"/>
                    <h2  className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-100" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook
                    </h2>
                  </div>
                  <form className="mt-2" action="#" method="POST">{renderFormFields()}</form>
                </div>
              </div>
              <div className="p-4">
            <LoginButton 
              handleLogin={handleLogin} 
              loading={loading} 
              newPassword={newPassword}
              forgotPassword={forgotPassword} 
              handleForgotPassword={handleForgotPassword}
              handleVerifyCode={handleVerifyCode}
              verifyCodeModal={verifyCodeModal}
              shouldSetNewPassword={shouldSetNewPassword}
              handleSetNewPassword={handleSetNewPassword}
              email={email}
            />
              </div>
              <div className="p-4 text-xs items-center mx-auto">
              <a onClick={() => { props.setRegisterModal() }} className="font-medium text-cyan-400 hover:text-cyan-300">
                <span className="font-medium text-pink-600 hover:text-pink-500">Don't have an account?</span>
                &nbsp; <button className="bg-pink-500 border rounded py-1 px-2 text-white hover:ring-2 hover: ring-pink-400">Sign Up Free</button>
                </a>
            </div>
          </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
