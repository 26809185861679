/**
 * The ConfirmModal component is a reusable modal dialog box that displays a confirmation message with
 * a title and message, and provides options to cancel or confirm an action.
 * @param  - 1. `isOpen` - a boolean value indicating whether the modal is open or not.
 * @returns The ConfirmModal component is being returned.
 */
import { Dialog } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

export default function ConfirmModal({ isOpen, onClose, onConfirm, title, message }: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Overlay */}
      <div className="fixed inset-0 bg-gray-900/80" aria-hidden="true" />
      
      {/* Modal box */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded animate-color-change-2x dark:animate-color-change-2x-dark border-pink-400 border-2 p-6 shadow-xl">
          <ExclamationCircleIcon className="inline h-10 w-10 text-pink-400" aria-hidden="true" />
          <Dialog.Title className="inline pl-2 text-lg font-medium text-pink-400">{title}</Dialog.Title>
          <Dialog.Description className="text-sm text-gray-600 dark:text-gray-200">
            {message}
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-3">
            <button className="rounded bg-gray-200 px-4 py-2 text-sm text-gray-700 hover:bg-gray-300" onClick={onClose}>
              Cancel
            </button>
            <button className="rounded bg-pink-600 px-4 py-2 text-sm text-white hover:bg-pink-400" onClick={onConfirm}>
              I'm sure!
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

