import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import logo from '../../../images/retrohook.png';
import {useAuth} from "../../hooks/useAuth";
import {Redirect} from "react-router-dom";

const VerifyButton = (props: any) => {
  if (props.loading) {
    return (
      <button type="button" onClick={props.handleVerifyCode} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-cyan-500" />
        </div>
      </button>
    )
  }
   else {
    return (
      <button type="button" onClick={props.handleVerifyCode} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-cyan-500 group-hover:text-cyan-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"/>
          </svg>
        </span>
        Submit Code
      </button>
    );
  }
}

export default function VerificationCodeModal(props: any) {
  const auth = useAuth();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleVerifyCode = async () => {
    clearErrorMessage();
    try {
      setLoading(true);
      const result = await auth.verify(props.email, verificationCode);
      console.dir(result);
      if (result === "SUCCESS") {
        setLoading(false);
        props.setSignInModal();
      }
    } catch (e: any) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };
  const resendCode = async () => {
    clearErrorMessage();
    try {
      setLoading(true);
      await auth.sendCode(props.email);
      setLoading(false);
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  };
  const clearErrorMessage = () => {
    setErrorMessage('');
  };
  const renderFormFields = () => { 
    return (
      <div>
        <input type="hidden" name="remember" value="true"/>
        <div className="rounded-md shadow-sm -space-y-px">
        { errorMessage.length > 0 && <div className="text-red-500 text-sm pb-2">{errorMessage}</div>}
          <div className="px-0 mx-0">
            <label htmlFor="verification" className="sr-only">Verification Code</label>
            <input id="verification" name="verification" type="text" autoComplete="email" required onKeyDown={(e) => { if (e.key === "Enter") {handleVerifyCode()} } }
              className="appearance-none rounded-md relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 
              focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 focus:z-10 sm:text-sm"
              placeholder="Verification Code" value={verificationCode} onChange={(e) => { setVerificationCode(e.target.value)}}/>
          </div>
        </div>
        <div className="flex mt-1 mx-auto items-center justify-between w-full">
          <div className="text-xs items-center mx-auto">
            <a onClick={() => { resendCode(); }} className="font-medium text-cyan-600 hover:text-cyan-500">Resend Code</a>
          </div>
        </div>
      </div>
    );
  }
  if (auth.user) { return <Redirect to={"/"}/> }
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed  z-10 inset-0 overflow-y-auto"  onClose={ () => { props.closeModal}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child as={Fragment} 
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enterTo="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative fade-in animate-in inline-block align-bottom items-center w-auto animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:p-2">
            <button type="button" className="absolute right-2 top-2 border-2 border-gray-500 rounded shadow px-2 py-1 text-base text-pink-400 outline-none hover:ring-1 hover:ring-pink-500" onClick={ () => { props.closeModal() }} >X</button>
              <div className="px-1 inline-flex items-center">
                <div className="px-6 pb-2">
                  <div className="items-center">
                    <img className="h-24 mx-auto w-auto" src={logo} alt="Logo"/>
                    <h2  className="mt-0 text-center text-2xl font-bold text-gray-600" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">Please enter the verification code sent to your email address.</p>
                  </div>
                  <form className="mt-2" action="#" method="POST">{renderFormFields()}</form>
                </div>
              </div>
            <div className="py-2 px-4">
            <VerifyButton handleVerifyCode={handleVerifyCode} loading={loading} />
            </div>
          </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
