import { useEffect, useState } from "react";
import useFetch from 'use-http';
import config from "../../../config";
import { ArrowUpCircleIcon, BoltIcon, CloudIcon, CloudArrowUpIcon, VariableIcon, SparklesIcon, CheckCircleIcon, ExclamationCircleIcon, CodeBracketIcon, SquaresPlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import PageHeading from "../Base/Elements/Nav/PageHeading";
import moment from 'moment';
import MaximumNumberWorkflowsAlert from "../Base/Elements/Alerts/MaximumNumberWorkflowsAlert";
import EnvironmentDropdown from "../Base/Elements/Nav/EnvironmentDropdown";
import ActionButton from "../Base/Elements/Nav/ActionButton";
import ConfirmModal from "../Base/Elements/Confirmations/ConfirmModal";
import { IconPickerItem } from 'react-heroicons-picker';
import logo from "../../../images/retrohook.png";

export default function WorkflowsGrid(props: any) {
  const { post, response, loading } = useFetch(config.url);
  const [workflows, setWorkflows] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState({events: [], flowName: '', SK: '', environment: ''});
  const [showAccessAlert, setShowAccessAlert] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState({flowName: ''});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState(props.selectedEnvironment);
  const [selectedWorkspace, setSelectedWorkspace] = useState(props.selectedWorkspace);

  useEffect(() => {
    setSelectedEnvironment(props.selectedEnvironment);
    setSelectedWorkspace(props.selectedWorkspace);
    getWorkflows(props.selectedWorkspace, props.selectedEnvironment);
  }, [JSON.stringify(props.selectedWorkspace)]);

  const updateEnvironment = async (env: any) => {
    setSelectedEnvironment(env);
    props.setSelectedEnvironment(env);
    await getWorkflows(props.selectedWorkspace, env);
  }

  const getWorkflows = async (selectedWorkspace: any, selectedEnvironment: any) => {
    const body = { selectedWorkspace, selectedEnvironment };
    const workflowRequest: any = await post(`workflow/list-workflows/${props.user.attributes.id}`, body);
    if (response.ok && workflowRequest) {
      const workflows = workflowRequest.workflows;
      setWorkflows(workflows ? workflows : []);
    } else {
      setWorkflows([]);
    }
  }

  const closeProdRequiredAlert = () => {
    setShowAccessAlert(false);
  }

  const canDelete = () => {
    const hasUpdateWorkflowInSelectedWorkspace = selectedWorkspace.accounts.filter(
      (account: any) => {
        if (account.PK && account.permissions.workflows.delete) return account;
      }
    );
    const isWorkspaceOwner =
      selectedWorkspace.owner.email === props.user.account.email;
    if (
      props.admin === true ||
      hasUpdateWorkflowInSelectedWorkspace.length ||
      isWorkspaceOwner
    ) {
      return true;
    }
    return false;
  }
  /**
   * The function `editFlow` is an asynchronous function that takes a `flow` parameter and calls the
   * `loadWorkflow` function from the `props` object with the `flow` parameter.
   * @param {any} flow - The `flow` parameter is an object that represents a workflow.
   */
  const editFlow = async (flow: any) => { 
    props.loadWorkflow(flow) 
  }
  /**
   * The function `deleteWorkflow` deletes a workflow if the user has permission and updates the list
   * of workflows.
   * @param {any} flow - The `flow` parameter is an object that represents a workflow. It is passed to
   * the `deleteWorkflow` function to specify which workflow should be deleted.
   */
  const deleteWorkflow = async (flow: any) => {
    if (canDelete()) {
      const body = { selected: selectedEnvironment, flow };
      const deleteRequest = await post(`workflow/delete-workflow/${props.user.attributes.id}`, body);
      if (response.ok && deleteRequest) await getWorkflows(props.selectedWorkspace, selectedEnvironment);
      props.notify({ message: `Workflow Deleted` });
    }
  }
  /**
   * The function `openModal` sets the current tunnel and opens a modal.
   * @param {any} tunnel - The `tunnel` parameter is of type `any`, which means it can accept any data
   * type.
   */
  const openModal = (workflow: any) => {
    setCurrentWorkflow(workflow);
    setIsModalOpen(true);
  };
  
 /**
  * The closeModal function sets the state variable isModalOpen to false.
  */
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  /**
   * The function `confirmDelete` deletes a stack in the current environment and closes a modal.
   */
  const confirmDelete = () => {
    deleteWorkflow(currentWorkflow);
    closeModal();
  };
  const viewEvents = (flow: any) => {
    if (flow.events) {
      flow.events.map((event: any) => {
        if (event.type === 'T-101' || event.type === 101) {
          event.icon = <VariableIcon className="rounded-full h-8 w-8 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'T-111') {
          event.icon = <VariableIcon className="rounded-full h-8 w-8 bg-gray-300 dark:bg-orange-500 text-white" aria-hidden="true" />
        }
        if (event.type === 'T-102' || event.type === 102) {
          event.icon = <SparklesIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'L-201' || event.type === 201) {
          event.icon = <CloudArrowUpIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'L-204' || event.type === 204) {
          event.icon = <CloudArrowUpIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'L-202' || event.type === 202) {
          event.icon = <CloudIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'L-221' || event.type === 221) {
          event.icon = <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'L-222' || event.type === 222) {
          event.icon = <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />
        }
        if (event.type === 'L-223' || event.type === 223) {
          event.icon = <ExclamationTriangleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-pink-300 text-pink" aria-hidden="true" />
        }
        if (event.type === 'C-001') {
          event.icon = <CheckCircleIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
        if (event.type === 'U-001') {
          event.icon = <CodeBracketIcon className="rounded-full h-8 w-8 p-1 bg-gray-300 dark:bg-cyan-300 text-white" aria-hidden="true" />
        }
      });
      setSelectedFlow(flow);
    }
    
  }
  if (!props.selectedWorkspace.PK) {
    return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body text-center">
                <h1 className="display-4">No Workspace Selected</h1>
                <p className="lead">Please select a workspace from the dropdown above.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  } 
  if (selectedWorkspace && selectedEnvironment) {
  return (
    <div>
      { !selectedFlow.SK && (<>
        <header className="dark:bg-gray-800 bg-cyan-400 shadow-sm pr-4 min-w-0 md:flex md:items-center">
          <PageHeading title="Workflows" />
            <div className="flex w-full justify-end">
              {(selectedWorkspace.environments.length > 0) &&
                <EnvironmentDropdown
                  user={props.user}
                  account={props.account}
                  admin={props.admin}
                  setShowEnvironmentDropdown={props.setShowEnvironmentDropdown}
                  environments={props.environments}
                  selectedWorkspace={selectedWorkspace}
                  updateBillingCustomer={props.updateBillingCustomer}
                  selectedEnvironment={selectedEnvironment}
                  selectedAccount={props.selectedAccount}
                  setSelectedEnvironment={updateEnvironment}
                  logout={props.logout}
                />
              }
              <button
                type="button"
                onClick={() => { props.showTemplates() }} 
                className="col-span-1 border-2 border-cyan-300 hover:bg-cyan-300 hover:dark:bg-cyan-300 rounded dark:text-gray-200 text-gray-100 whitespace-nowrap items-center px-2 mx-1 text-xs font-medium hover:text-white dark:bg-gray-700 focus:outline-none">
                Start From Template
              </button> 
              <ActionButton title="Create New" icon={SquaresPlusIcon} clickHandler={() => {props.newWorkflowClickHandler()}} />
          </div>
        </header>
          {showAccessAlert && ( <MaximumNumberWorkflowsAlert closeProdRequiredAlert={closeProdRequiredAlert} /> )}
          <ConfirmModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onConfirm={() => confirmDelete()}
            title="Confirm Delete Workflow"
            message={`Are you sure you want to delete ${currentWorkflow?.flowName}?`}
          />
          { loading &&
            <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48 h-screen">
              <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
            <h2 className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200"
            style={{ fontFamily: '"Gugi", sans-serif' }}>
                Retrohook
                <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1"></span>
              </h2>
            </div>
          }
          { !loading &&
            <div className="mt-5 px-4 grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            { workflows.length < 1 && 
              <div className="text-2xl sm:col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-3 2xl:col-span text-center text-gray-300">
                  <p>You don't have any workflows in this workspace environment.</p>
              </div>  
            }
            { workflows?.map((flow: any) => {
              return (
                <div key={flow.SK} className="relative dark:text-gray-200 text-gray-600 dark:bg-gray-800 pt-3 px-3 pb-4 sm:pt-3 sm:px-3 rounded overflow-hidden hover:border-cyan-300 border-2 border-gray-400" >
                  <div className="mb-4">
                    <dt>
                      <div className="absolute pt-0.5">
                        <div className="h-8 w-8 text-cyan-400 rounded" >
                          <IconPickerItem icon={flow.icon || 'BoltIcon'} library="outline" />
                        </div>
                      </div>
                      <div className="cursor-pointer pl-7 mr-5">
                        <p onClick={() => { editFlow(flow); }} className="text-lg font-semibold text-gray-500 dark:text-gray-200 truncate pr-2"> {flow.flowName} </p>
                      </div>
                      <button className="absolute top-0 right-0 m-2 border-gray-500 border-2 hover:bg-pink-500 hover:text-white hover:border-pink-500 text-gray-500 font-bold text-xs py-0.5 px-2 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => { openModal(flow); }}>X</button>
                    </dt>
                    
                    <p className="text-md ml-0 pl-0 w-full pr-1 text-cyan-400 dark:text-gray-200 w-full truncate">
                      {flow.trigger.type.toUpperCase()} →&nbsp;
                      {flow.next.type}
                    </p>
                    <p className="text-xs ml-0 pl-0 mb-4 pb-2 mt-1 w-3/4 pr-1 dark:text-gray-200 w-full truncate">
                      
                      { Object.entries(flow.trigger.custom).map(([key, value], i) => {
                        return (
                          <span key={key}>
                            <span className="px-0.5">{key}</span>
                            { i < Object.keys(flow.trigger.custom).length - 1 ? " + " : "" }
                          </span>
                        );
                      })
                      }
                    </p>
                  </div>
                  <div className="w-full bottom-0 inset-x-0 p-2 text-center">
                    <div
                      className="text-sm dark:text-gray-200 text-center w-full cursor-pointer"
                      onClick={() => { viewEvents(flow); }}
                    >
                      
                      <div className="absolute right-2 bottom-2 w-fit border-2 border-cyan-400 rounded px-2 pl-2 flex items-baseline text-center">
                      <p className="text-xl font-semibold text-green-400">
                        {flow.successes}
                      </p>
                      <p className="ml-0 flex text-md pr-2 border-r-gray-500 border-r-2">
                        <ArrowUpCircleIcon
                          className="self-center flex-shrink-0 h-5 w-5 pt-1 text-green-400"
                          aria-hidden="true"
                        />
                      </p>
                      <p className="text-xl ml-0 font-semibold text-red-300 pl-2">
                        <span className="text-xl font-semibold text-red-500">
                          {" "}
                          {flow.failures}
                        </span>
                      </p>
                      <p className="ml-0 flex text-sm align-base">
                        <ExclamationCircleIcon
                          className="self-center pt-1 flex-shrink-0 h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          }
        </>
      )}
      { selectedFlow.SK && (
      <div>
        <header className="dark:bg-gray-900 bg-cyan-400 shadow-sm pr-4 min-w-0 md:flex md:items-center">
          <PageHeading className="inline" title={`${selectedFlow.flowName} Event History`} />
          <div className="flex min-w-2 grow-1">
            <div className="inline">
              <button type="button" onClick={() => { setSelectedFlow({events: [], flowName: '', SK: '', environment: ''}); }}
                className="items-center px-4 py-2 border-2 border-cyan-300 dark:border-gray-600 rounded text-sm font-medium text-cyan-200 dark:bg-gray-900 hover:bg-cyan-500 hover:text-white dark:hover:bg-cyan-500"
              >
                Back
              </button>
            </div>
          </div>
        </header>
        <ul className="overflow:scroll my-6 pb-4 sm:px-2 md:mx-24 mx-24 px-12 bg-transparent dark:bg-gray-800 border-2 dark:border-gray-600 border-gray-300 rounded">
          {!selectedFlow.events && 
          <h2 className="text-center text-gray-600 dark:text-gray-200 text-xl py-24">Events will start appearing when messages are processed by this workflow.</h2>
          }
          { selectedFlow?.events.sort((a: any, b: any) => (a.timestamp < b.timestamp ? 1 : -1)).map((event: any) => (
            <li key={event.id}>
              <div className="relative py-4 mx-4">
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300 text-white dark:bg-gray-600" aria-hidden="true" />
                <div className="relative flex space-x-2">
                  <div>
                    <span className="h-8 w-8 rounded-full flex items-center justify-center text-white ring-4 ring-gray-300 dark:ring-gray-600">
                      {event.icon}
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-1">
                    <div>
                      <p className="text-sm pl-2 text-gray-600 dark:text-gray-300">
                        {event.message}{" "}
                      </p>
                    </div>
                    <div className="text-sm whitespace-nowrap text-gray-500 dark:text-gray-500">
                      <time dateTime={event.timestamp}>
                        {moment(event.timestamp).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
    );
  }
}
