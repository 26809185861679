/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/**
 * The above code is a JavaScript module that provides authentication functionality using Amazon
 * Cognito User Pool in a React application.
 * @param initialState - The `initialState` parameter is the initial state of the user. It is used to
 * set the initial value of the `user` state in the `useProvideAuth` hook.
 * @returns The code is returning a custom hook called `useProvideAuth` and a component called
 * `ProvideAuth`. The `useProvideAuth` hook provides authentication functionality such as login,
 * registration, verification, password reset, and logout. The `ProvideAuth` component wraps the
 * application and provides the authentication context to its children.
 */
import React, { useState, useEffect, useContext, createContext, useCallback } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import axios from 'axios';
import config from '../../config';

const userPoolId = process.env.USER_POOL_ID;
const clientId = process.env.USER_POOL_CLIENT_ID;
const userPool = new CognitoUserPool({ UserPoolId: userPoolId, ClientId: clientId });
const authContext = createContext();

const localAuth = () => {
  const user = localStorage.getItem('user');
  return user || null;
};

const fetchUserData = () => {
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((resolve, reject) => {
    if (cognitoUser) {
      cognitoUser.getSession((err) => {
        if (err) reject(err);
        cognitoUser.getUserData(
          (attrErr, userData) => {
            if (attrErr) reject(attrErr);
            resolve(userData);
          },
          { bypassCache: true }
        );
      });
    } else {
      reject(new Error('No Cognito user found.'));
    }
  });
};

const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token.jwtToken || token}`;
};

const fetchAccount = async (token, id) => {
  setAuthHeader(token);
  try {
    const res = await axios.post(`${config.url}/account/get-account/${id}`);
    return res.data.account || null;
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

const createAccount = async (token, id, accountParams) => {
  setAuthHeader(token);
  try {
    const res = await axios.post(`${config.url}/account/register-account/${id}`, { accountParams });
    return res.data || null;
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

const createAWSAccount = async (token, id, accountParams) => {
  setAuthHeader(token);
  try {
    const res = await axios.post(`${config.url}/account/register-aws-account/${id}`, { accountParams });
    return res.data || null;
  } catch (err) {
    console.error(err);
    throw new Error(err.message);
  }
};

const constructUser = (session) => {
  const { sub } = session.getIdToken().decodePayload();
  return {
    admin: session.getAccessToken().decodePayload()['cognito:groups']?.includes('Admin') || false,
    email: session.getIdToken().decodePayload().email,
    username: session.getAccessToken().decodePayload().username,
    firstName: session.getAccessToken().decodePayload().given_name,
    lastName: session.getAccessToken().decodePayload().family_name,
    tokens: {
      IdToken: session.getIdToken().getJwtToken(),
      AccessToken: session.getAccessToken().getJwtToken(),
      RefreshToken: session.getRefreshToken().getToken(),
    },
    attributes: { id: sub },
  };
};

export function ProvideAuth({ children }) {
  const user = localAuth();
  const auth = useProvideAuth(user);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth(initialState) {
  const [user, setUser] = useState(initialState);

  const setUserCallback = useCallback((user) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  }, []);

  useEffect(() => {
    let isComponentMounted = true;
    const localAuth = async () => {
      try {
        const user = await getCurrentUser();
        if (!isComponentMounted) return;
        user.account = await fetchAccount(user.tokens.AccessToken, user.attributes.id);
        setUserCallback(user);
      } catch (error) {
        if (!isComponentMounted) return;
        setUserCallback(null);
      }
    };
    localAuth();
    return () => {
      isComponentMounted = false;
      setUserCallback(null);
    };
  }, [setUserCallback]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        const user = userPool.getCurrentUser();
        if (user) {
          user.getSession((err, session) => {
            if (err) {
              console.error('Session error:', err);
              logout();
            } else if (session.isValid()) {
              console.log('Session is still valid');
            } else {
              refreshToken().catch(logout);
            }
          });
        }
      },
      15 * 60 * 1000
    ); // check every 15 minutes
    return () => clearInterval(interval);
  }, []);

  const login = async (username, password) => {
    const authDetails = new AuthenticationDetails({ Username: username, Password: password });
    const user = new CognitoUser({ Username: username, Pool: userPool });

    try {
      const session = await authenticateUser(user, authDetails);
      if (session.FORCE_PASSWORD_CHANGE) {
        return session;
      }
      const userAccount = await processUserAccount(session);
      setUserCallback(userAccount);
      return true;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const authenticateUser = (user, authDetails) => {
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (session) => resolve(session),
        onFailure: (err) => reject(err),
        newPasswordRequired: (userAttributes) => {
          userAttributes.FORCE_PASSWORD_CHANGE = true;
          resolve(userAttributes);
        },
      });
    });
  };

  const createAccountHandler = async (session, payload) => {
    const accountParams = {
      email: payload.email,
      firstName: payload.given_name,
      lastName: payload.family_name,
      organization: payload.nickname,
      ...(payload['custom:rawToken'] && {
        type: 'aws',
        customerIdentifier: payload['custom:customerIdentifier'],
        productCode: payload['custom:productCode'],
        quantityPurchased: payload['custom:quantityPurchased'],
        productExpiration: payload['custom:productExpiration'],
        rawToken: payload['custom:rawToken'],
      }),
    };
    const createAccountFn = accountParams.type === 'aws' ? createAWSAccount : createAccount;
    return await createAccountFn(session.getAccessToken(), payload.sub, accountParams);
  };

  const processUserAccount = async (session) => {
    try {
      const payload = await session.getIdToken().decodePayload();
      let accountResponse = await fetchAccount(session.getAccessToken().jwtToken, payload.sub);
      if (!accountResponse) {
        accountResponse = await createAccountHandler(session, payload);
      }
      const user = constructUser(session);
      user.account = accountResponse;
      return user;
    } catch (error) {
      console.error('Error in processUserAccount:', error);
      throw error;
    }
  };

  const newPasswordChange = ({ username, password, newPassword }) => {
    const authDetails = new AuthenticationDetails({ Username: username, Password: password });
    const user = new CognitoUser({ Username: username, Pool: userPool });
    return new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: async (session) => {
          const user = await constructUserFromSession(session);
          setUserCallback(user);
          resolve(true);
        },
        onFailure: (err) => reject(err),
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email;
          Object.keys(userAttributes).forEach((attributeKey) => {
            if (attributeKey.startsWith('custom:') || attributeKey.startsWith('phone')) {
              delete userAttributes[attributeKey];
            }
          });
          user.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: async (session) => {
              const user = await constructUserFromSession(session);
              setUserCallback(user);
              resolve(true);
            },
            onFailure: (err) => reject(err),
          });
        },
      });
    });
  };

  const forgotPassword = async (email) => {
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => resolve(data),
        onFailure: (err) => reject(err),
      });
    });
  };

  const confirmPasswordReset = async (email, verificationCode, newPassword) => {
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => resolve(true),
        onFailure: (err) => reject(err),
      });
    });
  };

  const logout = async () => {
    const user = userPool.getCurrentUser();
    if (user) user.signOut({ global: true });
    localStorage.clear();
    setUserCallback(null);
  };

  const register = async (email, organization, firstName, lastName, password, confirm) => {
    if (!organization) {
      organization = email.split('@')[1].split('.')[0];
    }
    const username = email;
    const attributes = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'nickname', Value: organization }),
    ];

    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, attributes, null, (err, result) => {
        if (err) {
          console.error(err);
          reject(err);
        }
        resolve(result);
      });
    });
  };

  const registerAWS = async (
    email,
    organization,
    firstName,
    lastName,
    password,
    confirm,
    customerIdentifier,
    productCode,
    quantityPurchased,
    productExpiration,
    rawToken
  ) => {
    if (!organization) {
      organization = email.split('@')[1].split('.')[0];
    }
    const username = email;
    const attributes = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
      new CognitoUserAttribute({ Name: 'nickname', Value: organization }),
      new CognitoUserAttribute({ Name: 'custom:rawToken', Value: rawToken }),
      new CognitoUserAttribute({ Name: 'custom:customerIdentifier', Value: customerIdentifier }),
      new CognitoUserAttribute({ Name: 'custom:productCode', Value: productCode }),
      new CognitoUserAttribute({ Name: 'custom:quantityPurchased', Value: quantityPurchased }),
      new CognitoUserAttribute({ Name: 'custom:productExpiration', Value: productExpiration }),
    ];

    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, attributes, null, (err, result) => {
        if (err) {
          console.error(err);
          reject(err);
        }
        resolve(result);
      });
    });
  };

  const verify = async (email, code) => {
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
        }
        resolve(result);
      });
    });
  };

  const sendCode = async (email) => {
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
          reject(err);
        }
        resolve(result);
      });
    });
  };

  const refreshToken = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      console.log('No Cognito user available.');
      return false;
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          console.error('Session expired. Refreshing token...');
          cognitoUser.refreshSession(session.getRefreshToken(), (refreshErr, newSession) => {
            if (refreshErr) {
              console.error('Cannot refresh session, logging out:', refreshErr);
              logout();
              reject(refreshErr);
            } else {
              const updatedUser = constructUser(newSession);
              setUserCallback(updatedUser);
              resolve(updatedUser);
            }
          });
        } else {
          resolve(constructUser(session));
        }
      });
    });
  };

  const getCurrentUser = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      logout();
      throw new Error('No Cognito user found');
    }
    try {
      const session = await getSession(cognitoUser);
      const user = await constructUserFromSession(session);
      return user;
    } catch (error) {
      console.error('Error in getCurrentUser:', error);
      logout();
      throw error;
    }
  };

  const getSession = (cognitoUser) => {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else if (session) {
          resolve(session);
        } else {
          reject(new Error('No session'));
        }
      });
    });
  };

  const constructUserFromSession = async (session) => {
    const userData = await fetchUserData();
    const user = constructUser(session);
    const account = await fetchAccount(session.getAccessToken().getJwtToken(), session.getIdToken().decodePayload().sub);
    if (!account) {
      throw new Error('No account associated with the user');
    }
    user.account = account;
    return user;
  };

  return {
    user,
    login,
    register,
    registerAWS,
    verify,
    sendCode,
    getCurrentUser,
    newPasswordChange,
    forgotPassword,
    confirmPasswordReset,
    logout,
    refreshToken,
  };
}
