import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';


const generateTimeOptions = () => {
  let options = [];
  for (let i = 1; i <= 60; i++) { // Minutes
    options.push({ id: i, label: `${i} minute${i > 1 ? 's' : ''}`, value: i });
  }
  for (let i = 2; i <= 24; i++) { // Hours
    options.push({ id: i + 60, label: `${i} hour${i > 1 ? 's' : ''}`, value: i * 60 });
  }
  return options;
};

const classNames = (...classes: string[]): string => classes.filter(Boolean).join(' ');

const TimeDropdown: React.FC = (props) => {
  const [selected, setSelected] = useState(props.selected);
  const timeOptions = generateTimeOptions();

  if (!selected) {
    setSelected(timeOptions[0]);
  }

  return (
    <div className="relative border border-gray-500 rounded px-3 py-2 shadow-sm focus-within:ring-1 
    focus-within:ring-cyan-400 focus-within:border-cyan-400">
      <label htmlFor='Key' className="z-5 absolute -top-2 left-2 -mt-px inline-block px-1 bg-gray-700 rounded-md text-xs font-medium text-gray-100">Cadence</label>
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <div className="mr-3 whitespace-nowrap relative z-50">
          <Listbox.Button className="bg-gray-800 text-gray-100 align-middle relative w-full border border-gray-600 rounded shadow-sm pl-3 pr-12 py-2 text-left text-sm cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300">
            <span className="block truncate">{selected?.label}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="align-middle w-full bg-gray-800 text-gray-200 absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {timeOptions.map((option) => (
                <Listbox.Option
                  className={({ active }) => classNames(active ? 'text-white bg-cyan-600' : 'text-gray-200', 'cursor-default select-none relative py-2 pl-3 pr-9')}
                  key={option.id}
                  value={option}
                  onClick={() => props.selectHandler(option)}
                >
                  {({ selected, active }) => (
                    <div>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option.label}</span>
                      {selected && (
                        <span className={classNames(active ? 'text-white' : 'text-cyan-400', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
      </Listbox>
    </div>
  );
};

export default TimeDropdown;
