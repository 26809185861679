
export default function CsvReader(props: any) {
  return (
    <form id='csv-form'>
      <input 
        className="inline-flex w-full items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
        type='file' 
        accept='.csv' 
        id='csvFile' 
        onChange={(e: any) => { props.setCsvFile(e.target.files[0])}} 
      />
      <br/>
    </form>
  );
}
