export default function URLInputBox(props: any) {
  return (
    <div className="flex rounded-md shadow-sm relative">
      {props.leftInput &&
        <span
          className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-cyan-300 dark:bg-gray-800 dark:text-gray-200 sm:text-sm"
        >
          {props.leftInput}
        </span>
      }
      <label
        htmlFor="url"
        className="absolute -top-4 left-2 -mt-px inline-block rounded px-1 bg-gray-100 dark:bg-gray-800 text-xs dark:text-gray-200"
      >
        {props.url}
      </label>
      {props.leftInput &&
        <input
          type="text"
          name="url"
          onChange={props.onChange}
          value={props.value}
          className="flex-1 bg-transparent dark:bg-gray-800 block focus:ring-cyan-500 focus:border-cyan-500 min-w-0 rounded-none rounded-r-md sm:text-sm dark:text-cyan-400 text-gray-600 border-cyan-300"
        />
      }
      {!props.leftInput &&
        <input
          type="text"
          name="url"
          onChange={props.onChange}
          value={props.value}
          className="flex-1 bg-transparent dark:bg-gray-800 block w-full focus:ring-cyan-500 focus:border-cyan-500 min-w-0 rounded-md sm:text-sm dark:text-cyan-400 text-gray-600 border-cyan-300"
        />
      }
    </div>
  );   
}
