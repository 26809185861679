import React from 'react';
import useTunnels from '../../hooks/useTunnels';
import Toggle from './Elements/TunnelTypeToggle';
import Dropdown from './Elements/TunnelConfigDropdown';

interface Tunnel {
  vpnConnectionId: string;
  SK: string;
  Routes: { DestinationCidrBlock: string }[];
  tunnel1_ip: string;
  tunnel2_ip: string;
  Options: { RemoteIpv4NetworkCidr: string };
  tunnel1_psk: string;
  tunnel2_psk: string;
  showDownloadTunnelConfig?: boolean;
}

interface Props {
  user: any;
  selectedEnvironment: any;
  tunnel: Tunnel;
  showDownloadTunnelConfigClickHandler: (tunnel: Tunnel) => void;
}

const TunnelConnect: React.FC<Props> = (props: Props) => {
  const {
    devices,
    device,
    showAdvancedTunnelConfig,
    setShowadvancedTunnelConfig,
    selectHandler,
    downloadML7Config,
    downloadTunnelConfig,
  } = useTunnels(props);

  return (
    <div className="divide-y divide-solid items-center text-center">
      <div className="py-2 text-center text-md font-medium text-gray-600 dark:text-gray-200 inline">
        Quick&nbsp;&nbsp;
        <Toggle enabled={showAdvancedTunnelConfig} name={showAdvancedTunnelConfig} setEnabled={() => setShowadvancedTunnelConfig(!showAdvancedTunnelConfig)} />
        &nbsp;&nbsp;Advanced
      </div>
      {props.tunnel.showDownloadTunnelConfig ? 
        <button
          className="ml-2 border-2 bg-gray-500 hover:bg-cyan-400 text-white text-xs py-0.5 px-1 absolute right-2 top-2 rounded" 
          type="button"
          onClick={() => { props.showDownloadTunnelConfigClickHandler(props.tunnel) }}>
          Done
        </button>
      : null }
      {showAdvancedTunnelConfig ?
        <div className="pb-2 pt-4 text-xs">
          <p className='text-xs pt-1 text-center text-gray-600 dark:text-gray-200'>Device specific connection support.</p>
          <Dropdown className="w-full" selectHandler={selectHandler} devices={devices}/>
          <button onClick={() => {downloadTunnelConfig(props.tunnel)}} className="mt-2 w-full inline bg-cyan-400 hover:bg-cyan-300 text-gray-600 dark:text-gray-200 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
            Download
          </button>
          {props.tunnel.showDownloadTunnelConfig ?
            <button className="mt-2 w-full bg-gray-500 hover:bg-gray-400 text-gray-600 dark:text-gray-200 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
              type="button" 
              onClick={() => { props.showDownloadTunnelConfigClickHandler(props.tunnel) }}
            >
              Done
            </button> : null }
        </div> 
      : 
      <div className="p-2 text-gray-600 dark:text-gray-200">
        <p className='text-xs pt-1 text-center'>Policy-based, subnet connection.</p>
        <p className='text-md pt-4 text-gray-600 dark:text-gray-200 inline'>1:&nbsp; 
        <span className='text-sm pt-1 text-cyan-400 inline'><button onClick={() => {downloadML7Config(props.tunnel)}} 
          className="mt-2 text-xs inline border-2 border-gray-500 hover:bg-cyan-400 text-gray-600 dark:text-gray-200 px-1
          rounded focus:outline-none focus:shadow-outline" type="button">Download</button> the connection tool.&nbsp;</span>
        </p>
        <p className='text-md pt-1 text-gray-600 dark:text-gray-200'>2:&nbsp; 
        <span className='text-xs pt-1 text-gray-600 dark:text-gray-200'>Run setup<pre className="inline bg-gray-500/80 w-fit px-1 ml-2 py-0.5 rounded-sm">sudo ./quicksetup.sh</pre></span>
        </p>
      </div>
      }
    </div>
  );
};

export default TunnelConnect;
