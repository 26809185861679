import { IconPickerItem, iconList } from '.';
import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
const IconPicker = ({ value, library, onChange, hideSearch, }) => {
    const ref = useRef(null);
    const [display, changeDisplay] = useState(false);
    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        function handleClickOutside(event) {
            // @ts-ignore
            if (ref.current && !ref.current.contains(event.target)) {
                changeDisplay(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
    const buttonClick = () => changeDisplay(!display);
    const onChangeSearch = (event) => {
        setSearchString(event.target.value);
    };
    return (React.createElement("div", { className: "relative min-h-[40px] rounded-md border-1 border-black justify-center items-center hover:cursor-pointer", ref: ref, onClick: () => buttonClick() },
        React.createElement(IconPickerItem, { icon: value, library: library }),
        display && (React.createElement("div", { className: "absolute top-12 grid grid-cols-8 overflow-y-scroll bg-white p-2 max-h-96 w-32 rounded-md border-2 border-black z-10", onClick: (e) => e.stopPropagation() },
            !hideSearch && (React.createElement("input", { className: "w-full col-span-full", onChange: onChangeSearch, value: searchString, placeholder: "Search" })),
            iconList
                .filter((i) => i.toLowerCase().includes(searchString.toLowerCase()))
                .map((icon) => (React.createElement(IconPickerItem, { key: icon, library: library, icon: icon, onClick: (value) => {
                    onChange(value);
                    changeDisplay(false);
                    setSearchString('');
                } })))))));
};
export { IconPicker };
