
export default function InnerNavButton(props: any) {
  let Icon = null;
  if (props.icon) {  Icon = props.icon; }
  return (<div className="mx-1">
    <button type="button" onClick={() => props.clickHandler()} 
    className="inline text-gray-100 whitespace-nowrap w-full items-center px-2 py-1 hover:border-b-2 text-xs font-medium hover:text-cyan-100 hover:border-b-pink-300 focus:outline-none">
    {
      // props.icon && <Icon name={props.icon} className="pr-1 h-6 w-6 inline" aria-hidden="true" /> 
    }
    {props.title}
    </button>
  </div>);
}
