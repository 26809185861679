import React from 'react';
import useFetch from 'use-http';

interface TunnelForm {
  PK: string;
  deviceName: string;
  bgp: boolean;
  deviceNamePlaceholder: string;
  type: string;
  LocalIpv4AddressPlaceholder: string;
  LocalIpv4Address: string;
  PublicIpv4Address: string;
  PublicIpv4AddressPlaceholder: string;
  RemoteIpv4Address: string;
  ikeVersion: string;
  owner: any;
}

interface Device {
  VpnConnectionDeviceTypeId: string;
  VpnConnectionDeviceTypeName: string;
}

interface Tunnel {
  vpnConnectionId: string;
  name: string;
  showDownloadTunnelConfig?: boolean;
  showPsk?: boolean;
  Tags: { Value: string }[];
  state: string;
  customer_ip: string;
  Routes?: { DestinationCidrBlock: string }[];
  tunnel1_ip: string;
  tunnel2_ip: string;
  Options?: { RemoteIpv4NetworkCidr: string };
  ike_auth_protocol: string;
  ike_encryption_protocol: string;
  ike_diffie_helm_group: string;
  tunnel1_psk?: string;
  ipsec_auth_protocol: string;
  tunnel2_psk?: string;
}

interface Props {
  user: any;
  selectedWorkspace: any;
  selectedEnvironment: any;
  selectedAccount: any;
  admin: boolean;
  notify: (notification: { message: string }) => void;
  setSelectedEnvironment: () => void;
  updateBillingCustomer: () => void;
  logout: () => void;
  account: any;
  environments: any[];
}

const useTunnels = (props: Props) => {
  const { post, response, loading } = useFetch();
  const form: TunnelForm = {
    PK: props.user.id,
    deviceName: '',
    bgp: false,
    deviceNamePlaceholder: String.raw`Your MLLP HL7 Server`,
    type: String.raw`ipsec.1`,
    LocalIpv4AddressPlaceholder: String.raw`Your Server's Private IPv4 Address`,
    LocalIpv4Address: '',
    PublicIpv4Address: '',
    PublicIpv4AddressPlaceholder: String.raw`Your Server's Public IPv4 Address`,
    RemoteIpv4Address: '172.31.0.0',
    ikeVersion: 'ikev2',
    owner: props.user,
  };

  const [ikeToggle, setIkeToggle] = React.useState('');
  const [showEnvironmentDropdown, setShowEnvironmentDropdown] = React.useState(false);
  const [tunnels, setTunnels] = React.useState<Tunnel[]>([]);
  const [updatedConnectClickHandler, setUpdatedConnectClickHandler] = React.useState(false);
  const [advanced, setAdvanced] = React.useState(false);
  const [showAccessAlert, setShowAccessAlert] = React.useState(false);
  const [tunnel, setTunnel] = React.useState(form);
  const [missingFieldsAlert, setMissingFieldsAlert] = React.useState(false);
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [currentTunnel, setCurrentTunnel] = React.useState<Tunnel>({ name: 'New Tunnel', vpnConnectionId: '' });
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [devices, setDevices] = React.useState<Device[]>([]);
  const [device, setDevice] = React.useState<Device>({
    VpnConnectionDeviceTypeId: '',
    VpnConnectionDeviceTypeName: '',
  });
  const [showAdvancedTunnelConfig, setShowadvancedTunnelConfig] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!devices.length) getDeviceList();
  }, [props.selectedEnvironment]);

  const getDeviceList = async () => {
    const body = { environment: props.selectedEnvironment };
    const devices = await post(`ipsec/devices/${props.user.attributes.id}`, body);
    if (response.ok && devices) {
      setDevices(devices);
    }
  };

  const selectHandler = (device: Device) => {
    setDevice(device);
  };

  const downloadML7Config = async (tunnel: Tunnel) => {
    const body = {
      left: tunnel.SK.split('#')[1],
      leftSubnet: tunnel.Routes[0].DestinationCidrBlock,
      leftIp: tunnel.SK.split('#')[1],
      right: tunnel.tunnel1_ip,
      rightSubnet: tunnel.Options.RemoteIpv4NetworkCidr,
      rightSecondary: tunnel.tunnel2_ip,
      rightSubnetSecondary: tunnel.Options.RemoteIpv4NetworkCidr,
      psk: tunnel.tunnel1_psk,
      pskSecondary: tunnel.tunnel2_psk,
      selected: props.selectedEnvironment,
      leftSubnetSecondary: tunnel?.Routes[1]?.DestinationCidrBlock ? tunnel.Routes[1].DestinationCidrBlock : '',
    };
    const config = await post(`ipsec/generate-custom-config/${props.user.attributes.id}`, body);
    if (response.ok && config) {
      const element = document.createElement('a');
      const file = new Blob([config.customConfig], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'quick-connect.sh';
      document.body.appendChild(element);
      element.click();
    }
  };

  const downloadTunnelConfig = async (tunnel: Tunnel) => {
    const body = {
      VpnConnectionDeviceTypeId: device.VpnConnectionDeviceTypeId,
      VpnConnectionId: tunnel.vpnConnectionId,
      ikeVersion: 'IKEv2',
      environment: props.selectedEnvironment,
    };
    const downloadRequest = await post(`ipsec/get-config/${props.user.attributes.id}`, body);
    if (downloadRequest?.VpnConnectionDeviceSampleConfiguration) {
      const element = document.createElement('a');
      const file = new Blob([downloadRequest.VpnConnectionDeviceSampleConfiguration], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'sample-config.txt';
      document.body.appendChild(element);
      element.click();
    }
  };

  const getTunnels = async () => {
    setShowAccessAlert(false);
    if (props?.selectedEnvironment?.name?.substring(3, 6) === 'Dev') {
      setShowAccessAlert(true);
    }
    const body = { selectedWorkspace: props.selectedWorkspace, selectedEnvironment: props.selectedEnvironment };
    const tunnelz: any = await post(`ipsec/list/${props.user.attributes.id}`, body);
    if (response.ok && tunnelz.length > 0) {
      tunnelz.forEach((element: any) => {
        if (!element.showDownloadTunnelConfig || element.showDownloadTunnelConfig === 'undefined') {
          element.showDownloadTunnelConfig = false;
          element.showPsk = false;
        }
      });
      setTunnels(tunnelz || []);
    } else {
      setTunnels([]);
    }
  };

  React.useEffect(() => {
    getTunnels();

    return () => {
      setTunnels([]);
    };
  }, [props.selectedEnvironment, props.selectedAccount]);

  const canCreate = () => {
    const hasUpdateWorkflowInSelectedWorkspace = props.selectedWorkspace.accounts.filter((workspace: any) => {
      return workspace.permissions?.tunnels.create;
    });
    const isOwner = props.selectedWorkspace?.PK === props.account?.PK;
    return props.admin === true || hasUpdateWorkflowInSelectedWorkspace.length > 0 || isOwner;
  };

  const canDelete = () => {
    const can = props.selectedWorkspace.accounts.filter((workspace: any) => {
      return workspace.permissions?.tunnels.delete;
    });
    const isOwner = props.selectedWorkspace?.owner?.email === props.account?.email;
    return props.admin === true || can.length > 0 || isOwner;
  };

  const saveTunnel = async () => {
    if (props?.selectedEnvironment?.name.substring(2, 6) === 'Dev') {
      setShowAccessAlert(true);
    }
    if (!tunnel.deviceName || !tunnel.PublicIpv4Address || !tunnel.LocalIpv4Address) {
      const missingFieldz = [];
      if (!tunnel.deviceName) missingFieldz.push('Device Name');
      if (!tunnel.PublicIpv4Address) missingFieldz.push('Public IP Address');
      if (!tunnel.LocalIpv4Address) missingFieldz.push('Local IP Address');
      setMissingFields(missingFieldz);
      setMissingFieldsAlert(true);
      return;
    }
    if (canCreate()) {
      const newTunnel = {
        DeviceName: tunnel.deviceName,
        StaticRoutesOnly: !tunnel.bgp,
        PublicIpv4Address: tunnel.PublicIpv4Address,
        RemoteIpv4Address: tunnel.RemoteIpv4Address,
        LocalIpv4Address: tunnel.LocalIpv4Address,
        Type: tunnel.type,
        owner: props.user,
        BgpAsn: 65000,
      };
      const body = {
        tunnel: newTunnel,
        selectedAccount: props.selectedAccount,
        selectedEnvironment: props.selectedEnvironment,
        selectedWorkspace: props.selectedWorkspace,
      };
      const tunnelResponse = await post(`ipsec/create/${props.user.attributes.id}`, body);
      props.notify({ message: 'Generating New Tunnel...' });
      if (response.ok && tunnelResponse) {
        await getTunnels();
        setTunnel(form);
      }
    }
  };

  const deleteTunnel = async (tnl: Tunnel) => {
    props.notify({ message: 'Deleting Tunnel...' });
    if (canDelete()) {
      const body = {
        selectedEnvironment: props.selectedEnvironment,
        selectedAccount: props.selectedAccount,
        tunnel: tnl,
      };
      await post(`ipsec/delete/${props.user.attributes.id}`, body);
      if (response.ok) {
        getTunnels();
      }
    } else {
      props.notify({ message: 'You do not have permission to delete this tunnel.' });
    }
  };

  const toggleIkeVersion = () => {
    setIkeToggle(tunnel.ikeVersion === 'IKEV1' ? 'IKEV2' : 'IKEV1');
  };

  const handleIPChange = (event: any) => {
    setTunnel({ ...tunnel, PublicIpv4Address: event.target.value });
  };

  const handleTunnelNameChange = (event: any) => {
    setTunnel({ ...tunnel, deviceName: event.target.value });
  };

  const handleInternalIPChange = (event: any) => {
    setTunnel({ ...tunnel, LocalIpv4Address: event.target.value });
  };

  const toggleBGP = (value: boolean) => {
    setTunnel({ ...tunnel, bgp: value });
  };

  const showDownloadTunnelConfigClickHandler = (tnl: Tunnel) => {
    let shouldReRender = false;
    tunnels.forEach((element) => {
      if (element.vpnConnectionId === tnl.vpnConnectionId) {
        if (element.showDownloadTunnelConfig === 'undefined') {
          element.showDownloadTunnelConfig = false;
        }
        if (!element.showDownloadTunnelConfig || element.showDownloadTunnelConfig === false) {
          element.showDownloadTunnelConfig = true;
          shouldReRender = true;
        } else {
          element.showDownloadTunnelConfig = false;
        }
      }
    });
    setUpdatedConnectClickHandler(shouldReRender);
    setTunnels([...tunnels]);
  };

  const openModal = (tnl: Tunnel) => {
    setCurrentTunnel(tnl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmDelete = () => {
    deleteTunnel(currentTunnel);
    closeModal();
  };

  return {
    ikeToggle,
    showEnvironmentDropdown,
    tunnels,
    updatedConnectClickHandler,
    advanced,
    showAccessAlert,
    tunnel,
    missingFieldsAlert,
    missingFields,
    currentTunnel,
    isModalOpen,
    loading,
    devices,
    device,
    showAdvancedTunnelConfig,
    setIkeToggle,
    setShowEnvironmentDropdown,
    setTunnels,
    setUpdatedConnectClickHandler,
    setAdvanced,
    setShowAccessAlert,
    setTunnel,
    setMissingFieldsAlert,
    setMissingFields,
    setCurrentTunnel,
    setIsModalOpen,
    setShowadvancedTunnelConfig,
    selectHandler,
    downloadML7Config,
    downloadTunnelConfig,
    getTunnels,
    canCreate,
    canDelete,
    saveTunnel,
    deleteTunnel,
    toggleIkeVersion,
    handleIPChange,
    handleTunnelNameChange,
    handleInternalIPChange,
    toggleBGP,
    showDownloadTunnelConfigClickHandler,
    openModal,
    closeModal,
    confirmDelete,
  };
};

export default useTunnels;
