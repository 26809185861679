/* This example requires Tailwind CSS v2.0+ */
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function MessageIdentifierInfoAlert(props: any) {
  return (
    <div className="rounded-md animate-color-change-2x mx-48 px-4 py-4 shadow border-2 border-pink-400 my-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-12 w-12 text-pink-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-xl font-medium text-pink-400">Using Wildcards</p>
          <p className="text-sm font-medium text-cyan-400">The "?" matches on one dynamic character, these can be chained. such as "???"</p>
          <p className="text-sm font-medium text-cyan-400">The "*" matches on all characters to the right of the last defined character. </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={ () => { props.closeAlert() }}
              type="button"
              className="inline-flex bg-transparent border-2 border-pink-400 rounded-md p-1.5 text-pink-500 hover:border-pink-300 hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-pink-50 focus:ring-pink-600"
            >
              <span className="sr-only">Done</span>
              <XMarkIcon className="h-5 w-5 " aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
