/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */

import { InboxArrowDownIcon, MapIcon, ShareIcon, RectangleStackIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import InnerNavButton from '../../../Base/Elements/Nav/InnerNavButton';
import InnerNavButtonSelected from '../../../Base/Elements/Nav/InnerNavButtonSelected';
import DropdownButton from './SaveDropdown';
import ActionButton from "../../../Base/Elements/Nav/ActionButton";


export default function SubNav(props: any) {
  const saveOptions = [
    // {
    //   label: props.template?.PK ? "Update Template" : "Generate Template",
    //   action: props.saveTemplate,
    // },
    {
      label: props.workflow?.PK ? 'Update Workflow' : 'Create Workflow',
      action: props.saveWorkflow,
    },
  ];
  const createOptions = [
    // {
    //   label: "Generate Template",
    //   action: props.saveTemplate,
    // },
    {
      label: 'Create Workflow',
      action: props.saveWorkflow,
    },
  ];

  return (
    <div className="dark:bg-gray-800 bg-cyan-400 shadow-sm min-w-0 flex py-1 px-4">
      <div className="w-full mt-2 flex justify-end">
        {props.ux == 'adapters' && <InnerNavButtonSelected title="Details" icon={RectangleStackIcon} clickHandler={() => props.adapterClickHandler()} />}
        {props.ux != 'adapters' && <InnerNavButton title="Details" icon={RectangleStackIcon} clickHandler={() => props.adapterClickHandler()} />}
        {props.ux == 'e-layer' && props.workflow.trigger.type.substring(0, 4) != 'HTTP' && (
          <InnerNavButtonSelected title="Extract" icon={InboxArrowDownIcon} clickHandler={() => props.eventTriggerClickHandler()} />
        )}
        {props.ux != 'e-layer' && props.workflow.trigger.type.substring(0, 4) != 'HTTP' && (
          <InnerNavButton title="Extract" icon={InboxArrowDownIcon} clickHandler={() => props.eventTriggerClickHandler()} />
        )}
        {props.ux == 't-layer' && <InnerNavButtonSelected title="Transform" icon={MapIcon} clickHandler={() => props.mapClickHandler()} />}
        {props.ux != 't-layer' && <InnerNavButton title="Transform" icon={MapIcon} clickHandler={() => props.mapClickHandler()} />}
        {props.ux == 'l-layer' && <InnerNavButtonSelected title="Load" icon={ShareIcon} clickHandler={() => props.loadClickHandler()} />}
        {props.ux != 'l-layer' && <InnerNavButton title="Load" icon={ShareIcon} clickHandler={() => props.loadClickHandler()} />}
      </div>
      <p className="border-left border-2 mx-2 border-pink-500" />
      {props.workflow?.sampleMessage && !props.workflow?.PK && <DropdownButton label="Create" options={createOptions} />}
      {props.workflow?.PK && <DropdownButton label="Save" options={saveOptions} />}
      <ActionButton title="Close" icon={ArrowLeftIcon} clickHandler={() => {props.backToListClickHandler()}} />
    </div>
  );
}
