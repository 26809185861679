import * as React from 'react';
import * as HeroIconOutline from '@heroicons/react/24/outline';
import * as HeroIconSolid from '@heroicons/react/24/solid';
import * as HeroIconMini from '@heroicons/react/20/solid';
const IconPickerItem = ({ icon, library, size = 6, onClick, }) => {
    const libraryToUse = library === 'outline'
        ? HeroIconOutline
        : library === 'solid'
            ? HeroIconSolid
            : HeroIconMini;
    const iconDiv = !!icon ? React.createElement(libraryToUse[icon]) : React.createElement("div", null);
    return (React.createElement("div", { onClick: () => !!onClick && onClick(icon), className: `w-${size} h-${size} col-span-1` }, iconDiv));
};
export { IconPickerItem };
