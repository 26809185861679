
export default function FlowHeading (props: any) {
  let Icon = null;
  if (props.icon) {  Icon = props.icon; }
  return (
    <div className="w-full mt-2 inline px-2 mx-auto">
      <a className="text-xl leading-6 font-semibold text-gray-600 dark:text-cyan-300">
      { 
        // props.icon && <Icon name={props.icon} className="pr-1 h-8 w-8 inline" aria-hidden="true" /> 
      }
      {props.title}</a>
    </div>
  );
}

