import { useState, useEffect } from 'react';
import moment from 'moment';
import useFetch from 'use-http';
import StackedBarChart from '../components/Dashboard/StackedBarChart';
import AreaChart from '../components/Dashboard/AreaChart';
import PageHeading from '../components/Base/Elements/Nav/PageHeading';
import EnvironmentDropdown from '../components/Base/Elements/Nav/EnvironmentDropdown';
import logo from '../../images/retrohook.png';

export default function Dashboard(props: any) {
  const [workflows, setWorkflows] = useState([]);
  const [shouldRender, setShouldRender] = useState(false);
  const { post, response, loading } = useFetch();
  const [chartType, setChartType] = useState('bar');

  useEffect(() => {
    if (props.selectedWorkspace?.SK && props.selectedEnvironment?.SK) {
      getWorkflows();
    }
  }, [props.selectedEnvironment, props.selectedWorkspace]);

  const getWorkflows = async () => {
    const body = { selectedWorkspace: props.selectedWorkspace, selectedEnvironment: props.selectedEnvironment };
    const workflowRequest: any = await post(`workflow/list-workflows/${props.user.attributes.id}`, body);
    if (response.ok && workflowRequest) {
      const workflows = typeof workflowRequest?.workflows !== 'undefined' ? workflowRequest.workflows : [];
      if (workflows) {
        workflows.map((workflow: any) => {
          workflow.events = workflow.events
            ? workflow.events.map((event: any) => {
                event.timestamp = moment(event.timestamp).format('MM/DD/YY');
                return event;
              })
            : [];
          workflow.eventsByDay =
            workflow.events.length > 0
              ? workflow.events.reduce(function (r: any, a: any) {
                  r[a.timestamp] = r[a.timestamp] || [];
                  r[a.timestamp].push(a);
                  return r;
                }, Object.create(null))
              : [];
          workflow.dailySuccesses = 0;
          workflow.dailyErrors = 0;
          workflow.gridSuccesses = {};
          workflow.gridSuccesses.data = [];
          workflow.gridErrors = {};
          workflow.gridErrors.data = [];
        });
        workflows.map((workflow: any) => {
          Object.entries(workflow.eventsByDay).map((flow: any) => {
            flow.filter((day: any) => {
              const successes = flow[1].filter((day: any) => day.type === 'L-202');
              const tErrors = flow[1].filter((day: any) => day.type === 'T-111');
              const errors = flow[1].filter((day: any) => day.type === 'L-222');
              const dailyErrors = errors.length + tErrors.length;
              const params = { x: flow[0], y: successes.length };
              const eerams = { x: flow[0], y: dailyErrors };
              if (successes.length) {
                workflow.gridSuccesses.data.push(params);
              }
              if (dailyErrors) {
                workflow.gridErrors.data.push(eerams);
              }
            });
          });
          const RemoveDuplicates = (array: any, key: any) => {
            return array.reduce((arr: any, item: any) => {
              const removed = arr.filter((i: any) => i[key] !== item[key]);
              return [...removed, item];
            }, []);
          };
          workflow.gridSuccesses.data = RemoveDuplicates(workflow.gridSuccesses.data, 'x');
          workflow.gridErrors.data = RemoveDuplicates(workflow.gridErrors.data, 'x');
          workflow.gridSuccesses.data.sort((a: any, b: any) => (moment(a.x).format('YYYYMMDD') > moment(b.x).format('YYYYMMDD') ? -1 : 1));
          workflow.gridErrors.data.sort((a: any, b: any) => (moment(a.x).format('YYYYMMDD') > moment(b.x).format('YYYYMMDD') ? -1 : 1));
        });
        if (workflows[0] && workflows[0].eventsByDay) {
          setShouldRender(true);
        } else {
          setShouldRender(false);
        }
        setWorkflows(workflows);
      } else {
        setWorkflows([]);
        setShouldRender(false);
      }
    } else {
      setWorkflows([]);
      setShouldRender(false);
    }
  };

  return (
    <div className="h-full max-h-full">
      <div className="dark:bg-gray-800 bg-cyan-400 pr-4 min-w-0 md:flex md:items-center">
        <PageHeading className="inline" title={`${props.selectedEnvironment?.name} Events`} />
        <div className="flex">
          {!loading && (
            <div className="space-2 p-b-2 pr-4">
              <div className="inline-flex">
                <button
                  className={` hover:text-cyan-300 text-white py-2 px-4 ${chartType === 'area' ? 'border-b-2 border-cyan-300' : ''}`}
                    onClick={() => {
                    setChartType('area');
                  }}
                >
                  Area
                </button>
                <button
                  className={` hover:text-cyan-300 text-white py-2 px-4 ${chartType === 'bar' ? 'border-b-2 border-cyan-300' : ''}`}
                  onClick={() => {
                    setChartType('bar');
                  }}
                >
                  Bar
                </button>
              </div>
            </div>
          )}
          {props?.selectedWorkspace?.environments && props?.selectedWorkspace?.environments.length > 0 && (
              <EnvironmentDropdown
                user={props.user}
                account={props.account}
                admin={props.admin}
                environments={props.environments}
                selectedWorkspace={props.selectedWorkspace}
                updateBillingCustomer={props.updateBillingCustomer}
                selectedEnvironment={props.selectedEnvironment}
                selectedAccount={props.selectedAccount}
                setSelectedEnvironment={props.setSelectedEnvironment}
                logout={props.logout}
            />
          )}
        </div>
        </div>
          <div className="my-2 mx-4 pb-2 mt-6 px-4 rounded h-4/5 text-gray-200">
            {loading && (
          <div className="justify-center items-center pt-12 sm:pt-12 md:pt-24 lg:pt-32 xl:pt-48">
            <div className="items-center animate-pulse">
              <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
              <h2 className="mt-0 text-center text-2xl font-bold text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook</h2>
              </div>
            </div>
        )}
        

        {shouldRender && chartType === 'bar' ? <StackedBarChart workflows={workflows} /> : <></>}
        {shouldRender && chartType === 'area' ? <AreaChart workflows={workflows} /> : <></>}
            {!shouldRender && !loading && (
          <div className="text-center text-gray-200">
            <div className="text-2xl sm:pt-14 md:pt-24 lg:pt-32">Hi {props.user.account.first}, Welcome to Retrohook!</div>
            <p className="text-md py-1">
                  This environment doesn't have any transactions... Ready to create your first workflow? Click the Workflows tab above. New here and unsure how to
                  build a Workflow? Check out our step-by-step guides at the top or chat with us (the little conversation in the bottom right corner).
            </p>
            </div>
        )}
      </div>
      </div>
  );
}
