/* eslint-disable react/button-has-type */
import { ComputerDesktopIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../../context/ThemeContext';

const ThemeToggler = () => {
  const { theme, toggleTheme } = useTheme();

  const handleToggle = () => {
    if (theme === 'light') {
      toggleTheme('dark');
    } else if (theme === 'dark') {
      toggleTheme('system');
    } else {
      toggleTheme('light');
    }
  };

  return (
    <div>
      <button onClick={handleToggle} className="p-2 rounded-full focus:outline-none">
        {theme === 'light' && <SunIcon className="h-6 w-6 text-yellow-500" />}
        {theme === 'dark' && <MoonIcon className="h-6 w-6 text-gray-100" />}
        {theme === 'system' && <ComputerDesktopIcon className="h-6 w-6 text-gray-500" />}
      </button>
    </div>
  );
};

export default ThemeToggler;
