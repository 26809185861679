import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import logo from '../../../images/retrohook.png';
import {useAuth} from "../../hooks/useAuth";
import {Redirect} from "react-router-dom";

const RegisterButton = (props: any) => {
  if (props.loading) {
    return (
      <button type="button" onClick={props.handleRegister} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <div className=" flex justify-center items-center">
          <div className="animate-spin rounded-full h-6 w-6 border-t-4 border-b-4 border-cyan-300" />
        </div>
      </button>
    )
  }
   else {
    return (
      <button type="button" onClick={props.handleRegister} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cyan-400 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-300">
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-cyan-300 group-hover:text-cyan-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"/>
          </svg>
        </span>
        Sign Up Free
      </button>
    );
  }
}

export default function RegisterModal(props: any) {
  const auth = useAuth();
  const [organization, setOrganization] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleRegister = async () => {
    clearErrorMessage();
    try {
      setLoading(true);
      if (password !== confirm) { setErrorMessage('Passwords do not match.'); return; }
      const registerResult = await auth.register(email, organization, firstName, lastName, password, confirm);
      console.dir(registerResult);
      if (registerResult) {
        props.setEmail(email);
        setLoading(false);
        props.closeModal();
        props.closeRegisterModal();
        props.setVerificationCodeModel();
      }
      
    } catch (e: any) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };
  const clearErrorMessage = () => {
    setErrorMessage('');
  };
  const renderFormFields = () => { 
    return (
      <div>
        <input type="hidden" name="remember" value="true"/>
        <div className="rounded-md  -space-y-px">
        { errorMessage.length > 0 && <div className="text-red-500 text-sm pb-2">{errorMessage}</div>}
          <div className="px-0 mx-0 pt-2">
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autoComplete="email" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-t-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Email Address" value={email} onChange={(e) => { setEmail(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="username" className="sr-only">Organization</label>
            <input id="username" name="username" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Organization (Optional)" value={organization} onChange={(e) => { setOrganization(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="first-name" className="sr-only">First Name</label>
            <input id="first-name" name="firstName" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="last-name" className="sr-only">Last Name</label>
            <input id="last-name" name="lastName" type="text" required onKeyDown={(e) => { if (e.key === "Enter") {handleRegister()} } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-b-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }}/>
          </div>
          <p className="text-gray-600 dark:text-gray-200 text-xs pt-4 mb-0">Passwords must be at least 8 characters,</p>
          <p className="text-gray-600 dark:text-gray-200 text-xs mb-0">contain an Uppercase, lowercase, number,</p>
          <p className="text-gray-600 dark:text-gray-200 text-xs pb-4">and special character.</p>
          <div className="px-0 mx-0">
            <label htmlFor="password" className="sr-only">Set Password</label>
            <input id="password" name="password" type="password" autoComplete="current-password" required onKeyDown={(e) => { if (e.key === "Enter") { handleRegister(); } } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-t-md
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value)}}/>
          </div>
          <div className="px-0 mx-0">
            <label htmlFor="confirm" className="sr-only">Confirm Password</label>
            <input id="confirm" name="confirm" type="password" autoComplete="current-password" required onKeyDown={(e) => { if (e.key === "Enter") { handleRegister(); } } }
              className="appearance-none rounded-none relative block w-full py-2 border border-gray-300 placeholder-gray-400 text-gray-900 rounded-b-md 
              focus:outline-none focus:ring-cyan-300 focus:border-cyan-300 focus:z-10 sm:text-sm"
              placeholder="Confirm Password" value={confirm} onChange={(e) => { setConfirm(e.target.value)}}/>
          </div>
        </div>
      </div>
    );
  }
  if (auth.user) { return <Redirect to={"/"}/> }
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed animate-in fade-in z-10 inset-0 overflow-y-auto"  onClose={ () => { props.closeModal}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <Transition.Child as={Fragment} 
            enter="ease-out duration-300" 
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enterTo="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom items-center w-auto animate-color-change-2x dark:animate-color-change-2x-dark rounded-lg text-left overflow-hidden transform transition-all sm:align-middle sm:p-2">
              <button type="button" className="absolute right-2 top-2 border-2 dark:border-gray-500 rounded px-2 py-1 text-base text-pink-400 outline-none hover:ring-1 hover:ring-pink-500" onClick={ () => { props.closeModal() }} >X</button>
              <div className="px-1 inline-flex items-center">
                <div className="animate-color-change-2x dark:animate-color-change-2x-dark px-6 pb-2">
                  <div className="items-center">
                    <img className="h-24 mx-auto w-auto" src={logo} alt="Logo"/>
                    <h2  className="mt-0 text-center text-2xl font-bold text-gray-600 dark:text-gray-200" style={{ fontFamily: '"Gugi", sans-serif' }}>Retrohook
                    </h2>
                  </div>
                  <form className="mt-2" action="#" method="POST">{renderFormFields()}</form>
                </div>
              </div>
              <div className="py-2 px-4">
            <RegisterButton handleRegister={handleRegister} loading={loading} />
            </div>
            <div className="flex mx-auto items-center my-4 justify-between w-full">
              <div className="text-xs items-center mx-auto">
                <a onClick={() => { props.setSignInModal(true); }} className="font-medium text-cyan-400 hover:text-cyan-300">
                  <span className="font-medium text-pink-600 hover:text-pink-500">
                    Already have an account?
                  </span>
                  &nbsp; 
                  <button className="bg-pink-500 border rounded py-1 px-2 text-white hover:ring-2 hover: ring-pink-400">
                    Sign In
                  </button>
                </a>
              </div>
            </div>
          </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
