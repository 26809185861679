import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export function PrivateRoute({...rest}) {
  const auth = useAuth();

  const render = (props: any) => {
    if (!auth.user) { auth.logout(); return <Redirect to={{ pathname: "/", state: { from: props.location }}} /> }
  }
  return (<Route {...rest} />);
}
