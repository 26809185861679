export default function WorkflowNameInputBox(props: any) {
  return (
    <div className="relative col-span-2 border flex border-cyan-300 rounded-md px-4 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-pink-600">
      <label htmlFor={props.name} className="absolute -top-4 left-2 -mt-px inline-block px-1 border rounded border-gray-600 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200">{props.name}</label>
      <input type="text" 
        name={props.name} 
        disabled={props.disabled} 
        id={props.name} 
        onChange={props.onChange} 
        defaultValue={props.value} 
        className="block w-full border-0 p-0 text-gray-600 dark:text-cyan-200 placeholder-gray-200 bg-transparent dark:bg-gray-800 focus:ring-0 sm:text-sm" />
    </div>
  );
}
