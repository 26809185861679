import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import useFetch from "use-http";
import config from "../../../../../config";

export default function EnvironmentDropdown(props: any) {
  const { post, response, loading } = useFetch(config.url);
  const [selectedEnvironment, setSelectedEnvironment] = useState<any>(props.selectedEnvironment);

  useEffect(() => {
    setSelectedEnvironment(props.selectedEnvironment);
  }, [props.selectedEnvironment]);

  useEffect(() => {
    if (props.updateBillingCustomer === true && !props.admin) {
      updateCanceledAccount();
    }
  }, [props.updateBillingCustomer, props.admin]);

  const updateCanceledAccount = async () => {
    const account = props.selectedAccount || props.user.account;
    const body = { selectedAccount: account };
    const request: any = await post(`delete-customer/${account.customer.id}`, body);
    if (response.ok && request) window.location.href = "/workspaces";
  };

  const getEnvironment = async (environment: any) => {
    const environmentRequest: any = await post(`environment/get/${props.user.attributes.id}`, environment);
    if (response.ok && environmentRequest) {
      return environmentRequest.environment;      
    }
  }

  const classNames = (...classes: any) => { return classes.filter(Boolean).join(' ')}
  
  const setSelected = async (selectedEnvironment: any) => {
    const fullEnvironment = await getEnvironment(selectedEnvironment);
    setSelectedEnvironment(fullEnvironment);
    props.setSelectedEnvironment(fullEnvironment);
  }
  return (
    <Listbox value={selectedEnvironment} onChange={ setSelected }>
    {({ open }) => (
      <div className="mr-1 relative z-20 border-2 border-cyan-300 dark:border-gray-600 rounded dark:bg-gray-800">
          <Listbox.Button className="text-xs text-gray-100 dark:bg-gray-700 align-middle relative w-44 rounded pl-3 pr-2 py-2 text-left 
        cursor-default focus:outline-none sm:text-xs">
          <span className="block align-middle text-xs">{props.selectedEnvironment?.name ? props.selectedEnvironment?.name?.substring(0,20): 'Select Environment'}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"><ChevronUpDownIcon className="h-5 w-5 text-gray-200" aria-hidden="true" /></span>
        </Listbox.Button>
        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="align-middle bg-gray-100 dark:bg-gray-700 absolute shadow-lg max-h-60 rounded-md py-1 dark:text-gray-200 text-base ring-1 dark:ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-xs">
            { props?.selectedWorkspace?.environments.map((environment: any) => (
              <Listbox.Option 
                key={environment.name} 
                value={environment} 
                className={({ active }) => classNames(active ? 'text-white bg-cyan-400' : 'text-gray-600', 'cursor-default select-none relative py-2 pl-3 pr-9')} 
              >
                {({ selected, active }) => (
                  <div>
                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                      {environment.name}
                    </span>
                    {selected && (
                      <span className={classNames(active ? 'text-white' : 'text-cyan-400', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                        <CheckIcon className="h-5 w-5 text-gray-100" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
  )
}
