/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { ShareIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { IconPickerItem } from 'react-heroicons-picker';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import WorkflowNameInputBox from './Elements/WorkflowNameInputBox';
import FlowHeading from './Elements/FlowHeading';
import Dropdown from './Elements/WorkflowDropDown';

const defaultEventTypes = [
  { id: 1, type: 'MLLP HL7v2', label: 'MLLP', description: 'HL7v2 Messages over MLLP', disabled: false },
  { id: 4, type: 'SFTP HL7v2', label: 'SFTP (Pro)', description: 'HL7v2 Messages over SFTP', disabled: true },
  { id: 3, type: 'HTTPS FHIR R4', label: 'FHIR R4 (Pro)', description: 'FHIR subscription listener', disabled: true },
  { id: 2, type: 'HTTPS JSON', label: 'HTTPS JSON (Pro)', description: 'Consume JSON over HTTP POST requests', disabled: true },
];
const defaultActionTypes = [
  { id: 1, type: 'HTTPS JSON', label: 'HTTPS JSON', description: 'POST JSON Over HTTPS', disabled: false },
  { id: 2, type: 'S3 JSON', label: 'S3 JSON (Pro)', description: 'Write JSON to S3', disabled: false },
  { id: 3, type: 'S3 FHIR R4', label: 'S3 FHIR R4 (Pro)', description: 'Send Bundles to a FHIR Server', disabled: false },
  { id: 4, type: 'MLLP HL7v2', label: 'MLLP HL7v2 (Pro)', description: 'Send MLLP Events To An IP & Port', disabled: false },
  { id: 5, type: 'HTTPS FHIR R4', label: 'HTTPS FHIR R4 (Pro)', description: 'Send Bundle Transactions', disabled: true },
];

export default function Adapters(props: any) {
  const [eventTypes, setEventTypes] = useState(defaultEventTypes);
  const [actionTypes, setActionTypes] = useState(defaultActionTypes);
  const [selectedEnvironment, setSelectedEnvironment] = useState(props.selectedEnvironment);

  useEffect(() => {
    setSelectedEnvironment(props.selectedEnvironment);
  }, [props.selectedEnvironment]);

  useEffect(() => {
    const updateDropdowns = () => {
      const isDevEnvironment = selectedEnvironment.name.substring(3, 6) === 'Dev';
      const updatedEventTypes = defaultEventTypes.map((eventType) => ({
        ...eventType,
        disabled: isDevEnvironment && eventType.type.startsWith('HTTP') ? true : eventType.disabled,
      }));

      const updatedActionTypes = defaultActionTypes.map((actionType) => ({
        ...actionType,
        disabled: isDevEnvironment && (actionType.type === 'MLLP HL7v2' || actionType.type.startsWith('S3')) ? true : actionType.disabled,
      }));

      setEventTypes(updatedEventTypes);
      setActionTypes(updatedActionTypes);
    };

    updateDropdowns();

    return () => {
      setEventTypes(defaultEventTypes);
      setActionTypes(defaultActionTypes);
    };
  }, [selectedEnvironment]);

  return (
    <div className="py-4">
      {props.error === 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error === 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      <div className="inline">
        <div className="px-2 md:mt-0">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="animate-color-change-2x dark:animate-color-change-2x-dark rounded h-full space-y-4 p-6 pb-4 mx-4 border-solid border shadow border-cyan-300">
              <FlowHeading icon={ShareIcon} title={`${props.workflow?.flowName} Details`} />
              <div className="flex border border-top border-cyan-400 mx-2" />
              <div className="inline-flex z-15 w-full">
                <div className="z-20">
                  <div className="border-2 rounded-md text-cyan-500 p-2 mr-1 border-gray-500 hover:border-cyan-400 cursor-pointer hover:text-cyan-300">
                    <IconPickerItem icon={props.workflow?.icon ? props.workflow?.icon : 'BoltIcon'} library="outline" />
                  </div>
                </div>
                <div className="mt-1 pr-2 w-full">
                  <WorkflowNameInputBox
                    name="Name"
                    onChange={props.handleFlowNameChange}
                    value={props.workflow?.flowName}
                    placeholder={props.placeholder.flowName}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    props.showTemplates();
                  }}
                  className="ml-2 col-span-1 text-gray-600 dark:text-gray-200 whitespace-nowrap items-center px-4 py-2 mx-1 border text-xs font-medium rounded shadow-sm dark:bg-gray-700 border-pink-400 hover:border-pink-300 hover:bg-pink-400 hover:text-white focus:outline-none"
                >
                  Templates
                </button>
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-200 text-center">
                Select the desired event type to initiate your workflow and the corresponding action you would like to take place as a result of the event.
              </p>
              <div className="flex">
                <div className="pl-3 inline w-1/2">
                  <h2 className="text-lg leading-6 font-medium text-gray-600 dark:text-gray-100">Event</h2>
                  <Dropdown selectHandler={props.updateEventTrigger} selected={props.workflow?.trigger} Types={eventTypes} />
                </div>
                <div className="inline w-full">
                  <h2 className="text-lg leading-6 font-medium text-gray-600 dark:text-gray-100">Action</h2>
                  <div className="inline w-full">
                    <Dropdown selectHandler={(event: any) => props.updateActionTrigger(event)} selected={props.workflow?.next} Types={actionTypes} />
                  </div>
                </div>
              </div>
              <div className="mt-1">
                {(props.workflow?.trigger.type.substring(0, 4) === 'MLLP' ||
                  props.workflow?.trigger.type.substring(0, 4) === 'SFTP' ||
                  props.workflow?.trigger.type.substring(0, 4) === 'HTTP') && (
                  <>
                    <div className="inline-flex w-full pb-2">
                      <FlowHeading icon={ArrowUpOnSquareIcon} title="Sample Message" />
                    </div>
                    <div className="relative border border-cyan-300 rounded px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-400 focus-within:border-cyan-400">
                      <div className="mt-1">
                        <textarea
                          className="block w-full border-0 p-0 h-auto text-gray-500 bg-transparent dark:bg-gray-800 dark:text-cyan-400 placeholder-cyan-200 focus:ring-0 sm:text-xs"
                          id="sample-message-hl7v2"
                          name="sample-message"
                          rows={15}
                          defaultValue={props.workflow?.sampleMessage}
                          onChange={async (event) => props.handleSampleMessageChange(event)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
