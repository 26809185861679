
export default function InnerNavButton(props: any) {
  let Icon = null;
  if (props.icon) {  Icon = props.icon; }
  return (<div className="px-1">
    <button type="button" onClick={() => props.clickHandler()} 
    className="inline whitespace-nowrap w-full items-center px-2 py-1 text-xs font-medium text-white border-b-2 border-b-pink-400 focus:outline-none">
    { 
      // props.icon && <Icon name={props.icon} className="pr-1 h-6 w-6 inline" aria-hidden="true" /> 
    }{props.title}
    </button>
  </div>);
}
