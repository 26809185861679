
export default function ActionButton(props: any) {
  let Icon = null;
  if (props.icon) {  Icon = props.icon; }
  return (<div className="px-1">
    <button type="button" onClick={() => props.clickHandler()} 
    className="inline dark:bg-gray-700 whitespace-nowrap w-full items-center p-2 border-2 text-xs text-gray-100 hover:text-white font-medium rounded dark:text-white bg-cyan-400 border-cyan-300 hover:dark:bg-cyan-300 hover:bg-cyan-300 focus:outline-none">
    {
      // props.icon && <Icon name={props.icon} className="pr-1 h-6 w-6 inline" aria-hidden="true" /> 
    }
    {props.title}
    </button>
  </div>);
}
