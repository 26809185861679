import {  Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';

const classNames = (...classes: any) => { return classes.filter(Boolean).join(' ')};

export default function TunnelDropdown(props: any) {
  const [selected, setSelected] = useState(props.selected);
  const [tunnels, setTunnels] = useState(props.tunnels);

  useEffect(() => {
    setSelected(props.selected);
    setTunnels(props.tunnels);
  }, [props.tunnels, props.selected]);


  return (
    <Listbox value={selected} className="m-2" onChange={(newValue) => { setSelected(newValue); props.selectHandler(newValue); }} >
    {({ open }) => (
      <div className="mr-3 whitespace-nowrap relative z-40 w-full">
        <Listbox.Button className="dark:bg-gray-800 text-gray-600 w-full dark:text-gray-100 align-middle relative border border-gray-600 rounded pl-3 pr-12 py-2 text-left text-sm cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-300 focus:border-cyan-300">
          <span className="text-sm truncate">{selected?.PK.length > 0 ? selected.name: tunnels[0].name}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"><ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
        </Listbox.Button>
        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="z-30 align-middle bg-gray-100 dark:bg-gray-800 w-fit text-gray-600 dark:text-gray-200 absolute shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-cyan-300 dark:ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            { tunnels.length && tunnels.map((tunnel: any) => (
              <Listbox.Option 
                className={({ active }) => classNames(active ? 'text-white bg-cyan-400' : 'text-gray-600 dark:text-gray-200', 'w-full cursor-default select-none relative py-2 pl-3 pr-9') } 
                key={tunnel.SK} 
                onClick= { () => props.selectHandler(tunnel) } 
                value={tunnel} 
                defaultValue={tunnel.name}
              >
              {({ selected, active }) => (
                <div>
                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', '')}>{tunnel.name}</span>
                  { selected ? (
                    <span className={classNames(active ? 'text-white' : 'text-cyan-400', 'absolute inset-y-0 right-0 flex items-center pr-4')}><CheckIcon className="h-5 w-5" aria-hidden="true" /></span>
                  ) : null }
                </div>
              )}  
              </Listbox.Option>
            )) }
          </Listbox.Options>
        </Transition>
      </div>
    )}
    </Listbox>
  )
}
  


