import {Switch} from "react-router-dom";
import {PrivateRoute} from "../../Auth/PrivateRoute";
import Dashboard from "../../../pages/Dashboard";
import Workflows from "../../../pages/Workflows";
import Tunnels from "../../../pages/Tunnels";
import Workspace from "../../../pages/Workspace";
import Account from "../../../pages/Account";
import { useAuth } from "../../../hooks/useAuth";
import Footer from "./Footer";

interface Props { 
  account: any;
  environments: any; 
  selectedAccount: any; 
  setAccount: any;
  updateBillingCustomer: any; 
  setSelectedWorkspace: any;
  selectedEnvironment: any; 
  setSelectedEnvironment: any; 
  setupEnvironmentsHandler: any;
  setSelectedAccount: any; 
  redirectToURL: any; 
  history: any; 
  selectedWorkspace: any;
  getAccount: any;
  notify: any;
  environmentChanged: any;
  setEnvironmentChanged: any;
};

export default function Content(props: Props) {
  const auth = useAuth();
  const user = auth.user;
  if (user && user?.account) {
    return (<>
      <main className="animate-color-change-2x dark:animate-color-change-2x-dark flex-grow">
        <Switch>
          <PrivateRoute exact path="/" render={(routeProps: any) =>
            <Dashboard 
              {...routeProps} 
              user={user} 
              account={props.account} 
              setAccount={props.setAccount}
              environments={props.environments} 
              selectedAccount={props.selectedAccount} 
              selectedWorkspace={props.selectedWorkspace}
              updateBillingCustomer={props.updateBillingCustomer}
              selectedEnvironment={props.selectedEnvironment} 
              setSelectedEnvironment={props.setSelectedEnvironment} 
              notify={props.notify}
            />
            }
          />
          <PrivateRoute path="/workflows" render={(routeProps: any) =>
            <Workflows 
              {...routeProps} 
              user={user} 
              account={props.account} 
              setAccount={props.setAccount}
              environments={props.environments} 
              selectedAccount={props.selectedAccount} 
              selectedWorkspace={props.selectedWorkspace}
              selectedEnvironment={props.selectedEnvironment} 
              setSelectedEnvironment={props.setSelectedEnvironment} 
              notify={props.notify}
            />
          }/>
          <PrivateRoute path="/tunnels" render={(routeProps: any) =>
            <Tunnels 
              {...routeProps} 
              user={user} 
              account={props.account} 
              setAccount={props.setAccount}
              environments={props.environments} 
              selectedAccount={props.selectedAccount} 
              selectedEnvironment={props.selectedEnvironment} 
              selectedWorkspace={props.selectedWorkspace}
              setSelectedEnvironment={props.setSelectedEnvironment} 
              notify={props.notify}
            />
          } />
          <PrivateRoute path="/workspace" render={(routeProps: any) =>
            <Workspace 
              {...routeProps} 
              user={user} 
              account={props.account} 
              setAccount={props.setAccount}
              getAccount={props.getAccount}
              environments={props.environments} 
              selectedAccount={props.selectedAccount} 
              setSelectedAccount={props.setSelectedAccount}
              selectedWorkspace={props.account?.workspaces[0]}
              selectedEnvironment={props.selectedEnvironment} 
              setSelectedEnvironment={props.setSelectedEnvironment} 
              setSelectedWorkspace={props.setSelectedWorkspace}
              setupEnvironmentsHandler={props.setupEnvironmentsHandler}
              redirectToURL={props.redirectToURL}
              notify={props.notify}
              environmentChanged={props.environmentChanged}
              setEnvironmentChanged={props.setEnvironmentChanged}
              />
          }/>
          <PrivateRoute path="/account" render={(routeProps: any) =>
            <Account 
              {...routeProps} 
              user={user} 
              account={props.account} 
              setAccount={props.setAccount}
              environments={props.environments} 
              selectedAccount={props.selectedAccount} 
              setSelectedAccount={props.setSelectedAccount}
              selectedEnvironment={props.selectedEnvironment} 
              setSelectedEnvironment={props.setSelectedEnvironment} 
              history={props.history}
              notify={props.notify}
            />
          }/>
        </Switch>
      </main>
      <Footer />
    </>);
  }
  return (<></>);
}
