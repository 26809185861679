import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import FlowHeading from './Elements/FlowHeading';
import URLInputBox from "./Elements/URLInputBox";
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'; 
import CopyToClipboard from 'react-copy-to-clipboard';
import Dropdown from "./Elements/TunnelDropDown";

export default function (props: any) {
  const selectedEnvironment = props.selectedEnvironment ? props.selectedEnvironment : null;
  const mllpURL = selectedEnvironment.accountId !== "587677186137" ? `${selectedEnvironment?.network.nlb?.DNSName.toLowerCase()}:2575` : "mllp.retrohook.com:2575";
  const httpURL = selectedEnvironment.accountId !== "587677186137" ? `${selectedEnvironment?.network.webhook?.ApiEndpoint}/messages/${props.workflow.SK.split('#')[1]}` : "";
  const clipBoardURL = props.workflow.trigger.type.substring(0, 4) == 'HTTP' ? httpURL : mllpURL;
  const tunnels = props.tunnels ? props.tunnels : [];
  const authHeader = {
    "retrohook-api-key": props.selectedWorkspace.hash.value,
  };
  const triggerShort = props?.workflow.trigger.type.substring(0, 4);
  const actionShort = props?.workflow.next.type.substring(0, 4);
  
  return (
    <div className="py-4">
    { props.error == "SaveInAnotherAccountException" && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} /> }
    { props.error == "UserPermissionsException" && <UserPermissionsAlert closeAlert={() => props.clearError()} /> }
    <div className="px-2 md:mt-0"> 
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="dark:bg-gray-800 rounded space-y-6 p-6 mx-6 border-solid border border-cyan-300 bg-transparent"> 
          <div className="inline-flex w-full">
            <FlowHeading title={`${props.workflow?.flowName} Load Action`} />
          </div>
          <div className="border border-top border-cyan-300 mx-2"/>
            <div className="py-2 w-full">
              { (triggerShort == 'MLLP') &&
                <div className="py-2">
                  <div className="relative border border-cyan-300 rounded-md px-2 py-2 w-full">
                    <label htmlFor="env-info" className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200">
                      Workflow Entry
                    </label>
                    <div className="mt-1 flex">
                      <p id="env-info" className=" text-xl dark:text-cyan-400 text-gray-600 flex-1 inline w-full">
                      { selectedEnvironment.accountId === "587677186137" ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">mllp.retrohook.com:2575 <span className="text-sm text-red-400">MLLP Only{" "}</span>
                            <span className="text-red-400">{" "}(IPSec required)</span>.{" "}
                          </span>
                        ) : (``)}
                        { selectedEnvironment.network && selectedEnvironment.accountId === "411790770360" ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                            { selectedEnvironment.network.nlb.DNSName.toLowerCase() }:2575 
                            <span className="text-sm text-red-400">&nbsp;MLLP Only{" "}</span>
                            <span className="text-red-400">{" "}(No PII Allowed)</span>.{" "}
                          </span>
                        ) : (``)}
                        { selectedEnvironment.network && selectedEnvironment.accountId !== "411790770360" && selectedEnvironment.accountId !== "587677186137" ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                            { selectedEnvironment.network?.nlb?.DNSName.toLowerCase() } :2575 
                            <span className="text-sm text-gray-600">&nbsp;MLLP Only{" "}</span>
                            <span className="text-pink-400">{" "}(IPSec required)</span>.{" "}
                          </span>
                        ) : (``)}
                      </p>
                      <CopyToClipboard 
                        text={ selectedEnvironment.accountId !== "587677186137" ? `${selectedEnvironment.network?.nlb?.DNSName.toLowerCase()}:2575` : "mllp.retrohook.com:2575"} 
                        onCopy={() => props.setCopiedHandler()}
                      >
                        <button className="text-xs text-gray-600 dark:text-gray-200 border-cyan-500 hover:text-cyan-300 hover:border-cyan-300 border rounded px-2">
                          Copy To Clipboard { props.copied ? <CheckIcon className="h-4 w-4 pt-0 mt-0 dark:text-cyan-400 text-gray-600 inline" /> : null} 
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              }      
              <div>
                <div className="w-full">
                { actionShort == "HTTP" &&
                  <URLInputBox
                    leftInput="->"
                    url="Workflow Action"
                    onChange={(event: any) => props.handleNextUrl(event)}
                    value={props.workflow.next.url}
                  />
                }
                { actionShort == "MLLP" &&
                  <div className="z-auto grid grid-cols-8 gap-4">
                    <div className="col-span-2">
                      <Dropdown 
                        tunnels={tunnels} 
                        selected={props.workflow.next?.tunnel} 
                        selectHandler={props.handleNextTunnel} 
                      />
                    </div>
                    <div className="col-span-4 mt-2">
                      <URLInputBox
                        leftInput="->"
                        url="Destination Override"
                        onChange={(event: any) => props.handleNextIP(event)}
                        value={props.workflow.next.ip}
                      />
                    </div>
                    <div className="col-span-1 mt-2">
                      <URLInputBox
                        leftInput=":"
                        url="Port Override"
                        onChange={(event: any) => props.handleNextPort(event)}
                        value={props.workflow.next.port}
                      />
                    </div>
                  </div>
                }
                { (actionShort.substring(0, 2) == "S3") &&
                  <div className="flex mt-4 items-center">
                      <div className="flex-grow space-x-4 flex w-full">
                        <div className="inline">
                          <URLInputBox url="S3 Bucket Name" onChange={(event: any) => { props.handleNextS3BucketName(event); }} value={props.workflow.next.bucket} />
                        </div>
                        <div className="inline">
                          <URLInputBox url="SubFolder (Optional)" onChange={(event: any) => { props.handleNextS3Key(event); }} value={props.workflow.next.key} />
                        </div>
                    </div>
                  </div>
                }
                </div>
                { actionShort == "MLLP" && 
                  <div className="relative flex border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                    <label htmlFor="env-info" className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200 z-10">Workflow Entry</label>
                    <div className="mt-1 flex">
                      <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                      { selectedEnvironment.accountId !== "587677186137" ? (
                            <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">{selectedEnvironment?.network?.webhook?.ApiEndpoint}/messages/{props.workflow.SK.split('#')[1]} <span className="text-sm text-gray-600"></span></span>
                        ) : (``)}
                      </p>
                      <CopyToClipboard 
                        text={ clipBoardURL } 
                        onCopy={() => props.setCopiedHandler()}
                      >
                        <button className="text-xs text-gray-600 dark:text-gray-200 border-gray-500 hover:text-cyan-300 hover:border-cyan-300 border rounded px-2">
                          Copy To Clipboard { props.copied ? <CheckIcon className="h-4 w-4 pt-0 mt-0 text-cyan-300 inline" /> : null} 
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                }
              </div>
              { props.workflow.trigger.type == 'HTTPS JSON' && !props.workflow.SK && 
              <div className="pb-2">
                <p className="text-xl text-gray-200 px-4 mx-1 py-6 my-4 border-4 border-orange-200 rounded">
                  <ExclamationCircleIcon className="h-12 w-12 inline text-orange-300 rounded" />
                  The workflow must be saved in order to generate the HTTPS entry point.
                </p>
              </div>
              }
              { props?.workflow?.trigger?.type.substring(0, 4) == "HTTP" &&
              <div className="relative border border-cyan-300 col-span-2 rounded px-3 mt-4 shadow-sm">
                <label htmlFor="payload-info" className="absolute rounded bg-gray-100 -top-4 left-2 -mt-px inline-block px-1  dark:bg-gray-800 text-xs font-medium dark:text-gray-200">
                  Authorization Header
                </label>
                <div className="mt-1 flex overflow-auto">
                  <pre className="text-sm dark:text-cyan-400 text-gray-600 py-2">
                    {JSON.stringify(authHeader, null, 2).substring(2, JSON.stringify(authHeader, null, 2).length - 2)}
                  </pre>
                </div>
              </div>
              }
            </div>
            { (actionShort == "HTTP" || actionShort == "MLLP") &&
              <div className="relative border border-cyan-300 col-span-2 rounded px-3 mb-4 shadow-sm">
                <label htmlFor="payload-info" className="absolute -top-4 left-2 -mt-px rounded inline-block px-1 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200 bg-gray-100 rounded">
                  Sample Body
                </label>
                <div className="mt-1 flex overflow-auto">
                  <pre className="text-sm dark:text-cyan-400 text-gray-600 py-2 text-wrap">
                    {JSON.stringify(props.sampleMap, null, 2)}
                  </pre>
                </div>
              </div>
            }
            { props?.workflow?.next?.type.split(' ')[1] == "FHIR" &&
              <div className="relative border border-cyan-300 col-span-2 rounded px-3 mt-4 w-full shadow-sm">
                <label htmlFor="payload-info" className="absolute -top-4 left-2 -mt-px rounded inline-block px-1 dark:bg-gray-800 text-xs font-medium dark:text-gray-200 bg-gray-100 rounded">
                  Sample Bundle Resource
                </label>
                <div className="mt-1 flex">
                  <pre className="text-sm dark:text-cyan-400 text-gray-600 p-2">
                    {JSON.stringify(props.fhirSampleMap, null, 2)}
                  </pre>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>     
  );
}
