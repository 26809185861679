import EnvironmentForm from "./EnvironmentForm";
import EnvironmentList from "./EnvironmentList";

export default function Environments(props: any) {
  return (
    <div className="p-4">
      <div className="grid grid-cols-7 gap-2">
        <EnvironmentForm 
          user={props.user} 
          account={props.account} 
          setAccount={props.setAccount} 
          selectedWorkspace={props.selectedWorkspace} 
          setSelectedWorkspace={props.setSelectedWorkspace} 
          setupEnvironmentsHandler={props.setupEnvironmentsHandler} 
          notify={props.notify}
        />
        <EnvironmentList 
          user={props.user} 
          account={props.account} 
          setAccount={props.setAccount} 
          selectedWorkspace={props.selectedWorkspace}
          setSelectedWorkspace={props.setSelectedWorkspace} 
          setupEnvironmentsHandler={props.setupEnvironmentsHandler} 
          notify={props.notify}
          environmentChanged={props.environmentChanged}  
          setEnvironmentChanged={props.setEnvironmentChanged}
        />
      </div>
    </div>
  );
}
