
import { useCallback, useEffect, useState } from "react";
import useFetch from 'use-http';
import { ServerIcon, TrashIcon, RocketLaunchIcon, CheckIcon } from '@heroicons/react/24/outline';
import InputBox from "../Base/Elements/Input/InputBox";
import ConfirmModal from "../Base/Elements/Confirmations/ConfirmModal";
import config from "../../../config";
import logo from '../../../images/retrohook.png';
const stacks = ['storage', 'network', 'compute'];

const stackStatusMap = {
  'CREATE_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Creating...</span>,
  'CREATE_FAILED': <span className="text-red-400 text-xs overflow-hidden">Failed</span>,
  'CREATE_COMPLETE': <span className="text-green-400 text-xs overflow-hidden">Ready</span>,
  'ROLLBACK_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  'ROLLBACK_FAILED': <span className="text-red-400 text-xs overflow-hidden">Rollback failed</span>,
  'ROLLBACK_COMPLETE': <span className="text-green-400 text-xs overflow-hidden">Rolled back</span>,
  'DELETE_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Deleting...</span>,
  'DELETE_FAILED': <span className="text-red-400 text-xs overflow-hidden">Delete failed</span>,
  'DELETE_COMPLETE': <span className="text-green-400 text-xs overflow-hidden">Deleted</span>,
  'UPDATE_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Updating...</span>,
  'UPDATE_COMPLETE_CLEANUP_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Updating...</span>,
  'UPDATE_COMPLETE': <span className="text-green-400 text-xs overflow-hidden">Updated</span>,
  'UPDATE_ROLLBACK_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  'UPDATE_ROLLBACK_FAILED': <span className="text-red-400 text-xs overflow-hidden">Rollback failed</span>,
  'UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Rolling back...</span>,
  'UPDATE_ROLLBACK_COMPLETE': <span className="text-green-400 text-xs overflow-hidden">Rolled back</span>,
  'REVIEW_IN_PROGRESS': <span className="text-cyan-400 text-xs overflow-hidden">Reviewing...</span>
};

export default function EnvironmentList(props: any) {
  const { post, put, response, loading } = useFetch(config.url);
  const [environments, setEnvironments] = useState(props.selectedWorkspace.environments.filter((environment: any) => environment.name !== '🚀 Production' && environment.name !== '🧪 Development'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEnvironment, setCurrentEnvironment] = useState<any>();
  const [currentStack, setCurrentStack] = useState<any>();
  const [isEnvModalOpen, setIsEnvModalOpen] = useState(false);
  const [updatedEnvironment, setUpdatedEnvironment] = useState<any>();
  const fetchData = useCallback(async () => {
    const envs = props.selectedWorkspace.environments.filter(
      (environment: any) => environment.name !== '🚀 Production' && environment.name !== '🧪 Development'
    );
    await Promise.all(
      envs.map(async (environment: any) => {
        if (!environment.resources || environment.resources.length < 1) {
          environment = await getStackResources(environment);
        }
        return environment;
      })
    );
  }, []);

  useEffect(() => { fetchData(); }, [fetchData]);

  useEffect(() => {
    if (props.environmentChanged) fetchData();
    return () => props.setEnvironmentChanged(null);
  }, [props.environmentChanged]);


  /**
   * The function `getStackResources` is an asynchronous function that retrieves environment resources
   * and updates the list of environments.
   * @param {any} environment - The `environment` parameter is an object that represents the
   * environment for which we want to get stack resources. It can contain any properties that are
   * necessary to identify the environment, such as an ID or a name.
   */
  const getStackResources = async (environment: any) => {
    let newEnvs: any = environments ? environments : props.selectedWorkspace.environments;
    const environmentRequest: any = await post(`environment/get/${props.user.attributes.id}`, environment);
    if (response.ok && environmentRequest) {
      newEnvs = newEnvs.filter((env: any) => env.SK !== environmentRequest?.environment?.SK);
      newEnvs.push(environmentRequest.environment);      
      setEnvironments(newEnvs);
      props.setupEnvironmentsHandler(newEnvs);
    }
  }
  /**
   * The function `saveEnvironment` is an asynchronous function that takes in an `environment` and
   * `workspace` parameter, and it makes a POST request to create a new environment using the provided
   * parameters and the `props.account` value.
   * @param {any} environment - The `environment` parameter is an object that represents the
   * environment you want to update. It could contain properties such as the environment name,
   * description, configuration settings, or any other relevant information about the environment.
   * @param {any} workspace - The `workspace` parameter is an object that represents the workspace
   * where the environment will be created. It likely contains information such as the workspace ID,
   * name, and any other relevant details about the workspace.
   */
  const saveEnvironment = async (env: any, wkspace: any) => {
    const params = {
      environment: env,
      workspace: wkspace,
      account: props.account
    };
    const envUpdateRequest = await post(
      `account/update-account-environment/${props.user.attributes.id}`,
      params
    );
    if (response.ok && envUpdateRequest) {
      getStackResources(env);
      props.setSelectedWorkspace(envUpdateRequest.workspace);
      props.setAccount(envUpdateRequest.account);
    }
  } 
  /**
   * The function handles the change of an environment name and updates the environment and selected
   * workspace.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the function. It contains information about the event, such as the target element and the value of
   * the input field that triggered the event.
   * @param {any} environment - The `environment` parameter is an object that represents an
   * environment. It likely has properties such as `name`, `id`, and other attributes that describe the
   * environment.
   */
  const handleEnvironmentNameChange = (event: any, environment: any) => {
    const updated = { ...environment, name: event.target.value };
    setUpdatedEnvironment(updated);
    const newEnvs = environments.filter((env: any) => env.SK !== updated.SK);
    newEnvs.push(updated);
    setEnvironments(newEnvs);
  }
  /**
   * The function `deployStack` deploys a stack in a specific environment using the provided
   * parameters.
   * @param {any} environment - The `environment` parameter is an object that represents the
   * environment in which the stack will be deployed. It could contain properties such as the
   * environment name, configuration settings, and other relevant information.
   * @param {string} stack - The `stack` parameter is a string that represents the type of stack to be
   * deployed. It could be something like "frontend", "backend", or any other specific stack name.
   */
  const deployStack = async (environment: any, stack: string) => {
    const params = { environment, workspace: props.selectedWorkspace, account: props.account  };
    const deployRequest = await post(`environment/deploy-${stack}/${props.user.attributes.id}`, params);
    if (deployRequest && response.ok) {
      getStackResources(environment);
      props.setSelectedWorkspace(deployRequest.workspace);
      props.setAccount(deployRequest.account);
    } 
  }
  /**
   * The function `deleteStack` is an asynchronous function that takes in an environment and a stack as
   * parameters, and sends a delete request to the server to delete the specified stack in the
   * specified environment.
   * @param {any} environment - The `environment` parameter is a variable that represents the
   * environment in which the stack is located. It could be a string or an object that contains
   * information about the environment, such as its name or ID.
   * @param {string} stack - The `stack` parameter is a string that represents the name or identifier
   * of the stack that you want to delete.
   */
  const deleteStack = async (environment: any, stack: string) => {
    const params = { environment, workspace: props.selectedWorkspace, account: props.account };
    const deleteRequest = await post(`environment/delete-${stack}/${props.user.attributes.id}`, params); 
    if (deleteRequest && response.ok) {
      props.setSelectedWorkspace(deleteRequest.workspace);
      props.setAccount(deleteRequest.account);
      
    }
  }
  /**
   * The function `deleteEnvironment` is an asynchronous function that deletes an environment and
   * updates the account and selected workspace if the deletion is successful.
   * @param {any} environment - The `environment` parameter is an object that represents the
   * environment to be deleted. It can contain any properties or data that are relevant to the
   * environment being deleted.
   */
  const deleteEnvironment = async (environment: any) => {
    const params = { environment, workspace: props.selectedWorkspace, account: props.account  };
    const deleteRequest = await post(`environment/delete-environment/${props.user.attributes.id}`, params); 
    if (deleteRequest && response.ok) {
      props.notify({ message: `␡ Deleted ${environment.name}` });
      props.setAccount(deleteRequest.account);
      props.setSelectedWorkspace(deleteRequest.workspace);
      setEnvironments(deleteRequest.workspace.environments.filter((env: any) => env !== environment));
    }
  }
  /**
   * The above code defines functions for opening and closing a modal, and confirming a delete action.
   * @param {any} environment - The "environment" parameter is a variable that represents the current
   * environment or context in which the modal is being opened. It could be a string, object, or any
   * other data type that represents the environment.
   * @param {string} stack - The "stack" parameter is a string that represents the stack to be deleted.
   */
  const openModal = (environment: any, stack: string) => {
    setCurrentEnvironment(environment);
    setCurrentStack(stack);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const confirmDelete = () => {
    deleteStack(currentEnvironment, currentStack);
    closeModal();
  };
  const openEnvModal = (environment: any) => {
    setCurrentEnvironment(environment);
    setIsEnvModalOpen(true);
  };
  const closeEnvModal = () => {
    setIsEnvModalOpen(false);
  };
  const confirmEnvDelete = () => {
    deleteEnvironment(currentEnvironment);
    closeEnvModal();
  };
  return (
    <>
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={() => confirmDelete()}
        title="Confirm Delete Stack"
        message={`Are you sure you want to delete ${currentStack} for ${currentEnvironment?.name}?`}
      />
      <ConfirmModal
        isOpen={isEnvModalOpen}
        onClose={closeEnvModal}
        onConfirm={() => confirmEnvDelete()}
        title="Confirm Delete Environment"
        message={`Are you sure you want to delete ${currentEnvironment?.name}?`}
      />
      <div className="col-span-5">
      <dl className="grid grid-cols-1 gap-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        {environments.map((environment: any) => (
          <div key={environment.SK} className="relative dark:bg-gray-800 px-4 pt-6 pb-2 border-2 border-gray-400 rounded-lg overflow-hidden hover:border-cyan-300 border-2 border-solid">
            { loading ? 
              <div className="items-center animate-pulse">
                <img className="h-14 mx-auto w-auto" src={logo} alt="Logo"/>
                <h2 className="mt-0 text-center text-xl font-bold text-gray-200"
                style={{ fontFamily: '"Gugi", sans-serif' }}
                >Retrohook
                </h2>
              </div>
              : <>
                <div className="flex w-full">
                  <InputBox
                    name="Name"
                    onChange={(event: any) => { handleEnvironmentNameChange(event, environment)}}
                    placeholder={environment.name}
                    defaultValue={environment.name}
                  />
                  <button
                    className="px-2 rounded-md ml-1 border border-gray-500 hover:border-cyan-400"
                    onClick={async () => await saveEnvironment(environment, props.selectedWorkspace)}
                  >
                    <CheckIcon className="h-5 w-5 text-gray-500 hover:text-cyan-400" />
                  </button>
                  <div className="pl-2 block">
                    <p className="px-2 text-center text-xs text-gray-400">
                      {`${environment.accountId}`}
                    </p>
                    <p className="px-2 text-center text-xs text-gray-400">
                      {`${environment.region}`}
                    </p>
                  </div>
               
                  <div className="flex justify-end space-x-2">
                    { (!environment['storage'].status?.includes('DELETE_COMPLETE') || !environment['storage'].stackId) ? 
                      null  :
                        <button className="text-xs text-gray-600 dark:bg-gray-700 hover:bg-pink-500 dark:text-white font-bold px-2 py-1 rounded focus:outline-none focus:shadow-outline" onClick={() => openEnvModal(environment)}>
                          <TrashIcon className="h-4 w-4" />
                        </button>
                      }
                  </div>
                </div>         
                <div className="grid grid-cols-3 gap-2 pt-4">
                  {stacks.map((stack) => {
                    const isStorage = stack === 'storage';
                    const status = environment[stack]?.status || '';
                    const storageStatus = environment['storage']?.status;
                    const canDelete = !status?.includes('PROGRESS') && !status?.includes('DELETE_COMPLETE');
                    const canDeploy = !status?.includes('PROGRESS') && !status?.includes('CREATE_COMPLETE');
                    const storageCreateComplete = storageStatus?.includes('CREATE_COMPLETE');

                    const showRocket = (
                      isStorage && canDeploy ||
                      !isStorage && canDeploy && storageCreateComplete
                    );

                    return (
                      <div key={stack} className="text-center">
                        <p className="text-lg text-gray-400 capitalize">{stack}</p>
                        {status ? 
                          <p className="text-xs text-cyan-400 overflow-hidden">
                            { stackStatusMap[status] }
                          </p>
                         : 
                          <p className="text-xs text-cyan-400">Deleted</p>
                        }

                        <div className="flex mt-1 items-center text-md text-white font-bold focus:outline-none focus:shadow-outline">
                          <div className="w-full items-center mx-2 space-x-2"> 
                            {canDelete && (
                              <button className="px-2 py-1 bg-transparent dark:bg-gray-700 hover:bg-pink-500 rounded" onClick={() => openModal(environment, stack)}>
                                <TrashIcon className="h-5 w-5 text-pink-400"/>
                              </button>
                            )}
                            {showRocket && (
                              <button className="px-2 py-1 bg-gray-700 hover:bg-cyan-500 rounded" 
                                onClick={() => environment[stack].stackId ? deployStack(environment, stack) : deployStack(environment, stack)}>
                                <RocketLaunchIcon className="h-5 w-5 text-cyan-400"/>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
              }
            </div>
          ))}
        </dl>
      </div>
    </> 
  );
}
