/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-use-before-define */
import { Fragment, useState, useEffect } from 'react';
import {
  Bars3Icon,
  XMarkIcon,
  DocumentTextIcon,
  CalendarDaysIcon,
  PlayCircleIcon,
  ChatBubbleLeftRightIcon,
  ArrowRightIcon,
  ArrowDownRightIcon,
} from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import { useTheme } from '../context/ThemeContext';

import PricingBox from '../components/Accounts/Elements/PricingBox';
import LoginModal from '../components/Auth/LoginModal';
import RegisterModal from '../components/Auth/RegisterModal';
import AWSRegister from '../components/Auth/AWSRegister';
import VerificationCodeModal from '../components/Auth/VerificationCodeModal';
import TermsOfServiceModal from '../components/Auth/TermsOfServiceModal';
import MatrixOfHL7 from '../components/Accounts/Elements/MatrixOfHL7';
import ThemeToggler from '../components/Base/Layout/ThemeToggler';
import ChatwootWidget from '../components/Base/Layout/ChatWootWidget';

import AWSMarket from '../../images/aws-mkt.png';
import AWSMarketDark from '../../images/aws-mkt-dark.png';
import logo from '../../images/retrohook.png';
import screenshot from '../../images/screenshot.png';
import screenshotLight from '../../images/screenshot-light.png';
import hospital from '../../images/hospital.png';
import happy from '../../images/happy.png';
import teams from '../../images/teams.png';
import serverRoom from '../../images/secure-tunnel.png';
import cool from '../../images/cool.png';
import help from '../../images/help.png';
import cloudPatient from '../../images/cloud-patient.svg';

const nav = [
  { name: 'Home', href: '#top', current: true },
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
  { name: 'Docs', href: 'https://docs.retrohook.com/' },
  { name: 'Contact', href: '#contact' },
];
const mainFeatures = [
  {
    id: 1,
    title: 'No-Code Integrations',
    href: 'https://docs.retrohook.com/docs/workflows',
    category: { name: 'Built for Everyone', href: 'https://docs.retrohook.com/docs/workflows' },
    imageUrl: hospital,
    preview: "Retrohook's workflow engine allow you to route and map legacy hl7v2 data into useful HTTPS requests (webhooks), or AWS services.",
  },
  {
    id: 2,
    title: 'Simple Secure Tunnels',
    href: 'https://docs.retrohook.com/docs/tunnels',
    category: { name: 'IPSec IKEv2 VPN', href: 'https://docs.retrohook.com/docs/tunnels' },
    imageUrl: serverRoom,
    preview:
      'Retrohook ensures secure interfaces by supporting both old and new security tech. Easily create safe MLLP tunnels with its user-friendly UI and connection agent.',
  },
  {
    id: 3,
    title: 'Private AWS Deployments',
    href: 'https://docs.retrohook.com/docs/workspaces',
    category: { name: 'Leverage Your AWS', href: 'https://docs.retrohook.com/docs/workspaces' },
    imageUrl: happy,
    preview: 'Deploy a Retrohook instance into your own AWS environment for enhanced compliance and security controls.',
  },
  {
    id: 4,
    title: 'Team Mode',
    href: 'https://docs.retrohook.com/docs/intro#workspaces--environments',
    category: { name: 'Shared Workspaces', href: 'https://docs.retrohook.com/docs/intro#workspaces--environments' },
    imageUrl: teams,
    preview: 'Share your workspace and collaborate with others. Team mode also provides granular controls for managing access.',
  },
];

export default function Product(props: any) {
  const matrixCSS = '';
  const { effectiveTheme } = useTheme();
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [verificationCodeModal, setVerificationCodeModal] = useState(false);
  const [email, setEmail] = useState('');
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [showAWSRegistrationModal, setShowAWSRegistrationModal] = useState(false);
  const [matrix, setMatrix] = useState<any>(false);
  const [currentSection, setCurrentSection] = useState('Home');

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (props.mktplaceToken) {
      setShowAWSRegistrationModal(true);
    } else {
      setShowAWSRegistrationModal(false);
    }
  }, [props.mktplaceToken]);

  const addPackageDetails = (product: any) => {
    switch (product.product[0].name) {
      case 'Production Plan':
        return {
          ...product,
          features: [
            'Everything in Developer +',
            'Unlimited workflows for leveraging legacy healthcare events.',
            'Unlimited team members to collaborate.',
            'Deploy Retrohook instances into your cloud infrastructure.',
            'IPSEC Tunnels for robust and secure EHR connections.',
            'Production Features - S3 Actions, FHIR R4 and more...',
          ],
        };
      case 'Developer Plan':
        return {
          ...product,
          features: [
            'Effortless Serverless Healthcare Messaging: Experience seamless hl7v2 communication without the complexities of server management',
            'Test Workflows: Design and test up to 5 non-PHI workflows, perfect for experimentation and learning.',
            'Shared Developer Workspace: Collaborate effectively with a 3-seat workspace designed for dynamic development teams.',
            'Message Allocation: Benefit from 100 messages per month, allowing for ample testing and development.',
            'Community Support: Gain insights and assistance from a community that understands your development needs.',
          ],
        };
      default:
        return {};
    }
  };

  const getProductsJSON = async () => {
    return {
      data: [
        {
          id: 'price_1LOCsQKCFwpQCzl3J5KmLZN7',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1658461810,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: [
            {
              id: 'prod_M60OTkntKj1Nxq',
              object: 'product',
              active: true,
              attributes: [],
              created: 1658367664,
              default_price: 'price_1LNoNxKCFwpQCzl3CLSELQcS',
              description: null,
              features: [],
              images: [],
              livemode: false,
              metadata: {},
              name: 'Developer Plan',
              package_dimensions: null,
              shippable: null,
              statement_descriptor: null,
              tax_code: 'txcd_10103001',
              type: 'service',
              unit_label: null,
              updated: 1658461811,
              url: null,
            },
          ],
          recurring: {
            aggregate_usage: null,
            interval: 'year',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          tax_behavior: 'exclusive',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 0,
          unit_amount_decimal: '0',
        },
        {
          id: 'price_1LOC37KCFwpQCzl3fkBFnwll',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1658458629,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: [
            {
              id: 'prod_M60PZr5qZsMtCZ',
              object: 'product',
              active: true,
              attributes: [],
              created: 1658367710,
              default_price: 'price_1LNoOhKCFwpQCzl3UttinsyD',
              description: null,
              features: [],
              images: [],
              livemode: false,
              metadata: {},
              name: 'Production Plan',
              package_dimensions: null,
              shippable: null,
              statement_descriptor: null,
              tax_code: 'txcd_10103001',
              type: 'service',
              unit_label: null,
              updated: 1658458630,
              url: null,
            },
          ],
          recurring: {
            aggregate_usage: null,
            interval: 'year',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          tax_behavior: 'exclusive',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 10900000,
          unit_amount_decimal: '10900000',
        },
        {
          id: 'price_1LNoOhKCFwpQCzl3UttinsyD',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1658367711,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: [
            {
              id: 'prod_M60PZr5qZsMtCZ',
              object: 'product',
              active: true,
              attributes: [],
              created: 1658367710,
              default_price: 'price_1LNoOhKCFwpQCzl3UttinsyD',
              description: null,
              features: [],
              images: [],
              livemode: false,
              metadata: {},
              name: 'Production Plan',
              package_dimensions: null,
              shippable: null,
              statement_descriptor: null,
              tax_code: 'txcd_10103001',
              type: 'service',
              unit_label: null,
              updated: 1658458630,
              url: null,
            },
          ],
          recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          tax_behavior: 'exclusive',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 500000,
          unit_amount_decimal: '1000000',
        },
        {
          id: 'price_1LNoNxKCFwpQCzl3CLSELQcS',
          object: 'price',
          active: true,
          billing_scheme: 'per_unit',
          created: 1658367665,
          currency: 'usd',
          custom_unit_amount: null,
          livemode: false,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: [
            {
              id: 'prod_M60OTkntKj1Nxq',
              object: 'product',
              active: true,
              attributes: [],
              created: 1658367664,
              default_price: 'price_1LNoNxKCFwpQCzl3CLSELQcS',
              description: null,
              features: [],
              images: [],
              livemode: false,
              metadata: {},
              name: 'Developer Plan',
              package_dimensions: null,
              shippable: null,
              statement_descriptor: null,
              tax_code: 'txcd_10103001',
              type: 'service',
              unit_label: null,
              updated: 1658461811,
              url: null,
            },
          ],
          recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'licensed',
          },
          tax_behavior: 'exclusive',
          tiers_mode: null,
          transform_quantity: null,
          type: 'recurring',
          unit_amount: 0,
          unit_amount_decimal: '0',
        },
      ],
    };
  };

  const getProducts = async () => {
    try {
      const productItems: any = await getProductsJSON();
      if (productItems?.data) {
        const productsFromApi = productItems.data.map((product: any) => addPackageDetails(product));
        setProducts(productsFromApi.sort((a: any, b: any) => (a.unit_amount > b.unit_amount ? 1 : -1)));
      }
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  const handleNavClick = (e: any, href: string, sectionName: string) => {
    e.preventDefault();
    if (href.startsWith('http')) {
      window.location.href = href;
    } else {
      setCurrentSection(sectionName);
      const targetSection = document.querySelector(href);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const getNavItemClass = (name: string) => {
    return name === currentSection
      ? 'px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50 border-b-2 border-pink-500'
      : 'px-3 py-1 text-base font-medium text-gray-700 dark:text-gray-50 hover:border-b-2 hover:border-b-cyan-400';
  };

  if (props?.user) {
    return (
      <div className="flex flex-col justify-center items-center animate-color-change-2x dark:animate-color-change-2x-dark h-screen">
        <div
          className="items-center animate-pulse"
          onClick={(e) => {
            handleNavClick(e, '#top', 'Home');
          }}
        >
          <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
          <h2 className="mt-0 text-center text-3xl font-bold text-gray-600 dark:text-gray-200">
            Retrohook
            <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
          </h2>
        </div>
      </div>
    );
  }
  return (
    <main id="top">
      {matrix ? (
        <MatrixOfHL7 />
      ) : (
        <Popover as="header" className="sticky top-0 z-10 border-b-2 border-cyan-400 animate-color-change-2x dark:animate-color-change-2x-dark">
          <div className="py-2">
            <nav className="mx-auto flex items-center justify-between sm:pl-3" aria-label="Global">
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <button onClick={(e) => handleNavClick(e, '#top', 'Home')}>
                    <img className="h-10 inline px-1" src={logo} alt="logo" />
                    <span className="inline align-middle text-3xl text-gray-700 dark:text-gray-100" style={{ fontFamily: '"Gugi", sans-serif' }}>
                      Retrohook
                    </span>
                  </button>
                  <div className="mr-4 flex items-center md:hidden">
                    <Popover.Button className="dark:bg-gray-700 bg-gray-100 rounded-md p-2 inline-flex items-center justify-center dark:text-gray-100 text-gray-700 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="hidden space-x-8 md:flex md:ml-10">
                  {nav.map((item) => (
                    <a key={item.name} href={item.href} className={getNavItemClass(item.name)} onClick={(e) => handleNavClick(e, item.href, item.name)}>
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
              <ThemeToggler />
              <div className="hidden md:flex pr-4">
                <a
                  onClick={() => {
                    setLoginModal(true);
                    setMatrix(!matrix);
                  }}
                  className="px-2 py-1 cursor-pointer border-2 border-solid border-cyan-300 hover:border-gray-50 text-sm font-medium rounded text-cyan-600 bg-gray-100 hover:bg-gray-100"
                >
                  Sign In
                </a>
              </div>
            </nav>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden dark:animate-color-change-2x-dark">
              <div className="rounded-lg px-6 shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <button onClick={(e) => handleNavClick(e, '#top', 'Home')}>
                    <img className="h-10 inline px-1 shadow-sm" src={logo} alt="logo" />
                    <span className="inline align-middle text-2xl tracking-tight font-bold text-gray-700 dark:text-gray-100 pl-1">Retrohook</span>
                  </button>
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-700 dark:text-gray-50 hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="pt-5 pb-6">
                  <div className="px-2 space-y-1">
                    {nav.map((item) => (
                      <a key={item.name} href={item.href} onClick={(e) => handleNavClick(e, item.href, item.name)} className={getNavItemClass(item.name)}>
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
      <ChatwootWidget />
      {showAWSRegistrationModal && (
        <AWSRegister
          mktplaceToken={props.mktplaceToken}
          setNavHighlight={props.setNavHighlight}
          setEmail={setEmail}
          open={showAWSRegistrationModal}
          setVerificationCodeModel={() => setVerificationCodeModal(true)}
          closeModal={() => {
            setShowAWSRegistrationModal(false);
            setMatrix(!matrix);
          }}
        />
      )}
      {showTermsOfService && (
        <TermsOfServiceModal
          open={showTermsOfService}
          closeModal={() => {
            setShowTermsOfService(false);
            setMatrix(!matrix);
          }}
        />
      )}
      {loginModal && (
        <LoginModal
          setNavHighlight={props.setNavHighlight}
          open={loginModal}
          setRegisterModal={() => {
            setLoginModal(false);
            setRegisterModal(true);
          }}
          closeModal={() => {
            setLoginModal(false);
            setMatrix(!matrix);
          }}
        />
      )}
      {registerModal && (
        <RegisterModal
          open={registerModal}
          setSignInModal={() => {
            setRegisterModal(false);
            setLoginModal(true);
          }}
          closeRegisterModal={() => {
            setRegisterModal(false);
          }}
          setEmail={setEmail}
          setVerificationCodeModel={() => {
            setVerificationCodeModal(true);
          }}
          closeModal={() => {
            setRegisterModal(false);
            setMatrix(!matrix);
          }}
        />
      )}
      {verificationCodeModal && (
        <VerificationCodeModal
          open={verificationCodeModal}
          email={email}
          setSignInModal={() => {
            setVerificationCodeModal(false);
            setLoginModal(true);
          }}
          closeModal={() => {
            setVerificationCodeModal(false);
            setMatrix(!matrix);
          }}
        />
      )}
      <div id="home" className="pt-2 *:lg:overflow-hidden bg-cyan-500 dark:animate-color-change-2x-dark border-b-2 border-b-cyan-400">
        {matrix ? <MatrixOfHL7 /> : null}
        <div className="mx-auto px-8">
          <div className="inline-flex">
            <div className="mx-auto inline px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:items-center">
              <div className="py-20 ~py-12/py-48">
                <h1
                  className={classNames(
                    matrix ? matrixCSS : '',
                    'mt-4 text-4xl tracking-tight font-bold text-gray-700 dark:text-white sm:mt-5 sm:text-xl sm:tracking-tight lg:mt-6 md:text-4xl lg:text-5xl xl:text-5xl xl:tracking-tight',
                  )}
                >
                  <span style={{ fontFamily: '"Gugi", sans-serif' }} className="block text-gray-100">
                    Instant Integration
                  </span>
                  <span
                    style={{ fontFamily: '"Gugi", sans-serif' }}
                    className="bg-clip-text text-transparent bg-gradient-to-r from-gray-100 to-cyan-400 dark:from-gray-200 dark:to-pink-600"
                  >
                    Infinite Innovation
                  </span>
                </h1>
                <p className={classNames(matrix ? matrixCSS : '', 'mb-4 text-base text-cyan-300 dark:text-gray-300 ~text-xl/text-lg xl:text-xl')}>
                  Empower your product with seamless, secure hl7 exchange.
                </p>
                <div>
                  <div className="mt-2 flex mx-20">
                    <div className="rounded-md shadow">
                      <a
                        onClick={() => { setRegisterModal(true); setMatrix(!matrix); }}
                        className="w-full flex items-center px-8 py-3 border-transparent text-base font-medium rounded-md cursor-pointer border-2 border-pink-400 hover:border-pink-300 text-white bg-pink-400 md:text-lg"
                      >
                        Free Developer Account
                      </a>
                    </div>
                  </div>
                  <p className="text-xs mx-16 text-white dark:text-gray-300">
                    by signing up, you agree to our{'  '}
                    <span
                      onClick={() => {
                        setShowTermsOfService(true);
                        setMatrix(!matrix);
                      }}
                      className="text-purple-500 cursor-pointer hover:underline"
                    >
                    terms of service
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-auto px-4 sm:max-w-2xl sm:px-6 lg:px-0">
              <img className="absolute right-10 top-10 ~opacity-20/100" src={cloudPatient} alt="data UP UP" />
            </div>
          </div>
        </div>
      </div>
      <div id="overview" className="relative pt-6 animate-color-change-2x dark:animate-color-change-2x-dark">
        {matrix ? <MatrixOfHL7 /> : null}
        <div className="mx-auto max-w-md px-4 text-center  sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div className={classNames(matrix ? matrixCSS : '', 'mx-auto')}>
            <h2 className="text-xl font-semibold text-pink-500 dark:text-cyan-300">⚡️ Harness Real Time EHR Event Automation ⚡️</h2>
            <p
              style={{ fontFamily: '"Gugi", sans-serif' }}
              className="mt-2 text-3xl font-bold text-gray-600 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight"
            >
              🔥 Reignite HL7v2.x With Clicks, Not Code.
            </p>
            <p className="mt-2 max-w-prose mx-auto text-xl text-gray-400">Quickly and securely connect EHR data to modern technologies you ❤️.</p>
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <img
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src={effectiveTheme === 'dark' ? screenshot : screenshotLight}
              alt="A view of Retrohook"
            />
          </div>
        </div>
      </div>
      <div id="features" className="relative animate-color-change-2x dark:animate-color-change-2x-dark">
        {matrix ? <MatrixOfHL7 /> : null}
        <div className="relative items-center">
          <div className=" py-8 mb-16">
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-lg font-semibold text-cyan-500" style={{ fontFamily: '"Gugi", sans-serif' }}>
                Learn more about how Retrohook
              </h2>
              <p className="mt-2 text-3xl font-bold text-pink-400 dark:text-gray-200 tracking-tight sm:text-4xl sm:tracking-tight">
                <span className="mx-2" style={{ fontFamily: '"Gugi", sans-serif' }}>
                  Accelerates HL7v2 Integration
                </span>
              </p>
              <p className="mb-10 mt-5 mx-auto max-w-prose text-xl text-gray-500">
                <span className="mx-2">
                  Work as a team or individual to deploy workflow automation in a fraction of the time with a DIY approach, from the comfort of your own
                  infrastructure!
                </span>
              </p>
            </div>
            <div className="mx-auto px-4 grid gap-8 sm:px-6 lg:px-8 sm:grid-cols-1 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-7xl">
              {mainFeatures.map((post) => (
                <div
                  key={post.id}
                  className="flex flex-col rounded-lg ring-2 ring-gray-200 dark:ring-cyan-400 overflow-hidden hover:animate-pulse hover:ring-2 hover:ring-cyan-300 cursor-pointer"
                >
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                  </div>
                  <div className="flex-1 p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-pink-500 dark:text-cyan-400">
                        <a href={post.category.href} className="hover:underline">
                          {post.category.name}
                        </a>
                      </p>
                      <a href={post.href} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-700 dark:text-gray-200">{post.title}</p>
                        <p className="text-base text-gray-500 dark:text-gray-400">{post.preview}</p>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-cyan-500 bg-gradient-to-r from-cyan-300 lg:z-10">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 grid grid-cols-3 lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="lg:-mt-28 md:-mt-14 mt-32">
                <div className="mx-auto px-4 p-0 lg:h-full">
                  <div className="aspect-w-10 aspect-h-10 overflow-hidden sm:aspect-w-7 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img className="max-h-96 sm:max-h-84 md:max-h-84" src={cool} alt="Retrohook doc" />
                  </div>
                </div>
              </div>
              <div className="lg:m-0 col-span-2 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 mt-8 lg:max-w-none">
                  <blockquote>
                    <div>
                      <p className="mt-4 sm:text-sm md:text-lg lg:text-2xl xl:text-2xl font-medium text-white">
                        <svg className="h-6 w-6 inline m-3 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        Taking advantage of the immense power of HL7 v2.x&apos;s event driven messaging does not have to be difficult.
                        <br />
                        <br />
                        Retrohook&apos;s abstractions provide rapid time to value and shorter product on ramps.
                        <svg className="h-6 w-6 inline rotate-180 m-3 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                      </p>
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pricing">
        {matrix ? <MatrixOfHL7 /> : null}
        <div className="relative items-center animate-color-change-2x dark:animate-color-change-2x-dark">
          <div className=" py-8">
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <PricingBox setRegisterModal={() => setRegisterModal(!registerModal)} products={products} setMatrix={() => setMatrix(!matrix)} />
            </div>
          </div>
        </div>
      </div>
      <div id="contact" className="border-t-2 border-t-cyan-400 dark:bg-cyan-500 dark:bg-gradient-to-r dark:from-cyan-300 pb-2">
        {matrix ? <MatrixOfHL7 /> : null}
        {!matrix ? (
          <>
            <div className="relative md:absolute md:left-0 md:w-1/2">
              <img src={help} className="~h-72/96" alt="Retrohook takes you back in time" />
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-7xl sm:px-6 py-auto">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <div className={classNames(matrix ? 'px-12 py-4 m-2 border-2 border-gray-600 rounded' : '', 'cursor-pointer')}>
                  <h2 className="text-lg font-semibold text-cyan-300 align-middle md:pt-12">&nbsp;</h2>
                  <p
                    className=" text-gray-700 dark:text-white text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight"
                    style={{ fontFamily: '"Gugi", sans-serif' }}
                  >
                    Get Started with Retrohook
                  </p>
                  <p className="mt-4 text-xl text-gray-600 dark:text-gray-100">
                    Check out our documentation to learn how to get started with Retrohook. When you are ready we are here to help.
                  </p>
                  <div className="my-6 w-full flex flex-wrap ~gap-x-2/4 gap-y-2">
                    <a
                      href="https://docs.retrohook.com"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-indigo-400 hover:text-indigo-500"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <DocumentTextIcon className="-ml-1 mr-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                      Docs
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCsgby_S_K27scdBlvvOJK8A"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-cyan-400 hover:text-cyan-300 dark:text-red-400 dark:hover:text-red-500"
                    >
                      <PlayCircleIcon className="-ml-1 mr-1 h-5 w-5 text-cyan-500 dark:text-red-400" aria-hidden="true" />
                      Youtube
                    </a>
                    <a
                      href="https://calendly.com/clint_johnson/retrohook?hide_gdpr_banner=1"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium text-purple-400 hover:text-purple-500"
                    >
                      <CalendarDaysIcon className="-ml-1 mr-1 h-5 w-5 text-purple-500" aria-hidden="true" />
                      Schedule a Demo
                    </a>
                    <div>
                      <a
                        href="#/contact"
                        className="inline-flex items-center pl-3 pr-1 py-2 border border-transparent text-base font-medium rounded-md text-pink-500 hover:text-pink-500"
                      >
                        <ChatBubbleLeftRightIcon className="-ml-1 mr-1 h-5 w-5 text-pink-500" aria-hidden="true" />
                        Chat
                      </a>
                      <ArrowDownRightIcon className="inline h-5 w-6 mt-2 text-pink-400" aria-hidden="true" />
                    </div>
                    <a
                      type="button"
                      target="_blank"
                      rel="noreferrer"
                      href="https://aws.amazon.com/marketplace/pp/prodview-efbfzy3xjgx5s"
                      className="inline ~px-1/3 ~py-1/2 dark:text-cyan-100 text-gray-500 text-center items-center ~text-sm/lg hover:border-2 dark:hover:border-cyan-500 hover:border-cyan-400 rounded"
                    >
                      Available on <img className="~h-5/8 inline ml-2 m-2" src={effectiveTheme !== 'dark' ? AWSMarketDark : AWSMarket} alt="AWS Marketplace" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </main>
  );
}
