import { Fragment, useCallback, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CogIcon } from '@heroicons/react/24/outline'
import CsvReader from '../../../Base/Elements/Input/CsvReader';

export default function TargetValueModal(props: any) {
  const [field, setField] = useState({ lookups: {}})
  const LookUpCallbackHandler = useCallback(() => {
    if (JSON.stringify(props.workflow.lookups) != JSON.stringify(field)) { setField(props.workflow.lookups) }
  }, []);

  useEffect(() => { LookUpCallbackHandler(); }, [props.workflow.lookups]);

  if (props.selectedField && typeof props.selectedFieldMap.map === 'object') {
    return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto"  onClose={props.toggleLookUpModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <Transition.Child as={Fragment} 
              enter="ease-out duration-300" 
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
              enterTo="opacity-100 translate-y-0 sm:scale-100" 
              leave="ease-in duration-200" 
              leaveFrom="opacity-100 translate-y-0 sm:scale-100" 
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="px-1 pb-6 inline-flex">
                  <div className="absolute top-4 left-4 px-1 inline">
                    <CogIcon className="h-10 w-10 text-gray-500 bg-white rounded-md inline" />
                    <Dialog.Title as="h3" className="text-xl font-medium text-gray-500 inline">
                    &nbsp; {props.selectedField[1] ? props.selectedField[1].field: ''}
                    </Dialog.Title>
                    <Dialog.Title as="h3" className="text-xs leading-6 ml-2 text-gray-500">
                    {props.selectedField[0] ? props.selectedField[0].definition: ''}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2 sm:mt-6 border border-gray-300 bg-white p-4 rounded-md shadow-sm">
                  <input 
                    className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 
                    rounded-md col-span-2" 
                    type="text" 
                    name="defaultValue" 
                    onChange={props.handleLookupDefaultValueChange}
                    placeholder="Default Value" 
                    id={'default-' + props.selectedField[1] ? props.selectedField[1].field: '' } 
                    defaultValue={props.selectedDefaultValue}
                    />
                   <div className="relative py-4">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">CSV Lookup</span>
                    </div>
                  </div>
                  <CsvReader setCsvFile={props.setCsvFile} csvFile={props.csvFile}/>               
                  <div className="relative py-4">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">Quick Lookup</span>
                    </div>
                  </div>
                <div className=" sm:grid sm:grid-cols-6 sm:gap-2 sm:grid-flow-row-dense bg-white p-2">
                  { props.selectedFieldMap.map.map((lookup: any, idx: number) => {
                    return (<>
                      <input 
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md col-span-2" 
                        type="text"
                        id={'match-' + lookup.field + '-' + idx } 
                        name="matchValue" 
                        onChange={props.updateFieldMapKey}
                        placeholder="Source Value"
                        defaultValue={lookup.key} 
                      />
                      <input 
                        className="shadow-sm focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-sm border-gray-300 rounded-md col-span-2" 
                        type="text" 
                        id={'replace-' + lookup.field + '-' + idx } 
                        name="matchOverride" 
                        onChange={props.updateFieldMapValue}
                        placeholder="Replacement" 
                        defaultValue={lookup.value} 
                      />
                      <button type="button" className="inline justify-center rounded-md border border-transparent shadow-sm bg-gray-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:col-start-5 sm:text-lg"
                        onClick={() => props.removeRow(lookup.id)}> -
                      </button>
                      { idx === 0 && 
                      <button 
                        type="button" 
                        className="inline justify-center rounded-md border border-transparent shadow-sm bg-gray-600 text-base font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:col-start-6 sm:text-lg"
                        onClick={() => props.addRowToLookupsHandler()}> + 
                      </button>
                      }
                    </>);
                  })}
                  </div>
                </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-2 sm:grid-flow-row-dense">
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.toggleLookUpModal()}>Close</button>
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-orange-400 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                  onClick={() => props.clearToggle(props.selectedField[1].field)}>Clear</button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    )
  } else {
    return (<div></div>)
  }
}
